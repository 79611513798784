import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import moment from 'moment';
import fileDownload from 'js-file-download'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { curvasNivelService } from '../../../../services/CurvasNivelService';
import CurvasNivelBlackIcon from '../../../../assets/pointcloudBlack.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import AddCurvasNivelDialog from '../../../Dialogs/CurvasNivel/AddCurvasNivelDialog'
import EditarCurvasNivelDialog from '../../../Dialogs/CurvasNivel/EditarCurvasNivelDialog';
import CurvasNivelMenuBtn from '../../../Dialogs/CurvasNivel/CurvasNivelMenuBtn';
import GrupoCurvasNivelMenuBtn from '../../../Dialogs/CurvasNivel/GrupoCurvasNivelMenuBtn';
import './CurvasNivel.css'
import CategoriasMenuBtn from '../../../Dialogs/CategoriasMenuBtn';
import PublicarCapaDialog from '../../../Dialogs/PublicarCapaTopografiaDialog';
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";

import {toast} from "react-toastify";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CurvasNivel = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grupoCurvasNivel, setGrupoCurvasNivel] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [openAddCurvasNivelDialog, setOpenAddCurvasNivelDialog] = useState(false);
  const [openEditCurvasNivelDialog, setOpenEditCurvasNivelDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [currentCurvasNivel, setCurrentCurvasNivel] = useState(null);
  const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
  const [openPublicarCapa, setOpenPublicarCapa] = useState(false);
  const [drawerMenuContext, setDrawerMenuContex] = useDrawerMenu();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };


  useEffect(() => {
    let mounted = true;
    mounted && cargarGrupoCurvasNivel();
    return () => mounted = false;
  }, [id]);

  const cargarGrupoCurvasNivel = () => {
    setLoadingGroup(true);
    curvasNivelService.getGrupo(id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setGrupoCurvasNivel(res.data.grupo);
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
  };

  const handleClickEditGCurvasNivel = () => {
    setOpenEditCurvasNivelDialog(true);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseEditCurvasNivelDialog = () => {
    setOpenEditCurvasNivelDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEditGrupoCurvasNivel = (nombre) => {
    handleCloseEditCurvasNivelDialog();
    setLoadingGroup(true);
    curvasNivelService.updateGrupo({ id, nombre, areaId: 1 })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargarGrupoCurvasNivel();
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
  };

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentCurvasNivel) {
      curvasNivelService.eliminarCurvasNivel({
        curvaId: currentCurvasNivel.id,
        grupoId: id
      })
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setCurrentCurvasNivel(null);
            cargarGrupoCurvasNivel();
          } else {
            console.log(res)
          }
          setLoadingGroup(false);
        })
    } else {
      curvasNivelService.eliminarGrupo(id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setDrawerMenuContex({actoin: ACTIONS.CLOSE_ALL_DRAWER_MENU})
            navigate('/');
          } else {
            console.log(res)
          }
          setLoadingGroup(false);
        })
    }
  };

  //menu-option-row
  const handleClickAddModelo = () => {
    setOpenAddCurvasNivelDialog(true);
  };
  const handleCloseAddCurvasNivelDialog = () => {
    setOpenAddCurvasNivelDialog(false);
    setCurrentCurvasNivel(null);
  };

  const handleAddModelo = ({ fecha, ficheros, workSpace, nombreCapa }) => {
    handleCloseAddCurvasNivelDialog(false)
    setImportandoDatos({ importando: true, status: "Importando" })
    notify("Importando...");

    const formData = new FormData()
    formData.append("uploadFile", ficheros)
    formData.append("fechaCreado", new Date(fecha).toISOString())
    formData.append("geoServerWorkSpace", workSpace)
    formData.append("nombreCapa", nombreCapa)
    curvasNivelService.crearCurvasNivel({
      idGrupo: id,
      formData
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        setCurrentCurvasNivel(null);
        cargarGrupoCurvasNivel();
        setImportandoDatos({ importando: false, status: "Hecho" })
        update("Los datos se importaron 👌", toast.TYPE.SUCCESS);
      } else {
        console.log(res)
        update(
            `Ha ocurrido un error importando 🤯 ${res.message}`,
            toast.TYPE.ERROR
        );
        setImportandoDatos({ importando: false, status: "Fallido" })
      }
    });
  };

  const handleDescargarCurvasNivel = (ort) => {
    curvasNivelService.descargarCurvasNivel({
      grupoId: id,
      curvaId: ort.id
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          fileDownload(res.data, `(${moment(ort.fechaCreado).format("DD-MM-YYYY")})curvasNivel.zip`)
        } else { console.log(res) }
      })
  };

  const handleClickPublicarCapa = (ort) => {
    setCurrentCurvasNivel(ort)
    setOpenPublicarCapa(true)
  }

  const handleDejarPublicarCapa = () => {
    alert("Esta acción no esta disponible")
  }

  const handleClosePublicarCapa = () => {
    setOpenPublicarCapa(false)
  }

  const handlePublicarCapa = ({ url, nombre, fecha }) => {
    handleClosePublicarCapa()
    curvasNivelService.publicarCapa({ curvaNivelId: currentCurvasNivel.id, nombre, url, fechaCreado: fecha })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          let modelos = grupoCurvasNivel?.modelos.map((ort) => {
            if (ort.id === currentCurvasNivel.id) {
              return {
                ...ort,
                publicado: true
              }
            } else {
              return ort;
            }
          })
          setGrupoCurvasNivel({ ...grupoCurvasNivel, modelos })
          setCurrentCurvasNivel()
        } else {
          console.log(res)
        }
      })
  }

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupoCurvasNivel.modelos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTabla = () => {
    if (rowsPerPage > 0) {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Publicado</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? grupoCurvasNivel.modelos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : grupoCurvasNivel.modelos
            ).map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">{row.fechaCreado ? moment(row.fechaCreado).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.publicado ?
                    <PublicadoIcon fontSize="small" />
                    :
                    <PublicadoIcon fontSize="small" color="disabled" />
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  <CategoriasMenuBtn
                    dejarPublicarAction={row.publicado && (() => handleDejarPublicarCapa(row))}
                    publicarAction={!row.publicado && (() => handleClickPublicarCapa(row))}
                    disabled={importandoDatos.importando}
                    descargarAction={() => handleDescargarCurvasNivel(row)}
                    eliminarAction={() => {
                      setCurrentCurvasNivel(row);
                      handleClickDeleteItem();
                    }} />
                </StyledTableCell>
              </StyledTableRow>))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: grupoCurvasNivel.modelos.length }]}
                  colSpan={12}
                  count={grupoCurvasNivel.modelos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    } else {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No existen curvas de nivel</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    }
  }

  return (
    <div className="area-container">
      {loadingGroup ?
        <div className='loading'><CircularProgress /></div> :
        <>
          <div className="head-area">
            <div className="head-name">
              <img src={CurvasNivelBlackIcon} alt="" width={18} height={18} />
              <h2 className="name-area">{grupoCurvasNivel ? grupoCurvasNivel.nombre : 'Grupo curvas de nivel inexistente'}</h2>
            </div>
            {grupoCurvasNivel
              ?
                <div className="actionsButtons">
                  <Button
                      onClick={cargarGrupoCurvasNivel}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoCurvasNivelMenuBtn
                    editarAction={handleClickEditGCurvasNivel}
                    eliminarAction={handleClickDeleteItem}
                  />
                </div>
              : null}
          </div>
          {grupoCurvasNivel ?
            <>
              <Button
                disabled={importandoDatos.importando}
                variant="contained"
                className="btn-add"
                onClick={handleClickAddModelo}
              >{importandoDatos.importando && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}Adicionar
              </Button>
              <div className="container-table">
                {renderTabla()}
              </div>
            </> : null}
        </>
      }

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      {openEditCurvasNivelDialog
        ? <EditarCurvasNivelDialog
          open={openEditCurvasNivelDialog}
          handleClose={handleCloseEditCurvasNivelDialog}
          handleEditarGrupo={handleEditGrupoCurvasNivel}
          nombre={grupoCurvasNivel.nombre}
        /> : null}
      {openAddCurvasNivelDialog ?
        <AddCurvasNivelDialog
          open={openAddCurvasNivelDialog}
          handleClose={handleCloseAddCurvasNivelDialog}
          handleSubmit={handleAddModelo}
        /> : null}
      {openPublicarCapa && <PublicarCapaDialog
        open={openPublicarCapa}
        handleClose={handleClosePublicarCapa}
        handleSubmit={handlePublicarCapa}
      />}
    </div>
  );
}
export default CurvasNivel;


