import "../App.css";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import NavigationBar from "../components/LeftMenu/NavigationBar/NavigationBar.js";
import Area from "../components/Pages/Sections/Areas/Area";
import Sector from "../components/Pages/Sections/Sectors/Sector";
import SystemReferences from "../components/Pages/SystemReferences/SystemReferences";
import { SectionsProvider } from "../providers/SectionsProvider";
import { DrawerMenuProvider } from "../providers/DrawerMenuProvider";
import { SystemReferencesProvider } from "../providers/SystemReferencesProvider";
import Piezometros from "../components/Pages/Layers/Piezometros/Piezometros";
import Acelerometros from "../components/Pages/Layers/Acelerometros/Acelerometros";
import InSar from "../components/Pages/Layers/InSar/InSar";
import Ortofotos from "../components/Pages/Layers/Ortofotos/Ortofotos";
import ModelosTerreno from "../components/Pages/Layers/DTM/ModelosTerreno";
import MapaElevaciones from "../components/Pages/Layers/MapaElevaciones/MapaElevaciones";
import CurvasNivel from "../components/Pages/Layers/CurvasNivel/CurvasNivel";
import Revancha from "../components/Pages/Layers/Revancha/Revancha";
import Auxiliares from "../components/Pages/Layers/Auxiliares/Auxiliares";
import EjesMuro from "../components/Pages/Layers/EjesMuro/EjesMuro";
import Grietas from "../components/Pages/Layers/Grietas/Grietas";
import PuntosDescarga from "../components/Pages/Layers/PuntosDescarga/PuntosDescarga";
import GrupoSismos from "../components/Pages/Sismos/GrupoSismo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import PlanesEsteril from "../components/Pages/Layers/PlanesCarga/Esteril/PlanesEsteril";
import Batimetrias from "../components/Pages/Layers/Batimetrias/Batimetrias";
import EstacionesMeteorologicas from "../components/Pages/Layers/EstacionesMeteorologicas/EstacionesMeteorologicas";
import CapaModeloTerrenoDigital from "../components/Pages/Layers/DTM/CapaModeloTerrenoDigital";
import CapaEjesMuro from "../components/Pages/Layers/EjesMuro/CapaEjesMuro";
import CapaCurvaNivel from "../components/Pages/Layers/CurvasNivel/CapaCurvaNivel";
import CapaAuxiliares from "../components/Pages/Layers/Auxiliares/CapaAuxiliares";
import CapaGrietas from "../components/Pages/Layers/Grietas/CapaGrietas";
import CapaPuntoDescarga from "../components/Pages/Layers/PuntosDescarga/CapaPuntoDescarga";
import CapaRevancha from "../components/Pages/Layers/Revancha/CapaRevancha";
import CapaOrtofoto from "../components/Pages/Layers/Ortofotos/CapaOrtofoto";
import CapaMapaElevaciones from "../components/Pages/Layers/MapaElevaciones/CapaMapaElevaciones";
import CapaPiezometro from "../components/Pages/Layers/Piezometros/CapaPiezometro";
import CapaAcelerometros from "../components/Pages/Layers/Acelerometros/CapaAcelerometros";
import CapaEstacionesMeteorologicas
    from "../components/Pages/Layers/EstacionesMeteorologicas/CapaEstacionesMeteorologicas";

import { AuthenticatedTemplate } from '@azure/msal-react';
import { useEffect } from "react";

export const Home = () => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = "Collahuasi - Monitoreo";

    }, [navigate, location]);

    return (
        <>
            <AuthenticatedTemplate>
                <DrawerMenuProvider>
                    <SectionsProvider>
                        <SystemReferencesProvider>
                            <div className="App">
                                <NavigationBar />
                                <Box
                                    component="main"
                                    sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
                                >
                                    <Routes>
                                        {/*CAPAS*/}
                                        <Route exact path="/capa_dtm/:id" element={<CapaModeloTerrenoDigital />} />
                                        <Route exact path="/capa_ejemuro/:id" element={<CapaEjesMuro />} />
                                        <Route exact path="/capa_curvanivel/:id" element={<CapaCurvaNivel />} />
                                        <Route exact path="/capa_auxiliar/:id" element={<CapaAuxiliares />} />
                                        <Route exact path="/capa_grieta/:id" element={<CapaGrietas />} />
                                        <Route exact path="/capa_puntodescarga/:id" element={<CapaPuntoDescarga />} />
                                        <Route exact path="/capa_revancha/:id" element={<CapaRevancha />} />
                                        <Route exact path="/capa_ortofoto/:id" element={<CapaOrtofoto />} />
                                        <Route exact path="/capa_mapaelevaciones/:id" element={<CapaMapaElevaciones />} />
                                        <Route exact path="/capa_piezometro/:id" element={<CapaPiezometro />} />
                                        <Route exact path="/capa_acelerometro/:id" element={<CapaAcelerometros />} />
                                        <Route exact path="/capa_est_meteorologicas/:id" element={<CapaEstacionesMeteorologicas />} />


                                        {/* GRUPOS */}
                                        <Route exact path="/area/:id" element={<Area />} />
                                        <Route exact path="/sector/:id" element={<Sector />} />
                                        <Route exact path="/sysRef/:id" element={<SystemReferences />} />
                                        <Route exact path="/piezometros/:id" element={<Piezometros />} />
                                        <Route exact path="/acelerometros/:id" element={<Acelerometros />} />
                                        <Route exact path="/insar/:id" element={<InSar />} />
                                        <Route exact path="/ortofotos/:id" element={<Ortofotos />} />
                                        <Route exact path="/dtm/:id" element={<ModelosTerreno />} />
                                        <Route exact path="/elevaciones/:id" element={<MapaElevaciones />} />
                                        <Route exact path="/curvasnivel/:id" element={<CurvasNivel />} />
                                        <Route exact path="/revancha/:id" element={<Revancha />} />
                                        <Route exact path="/puntosdescarga/:id" element={<PuntosDescarga />} />
                                        <Route exact path="/auxiliares/:id" element={<Auxiliares />} />
                                        <Route exact path="/ejesmuro/:id" element={<EjesMuro />} />
                                        <Route exact path="/grietas/:id" element={<Grietas />} />
                                        <Route exact path="/sismos/" element={<GrupoSismos />} />
                                        <Route exact path="/planesesteril/:id" element={<PlanesEsteril />} />
                                        <Route exact path="/planestransicion/:id" element={<PlanesEsteril />} />
                                        <Route exact path="/batimetrias/:id" element={<Batimetrias />} />
                                        <Route exact path="/estaciones_meteorologicas/:id" element={<EstacionesMeteorologicas />} />

                                    </Routes>
                                </Box>
                            </div>
                            <ToastContainer autoClose={3000} />
                        </SystemReferencesProvider>
                    </SectionsProvider>
                </DrawerMenuProvider>
            </AuthenticatedTemplate>
        </>
    );
};
