import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InsarBlack from "../../../../assets/insarBlack.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { insarService } from "../../../../services/InsarService";
import EditInsarDialog from "../../../Dialogs/SeriesInSAR/EditInsarDialog";
import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import "./InSar.css";
import SeriesTiempo from "./SerieTiempo";
import DeformacionesAcumuladas from "./DeformacionesAcumuladas";
import GrupoOrtofotoMenuBtn from "../../../Dialogs/Ortofotos/GrupoOrtofotoMenuBtn";
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Button from "@mui/material/Button";
import Reload from "../../../../assets/reloadIconBlack.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PERIODOS = {
  "7 dias": { activo: false, etiqueta: "7 días" },
  "12 dias": { activo: false, etiqueta: "12 días" },
  "30 dias": { activo: false, etiqueta: "30 días" },
  "6 meses": { activo: false, etiqueta: "6 meses" },
};

const InSar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [serieInsar, setSerieInsar] = useState();
  const [loadingSerie, setLoadingSerie] = useState(false);
  const [openEditSerieInsarDialog, setOpenEditSerieInsarDialog] =
    useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [periodoActual, setPeriodoActual] = useState();
  const [seriesTiempo, setSeriesTiempo] = useState(null);
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  useEffect(() => {
    loadSerieInsar();
  }, [id]);

  const loadSerieInsar = () => {
    setLoadingSerie(true);
    insarService.getSerie(id).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        let periodos = [...res.data.serie.periodos];
        let periodosOrdenados = periodos.map((periodo) => {
          PERIODOS[periodo.periodo].activo = true;
          let escala = [...periodo.escala].sort(
            (a, b) => a.rango.minimo > b.rango.minimo
          );
          return { ...periodo, escala };
        });

        let componentesAct = null;

        if(res.data.serie.serieTiempo !== null) {
          let componentesAct = {
            nombre: res.data.serie.etiqueta,
            activo: res.data.serie.serieTiempo.url ? true : false,
          };
        }

        setSeriesTiempo(componentesAct);
        setSerieInsar({ ...res.data.serie, periodos: periodosOrdenados });
      } else {
        console.error(res);
      }
      setLoadingSerie(false);
    });
  };

  const handleClickEditSerieInsar = () => {
    setOpenEditSerieInsarDialog(true);
  };

  const handleCloseEditSerieInsar = () => {
    setOpenEditSerieInsarDialog(false);
  };

  const handleEditSerieInsar = (serie) => {
    handleCloseEditSerieInsar();
    setLoadingSerie(true);
    insarService
      .editarSerie({
        id,
        ...serie,
      })
      .then((res) => {
        loadSerieInsar();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleDeleteSerie = () => {
    handleCloseDeleteItemDialog();
    insarService
      .eliminarSerie(id)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
    setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
    navigate("/");
  };

  const handleDeletePeriodo = () => {
    handleCloseDeleteItemDialog();
    insarService
      .deletePeriodo(periodoActual.id)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadSerieInsar();
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setPeriodoActual();
  };

  return (
    <div className="area-container" style={{ paddingLeft: "3rem" }}>
      {loadingSerie ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="head-area">
            <div className="head-name">
              <img src={InsarBlack} alt="" width={18} height={18} />
              <h2 className="name-area">
                {serieInsar ? serieInsar.etiqueta : "Grupo Insar inexistente"}
              </h2>
            </div>
            {serieInsar && (
                <div className="actionsButtons">
                  <Button
                      onClick={loadSerieInsar}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoOrtofotoMenuBtn
                    editarAction={handleClickEditSerieInsar}
                    eliminarAction={handleClickDeleteItem}
                  />
                </div>
            )}
          </div>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Series de Tiempo" {...a11yProps(0)} />
                <Tab label="Deformaciones Acumuladas" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="table-captura">
              <SeriesTiempo
                serieInsar={serieInsar}
                loadSerieInsar={loadSerieInsar}
                seriesTiempo={seriesTiempo}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className="table-captura">
              <DeformacionesAcumuladas
                serieInsar={serieInsar}
                loadSerieInsar={loadSerieInsar}
                periodos={PERIODOS}
              />
            </TabPanel>
          </Box>
        </>
      )}
      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={() => {
          periodoActual ? handleDeletePeriodo() : handleDeleteSerie();
        }}
      />
      {openEditSerieInsarDialog ? (
        <EditInsarDialog
          open={openEditSerieInsarDialog}
          handleClose={handleCloseEditSerieInsar}
          handleEditSerieInsar={handleEditSerieInsar}
          serieInsar={serieInsar}
        />
      ) : null}
    </div>
  );
};
export default InSar;
