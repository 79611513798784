import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Sismo.css";
import moment from "moment";
import { Button, CircularProgress } from "@mui/material";
import { sismosService } from "../../../services/SismosService";
import AddDialog from "../../Dialogs/Sismos/AddMagnitudDialog";
import MenuBtn from "../../Dialogs/Sismos/MenuBtn";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Magnitud = ({ magnitud, loadGrupoSismos }) => {
  const [editando, setEditando] = useState(false);
  const [importandoDatos, setImportandoDatos] = useState({
    importando: false,
    status: "Importando",
  });
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleClickAdd = () => {
    setOpenAddDialog(true);
  };
  const handleClickEditar = () => {
    setEditando(true);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    editando && setEditando(false);
    openAddDialog && setOpenAddDialog(false);
  };

  const handleAdd = ({ value, unit }) => {
    handleCloseAddDialog();
    setImportandoDatos({ importando: true, status: "Creando Umbrales de Magnitud" });
    let val = parseFloat(value);
    sismosService
      .crearUmbralMagnitud({ magnitud: { val, unit } })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadGrupoSismos();
          setImportandoDatos({ importando: false, status: "Hecho" });
        } else {
          console.log(res);
          setImportandoDatos({ importando: false, status: "Fallido" });
        }
      });
  };
  const handleEditar = ({ value, unit }) => {
    handleCloseAddDialog();
    setImportandoDatos({ importando: true, status: "Editando Umbrales de Magnitud" });
    let val = parseFloat(value);
    sismosService
      .editarUmbralMagnitud({ id: magnitud.id, magnitud: { val, unit } })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadGrupoSismos();
          setImportandoDatos({ importando: false, status: "Hecho" });
        } else {
          console.log(res);
          setImportandoDatos({ importando: false, status: "Fallido" });
        }
      });
  };

  return (
    <div>
      <Button
        disabled={importandoDatos.importando}
        variant="contained"
        className="btn-add"
        onClick={handleClickAdd}
      >
        {importandoDatos.importando && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        Adicionar
      </Button>
      <div className="container-table">
        {(magnitud) ?
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Identificador</StyledTableCell>
                <StyledTableCell align="center">
                  Fecha de creado
                </StyledTableCell>
                <StyledTableCell align="center">Magnitud</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="center">
                  {magnitud?.id ? magnitud.id : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {magnitud?.creado
                    ? moment(magnitud.creado).format("DD-MM-YYYY HH:mm:ss")
                    : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {magnitud?.magnitud
                    ? `${magnitud?.magnitud?.value} ${magnitud?.magnitud?.unit}`
                    : "-"}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {magnitud?.id && <MenuBtn editarAction={handleClickEditar} />}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        :
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No existen Umbral de Magnitud definido</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      }
      </div>
      {openAddDialog ? (
        <AddDialog
          open={openAddDialog}
          handleClose={handleCloseAddDialog}
          handleSubmit={editando ? handleEditar : handleAdd}
          magnitud={editando && magnitud?.magnitud}
        />
      ) : null}
    </div>
  );
};
export default Magnitud;
