import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import '../PlanesCarga.css';
import PlanesCargaBlackIcon from '../../../../../assets/planescargaBlack.png';
import GrupoMenuBtn from '../../../../Dialogs/PlanesCarga/Esteril/GrupoMenuBtn';
import { planesCargaService } from '../../../../../services/PlanesCargaService';
import {toast} from "react-toastify";
import Grid from "@mui/material/Grid";
import DeleteItemDialog from "../../../../Dialogs/DeleteItemDialog";
import {ejesmuroService} from "../../../../../services/EjesMuroService";
import {useDrawerMenu, ACTIONS} from "../../../../../providers/DrawerMenuProvider";
import Reload from "../../../../../assets/reloadIconBlack.png";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {ProtocolosRecepcion} from "./ProtocolosRecepcion";
import {ProtocolosAvance} from "./ProtocolosAvance";
import {CambiarEstadoPlanCarga} from "../../../../Dialogs/PlanesCarga/Esteril/CambiarEstadoPlanCarga";
import ImportarProtocolosDialog from "../../../../Dialogs/PlanesCarga/Esteril/ImportarProtocolosDialog";
import CreateExcelAvanceConfigDialog from "../../../../Dialogs/PlanesCarga/Esteril/CreateExcelAvanceConfigDialog";
import CreateExcelConfigDialog from "../../../../Dialogs/PlanesCarga/Esteril/CreateExcelConfigDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#343434",
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eaeaea",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const PlanesEsteril = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [capaPlanCarga, setCapaPlanCarga] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [openEditarDialog, setOpenEditarDialog] = useState(false);
    const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
    const [estados, setEstados] = useState()
    const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();
    const [openMostrarDetalles, setOpenMostrarDetalles] = useState(false);
    const [openCambiarEstadoDialog, setOpenCambiarEstadoDialog] = useState();
    const [currentElemento, setCurrentElemento] = useState(null);

    const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
    const [openImportarProtocolosAvance, setOpenImportarProtocolosAvance] = useState(false);
    const [openImportarProtocolosRecepcion, setOpenImportarProtocolosRecepcion] = useState(false);

    const [excelConfigAvance, setExcelConfigAvance] = useState(null)
    const [excelConfigRecepcion, setExcelConfigRecepcion] = useState(null)
    const [openCrearExcelConfigAvance, setOpenCrearExcelConfigAvance] = useState(false);
    const [openCrearExcelConfigRecepcion, setOpenCrearExcelConfigRecepcion] = useState(false);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toastId = React.useRef(null);
    const notify = (mensaje) =>
        (toastId.current = toast(`${mensaje}`, { autoClose: false }));
    const update = (mensaje, tipo) => {
        toast.update(toastId.current, {
            render: `${mensaje}`,
            type: tipo ? tipo : toast.TYPE.INFO,
            autoClose: 5000,
        });
    };

    useEffect(() => {
        let mounted = true;
        mounted && cargar();
        setExcelConfigRecepcion(null);
        setExcelConfigAvance(null);
        return () => mounted = false;
    }, [id]);

    const cargar = () => {
        setCargando(true);
        planesCargaService.getPlanEsterilById(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setCapaPlanCarga(res.data.capa);
                } else {
                    console.error(res);
                }
                setCargando(false);
            })
        planesCargaService.getEstadosPlandeCarga()
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setEstados(res.data.estados);
                } else {
                    console.error(res);
                }
            })
        planesCargaService.getConfiguracionDeExcelAvanceEsteril(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setExcelConfigAvance(res.data.protocolos);
                } else {
                    console.error(res);
                }
            })
        planesCargaService.getConfiguracionDeExcelRecepcionEsteril(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setExcelConfigRecepcion(res.data.protocolos);
                } else {
                    console.error(res);
                }
            })
    }

    const handleClickEditar = () => {
        setOpenEditarDialog(true);
    };

    const handleCloseEditarDialog = () => {
        setOpenEditarDialog(false);
    };

    const handleClickDeleteItem = () => {
        setOpenDeleteItemDialog(true);
    };

    const handleCloseDeleteItemDialog = () => {
        setOpenDeleteItemDialog(false);
    };

    const handleDeleteItem = () => {
        handleCloseDeleteItemDialog();
        planesCargaService.eliminarCapaPlanCargaEsteril(capaPlanCarga.id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
                    navigate('/');
                } else { console.log(res) }
            })
            .catch(err => {
                console.error(err)
            })
    };

    const handleClickCambiarEstado = () => {
        setOpenCambiarEstadoDialog(true);
    };

    const handleCloseCambiarEstado = () => {
        setOpenCambiarEstadoDialog(false);
    };

    const handleCambiarEstado = ({newEstado}) => {
        handleCloseCambiarEstado(false)
        notify("Editando Plan de carga...")
        planesCargaService.cambiarEstadosPlandeCarga({
            capaId: parseInt(id),
            estadoPlanCargaId: newEstado
            }).then(res => {
                if (res.status >= 200 && res.status < 300) {
                    cargar();
                    update(
                        "El Plan de Carga se editó corretamente 👌",
                        toast.TYPE.SUCCESS
                    );
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al editar el Plan de Carga 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
            })
    }

    const handleCloseImportarProtocolosAvance = () => {
        setOpenImportarProtocolosAvance(false)
    }

    const handleClickImportarProtocolosAvance = () => {
        setOpenImportarProtocolosAvance(true)
    }

    const handleImportarProtocolosAvance = ({ ficheros }) => {
        handleCloseImportarProtocolosAvance()
        setImportandoDatos({ importando: true, status: "Importando" })
        const formData = new FormData()
        formData.append("FormFiles", ficheros[0])
        notify("Importando Protocolo...")
        planesCargaService.importarProtocoloAvanceEsteril({
            capaId: capaPlanCarga.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "El nuevo Protocolo y sus datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar el Protocolo y sus datos  🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });

    }

    const handleCloseImportarProtocolosRecepcion = () => {
        setOpenImportarProtocolosRecepcion(false)
    }

    const handleClickImportarProtocolosRecepcion = () => {
        setOpenImportarProtocolosRecepcion(true)
    }

    const handleImportarProtocolosRecepcion = ({ ficheros }) => {
        handleCloseImportarProtocolosRecepcion()
        setImportandoDatos({ importando: true, status: "Importando" })
        const formData = new FormData()
        formData.append("FormFiles", ficheros[0])
        notify("Importando Protocolo...")
        planesCargaService.importarProtocoloRecepcionEsteril({
            capaId: capaPlanCarga.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "El nuevo Protocolo y sus datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar el Protocolo y sus datos  🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });
    }

    const handleEditarGrupo = (
        {capaId, noCapa, ejeMuroId, sector, estadoPlanCargaId,  elevacionRequerida, espesorObjetivo,
            toleranciaCumplimiento, fechaRecepcion, fechaCreacion, puntoControlInicial, puntoControlFinal}) => {
        handleCloseEditarDialog(false)
        notify("Editando Plan de carga...")
        planesCargaService.actualizarCapaPlanCargaEsteril({
            capaId: capaId, noCapa: noCapa, ejeMuroId: ejeMuroId, sector: sector, estadoPlanCargaId: estadoPlanCargaId,
            elevacionRequerida: elevacionRequerida, espesorObjetivo: espesorObjetivo, toleranciaCumplimiento: toleranciaCumplimiento,
            fechaRecepcion: fechaRecepcion, fechaCreacion: fechaCreacion, puntoControlInicial: puntoControlInicial, puntoControlFinal: puntoControlFinal})
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    cargar();
                    update(
                        "El Plan de Carga se editó corretamente 👌",
                        toast.TYPE.SUCCESS
                    );
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al editar el Plan de Carga 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
            })
    }

    const handleCreateExcelConfigAvance = () => {
        setOpenCrearExcelConfigAvance(true);
    };

    const handleCloseCrearExcelConfigAvance = () => {
        setOpenCrearExcelConfigAvance(false);
    };

    const handleCrearConfiguracionExcelAvance = ({noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow, observacionesCell,
                                                     puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
                                                     evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell,
                                                     anchoCapaCell, ajustesCell, observacionesPtoCell}) => {
        handleCloseCrearExcelConfigAvance()
        notify("Se está creando la configuración del archivo de datos");
        if(excelConfigAvance) {
            var config = excelConfigAvance;
            planesCargaService
                .editarConfiguracionDeExcelAvanceEsteril({
                    configId: config.id, capaId: capaPlanCarga.id, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow, observacionesCell,
                    puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
                    evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell,
                    anchoCapaCell, ajustesCell, observacionesPtoCell
                })
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        update(
                            "La configuración para la carga de los excel se editó correctamente 👌",
                            toast.TYPE.SUCCESS
                        );
                        cargar();
                    } else {
                        update(
                            `Ha ocurrido un error editando la configuración 🤯 ${res.message}`,
                            toast.TYPE.ERROR
                        );
                    }
                });
        } else {
            // console.log('EXCEL NEW', excelConfig);
            planesCargaService
                .crearConfiguracionDeExcelAvanceEsteril({
                    capaId: capaPlanCarga.id, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow, observacionesCell,
                    puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
                    evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell,
                    anchoCapaCell, ajustesCell, observacionesPtoCell
                })
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        update(
                            "La configuración para la carga de los excel se creo correctamente 👌",
                            toast.TYPE.SUCCESS
                        );
                        cargar();
                    } else {
                        update(
                            `Ha ocurrido un error creando la configuración 🤯 ${res.message}`,
                            toast.TYPE.ERROR
                        );
                    }
                });
        }
    };

    const handleCreateExcelConfigRecepcion = () => {
        setOpenCrearExcelConfigRecepcion(true);
    };

    const handleCloseCrearExcelConfigRecepcion = () => {
        setOpenCrearExcelConfigRecepcion(false);
    };

    const handleCrearConfiguracionExcelRecepcion = ({noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
                                                        observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
                                                        promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
                                                        difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
                                                        actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell}) => {
        handleCloseCrearExcelConfigRecepcion()
        notify("Se está creando la configuración del archivo de datos");
        if(excelConfigRecepcion) {
            var config = excelConfigRecepcion;
            planesCargaService
                .editarConfiguracionDeExcelRecepcionEsteril({
                    configId: parseInt(config.id), capaId: parseInt(id), noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
                    observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
                    promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
                    difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
                    actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell
                })
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        update(
                            "La configuración para la carga de los excel se editó correctamente 👌",
                            toast.TYPE.SUCCESS
                        );
                        cargar();
                    } else {
                        update(
                            `Ha ocurrido un error editando la configuración 🤯 ${res.message}`,
                            toast.TYPE.ERROR
                        );
                    }
                });
        } else {
            // console.log('EXCEL NEW', excelConfig);
            planesCargaService
                .crearConfiguracionDeExcelRecepcionEsteril({
                    capaId: parseInt(id), noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
                    observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
                    promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
                    difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
                    actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell
                })
                .then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        update(
                            "La configuración para la carga de los excel se creo correctamente 👌",
                            toast.TYPE.SUCCESS
                        );
                        cargar();
                    } else {
                        update(
                            `Ha ocurrido un error creando la configuración 🤯 ${res.message}`,
                            toast.TYPE.ERROR
                        );
                    }
                });
        }
    };

    return (
        <div className="area-container">
            {cargando ?
                <div className='loading'><CircularProgress /></div> :
                <>
                    <div className="head-area">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="head-name">
                                    <img src={PlanesCargaBlackIcon} alt="" width={18} height={18} />
                                    <h2 className="name-area">{capaPlanCarga ? capaPlanCarga.noCapa : 'Errores al cargar el Plan Carga'}</h2>
                                </div>
                            </Grid>

                            <Grid item sm={4}>
                                <div>
                                    <p><b>Sector:</b> {capaPlanCarga ? capaPlanCarga.sector : '-'}</p>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <p><b>Elevación Requerida [m]:</b> {capaPlanCarga ? capaPlanCarga.elevacionRequerida : '-'}</p>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <p><b>Espesor Objetivo [m]:</b> {capaPlanCarga ? capaPlanCarga.espesorObjetivo : '-'}</p>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <p><b>Tolerancia de Cumplimiento:</b> {capaPlanCarga ? capaPlanCarga.toleranciaCumplimiento : '-'}</p>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <p><b>Estado de Capa:</b> {capaPlanCarga ? capaPlanCarga.estadoCapa.name : '-'}</p>
                                </div>
                            </Grid>
                        </Grid>
                        {capaPlanCarga
                            ?
                            <div className="actionsButtons">
                                <Button
                                    onClick={cargar}
                                    sx={{ mr: "0px", color: "white", display: "block" }}
                                >
                                    <img src={Reload} alt="" />
                                </Button>
                                <GrupoMenuBtn
                                    cambiarEstado={handleClickCambiarEstado}
                                    crearExcelConfigAvance={handleCreateExcelConfigAvance}
                                    crearExcelConfigRecepcion={handleCreateExcelConfigRecepcion}
                                    editarAction={handleClickEditar}
                                    eliminarAction={() => handleClickDeleteItem(null)}
                                />
                            </div>
                            : null}
                    </div>
                    {capaPlanCarga ?
                        <>
                            <div className="container-table">
                                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                                    <Tabs value={value} onChange={handleChange}>
                                        <Tab label="Protocolos Avance" {...a11yProps(0)} />
                                        <Tab label="Protocolos Recepción" {...a11yProps(1)} disabled={capaPlanCarga.estadoCapa.name === "Avance"}/>
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0} className="table-captura">
                                    <Button
                                        variant="contained"
                                        className="btn-add-acelerometro"
                                        onClick={handleClickImportarProtocolosAvance}
                                        disabled={!excelConfigAvance}
                                    >
                                        Importar Protocolo Avance
                                    </Button>
                                    &nbsp;
                                    <ProtocolosAvance capaPlanCarga={capaPlanCarga}/>
                                </TabPanel>
                                <TabPanel value={value} index={1} className="table-captura">
                                    <Button
                                        variant="contained"
                                        className="btn-add-acelerometro"
                                        onClick={handleClickImportarProtocolosRecepcion}
                                        disabled={!excelConfigRecepcion}
                                    >
                                        Importar Protocolo Recepcion
                                    </Button>
                                    &nbsp;
                                    <ProtocolosRecepcion capaPlanCarga={capaPlanCarga}/>
                                </TabPanel>
                            </div>
                        </> : null}
                </>
            }

            <DeleteItemDialog
                open={openDeleteItemDialog}
                handleClose={handleCloseDeleteItemDialog}
                handleDeleteItem={handleDeleteItem} />

            {openCambiarEstadoDialog && (
                <CambiarEstadoPlanCarga
                    open={openCambiarEstadoDialog}
                    handleClose={handleCloseCambiarEstado}
                    handleSubmit={handleCambiarEstado}
                    estadoActualId={capaPlanCarga.estadoCapa.id}
                    estados={estados}
                />
            )}

            {openImportarProtocolosAvance && <ImportarProtocolosDialog
                open={openImportarProtocolosAvance}
                handleClose={handleCloseImportarProtocolosAvance}
                handleSubmit={handleImportarProtocolosAvance}
            />}
            {openImportarProtocolosRecepcion && <ImportarProtocolosDialog
                open={openImportarProtocolosRecepcion}
                handleClose={handleCloseImportarProtocolosRecepcion}
                handleSubmit={handleImportarProtocolosRecepcion}
            />}
            {openCrearExcelConfigAvance && (
                <CreateExcelAvanceConfigDialog
                    open={openCrearExcelConfigAvance}
                    handleClose={handleCloseCrearExcelConfigAvance}
                    handleSubmit={handleCrearConfiguracionExcelAvance}
                    excelConfig={excelConfigAvance}
                />
            )}
            {openCrearExcelConfigRecepcion && (
                <CreateExcelConfigDialog
                    open={openCrearExcelConfigRecepcion}
                    handleClose={handleCloseCrearExcelConfigRecepcion}
                    handleSubmit={handleCrearConfiguracionExcelRecepcion}
                    excelConfig={excelConfigRecepcion}
                />
            )}
        </div>
    );
}
export default PlanesEsteril;
