import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Information from "../../../../assets/informationBlack.png";
import Reload from "../../../../assets/reloadIconBlack.png";
import AcelerometroBlackIcon from "../../../../assets/earthquakeBlack.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";

import AddAcelerometroDialog from "../../../Dialogs/Acelerometros/AddAcelerometroDialog";
import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import EditAcelerometroDialog from "../../../Dialogs/Acelerometros/EditAcelerometroDialog";
import ImportDataAcelerometroDialog from "../../../Dialogs/Acelerometros/ImportDataAcelerometroDialog";
import PublicarDialog from "../../../Dialogs/Acelerometros/PublicarDialog";
import "./Acelerometros.css";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { acelerometrosService } from "../../../../services/AcelerometrosService";
import { fromLatLon } from "../../../../utils/ll2utm";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReceptorEmailForm from "../../../Dialogs/ReceptorEmailForm";
import moment from "moment/moment";
import {useDrawerMenu, ACTIONS,} from "../../../../providers/DrawerMenuProvider";
import PublicadoIcon from "@mui/icons-material/FlagOutlined";
import {TableFooter, TablePagination} from "@mui/material";
import {piezometersService} from "../../../../services/PiezometersService";
import DeleteCapaDialog from "../../../Dialogs/DeleteCapaDialog";
import ArchiveIcon from "@mui/icons-material/Archive";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Acelerometros = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [acelerometroGroup, setAcelerometroGroup] = useState();
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [rowEl, setRowEl] = useState(null);
  const [openRowEl, setOpenRowEl] = useState(null);

  const [openAddPiezomDialog, setOpenAddPiezomDialog] = useState(false);
  const [openEditAcelerometroDialog, setOpenEditAcelerometroDialog] =
    useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openImportarDatosDialog, setOpenImportarDatosDialog] = useState(false);
  const [currentAcelerometro, setCurrentAcelerometro] = useState(null);

  const [receptoresEmail, setReceptoresEmail] = useState([]);
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [openEditEmailDialog, setOpenEditEmailDialog] = useState(false);
  const [updatingThresholds, setUpdatingThresholds] = useState(false);
  const [openDeleteItemEmailDialog, setOpenDeleteItemEmailDialog] = useState(false);
  const [currentReceptorEmail, setCurrentReceptorEmail] = useState(null);
  const [openDejarPublicar, setOpenDejarPublicar] = useState(false);
  const [publicado, setPublicado] = useState(false);

  const [openPublicar, setOpenPublicar] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };

  useEffect(() => {
    let mounted = true;
    mounted && loadAcelerometroGroup();
    loadReceptoresEmail();
    return () => (mounted = false);
  }, [id]);

  const loadAcelerometroGroup = () => {
    setLoadingGroup(true);
    acelerometrosService.getGroup(id).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setAcelerometroGroup(res.data);
        setPublicado(res.data.published)
      } else {
        console.error(res);
      }
      setLoadingGroup(false);
    });
  };

  const loadReceptoresEmail = () => {
    setLoadingGroup(true);
    acelerometrosService.getReceptoresEmail().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setReceptoresEmail(res.data);
        //console.log("RECEPTORES: ", res.data)
      } else {
        console.error(res);
      }
      setLoadingGroup(false);
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setRowEl(event.currentTarget);
    setOpenRowEl(event.currentTarget)
  };
  const handleClose = () => {
    setRowEl(null);
    setAnchorEl(null)
    setOpenRowEl(null)
  };

  const handleClickEditAcelerometro = () => {
    handleClose();
    setOpenEditAcelerometroDialog(true);
  };

  const handleClickDeleteItem = () => {
    handleClose();
    handleCloseRowOption();
    setOpenDeleteItemDialog(true);
  };

  const handleClickDeleteEmailItem = () => {
    handleClose();
    handleCloseRowOption();
    setOpenDeleteItemEmailDialog(true);
  };

  const handleClickPublicarAcelerometro = () => {
    handleClose();
  };

  const handleCloseEditAcelerometroDialog = () => {
    setOpenEditAcelerometroDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleCloseDeleteEmailItemDialog = () => {
    setOpenDeleteItemEmailDialog(false);
  };

  const handleCloseImportarDatosDialog = () => {
    setOpenImportarDatosDialog(false);
  };

  const handleClickImportarDatos = () => {
    handleClose();
    handleCloseRowOption();
    setOpenImportarDatosDialog(true);
  };

  const handleClickArchivarItem = (row) => {
    handleCloseRowOption();
    notify("Actualizando estado del Acelerómetro...")
    acelerometrosService.archivarAcelerometro(
        {
          id: row.id,
          archivar: !row.archivado
        }
    ).then(res => {
      if (res.status >= 200 && res.status < 300) {
        loadAcelerometroGroup();
        update(
            "Se actualizó el estado del Acelerómetro correctamente 👌",
            toast.TYPE.SUCCESS
        );
      } else {
        console.log(res)
        update(
            `Ocurrio un error al actualizar el estado del Acelerómetro 🤯 ${res.message}`,
            toast.TYPE.ERROR
        );
      }
    });
  };

  const handleImportarDatos = ({ fecha, ficheros }) => {
    setOpenImportarDatosDialog(false);
    notify("Importando datos de Acelerómetros...")
    const bodyFormData = new FormData();
    bodyFormData.append("file", new Blob(ficheros));
    acelerometrosService.importarData({
      id: currentAcelerometro.id,
      ficheros: bodyFormData,
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        loadAcelerometroGroup();
        update(
            "Los datos fueron importados correctamente 👌",
            toast.TYPE.SUCCESS
        );
      } else {
        console.log(res)
        update(
            `Ocurrio un error al importar los datos 🤯 ${res.message}`,
            toast.TYPE.ERROR
        );
      }
    });
  };

  const handleEditGrupoAcelerometro = (acelerometroGroupEdited) => {
    handleCloseEditAcelerometroDialog();
    setLoadingGroup(true);
    acelerometrosService
      .updateGroup(acelerometroGroupEdited)
      .then((res) => {
        loadAcelerometroGroup();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClickAddEmail = () => {
    handleCloseRowOption();
    setOpenAddEmailDialog(true);
  };
  const handleCloseAddEmailDialog = () => {
    setOpenAddEmailDialog(false);
    setCurrentReceptorEmail(null);
  };

  const handleAddReceptorEmail = ({email}) => {
    handleCloseAddEmailDialog();
    setLoadingGroup(true);
    acelerometrosService
        .crearReceptoresEmail({
          grupoId: parseInt(currentAcelerometro.id),
          email
        })
        .then((res) => {
          update(
              "Se añadío el Email correctamente 👌",
              toast.TYPE.SUCCESS
          );
          loadReceptoresEmail();
        })
        .catch((err) => {
          update(
              "Ocurrio un error al crear el receptor email. 🤯 ${res.message}",
              toast.TYPE.ERROR
          );
          console.error(err);
        });
  };

  const handleClickEditEmail = () => {
    handleCloseRowOption();
    setOpenEditEmailDialog(true);
  };
  const handleCloseEditEmailDialog = () => {
    setOpenEditEmailDialog(false);
    setCurrentReceptorEmail(null);
  };

  const handleEditReceptorEmail = ({id, email}) => {
    handleCloseEditEmailDialog();
    setLoadingGroup(true);
    acelerometrosService
        .updateReceptoresEmail({id, email})
        .then((res) => {
          update(
              "Se actualizó el Email correctamente 👌",
              toast.TYPE.SUCCESS
          );
          loadReceptoresEmail();
        })
        .catch((err) => {
          update(
              "Ocurrio un error al editar el receptor email. 🤯 ${res.message}",
              toast.TYPE.ERROR
          );
          console.error(err);
        });
  };

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentAcelerometro) {
      acelerometrosService
        .deleteAcelerometro({
          id: currentAcelerometro.id,
        })
        .then((res) => {
          loadAcelerometroGroup();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      acelerometrosService
        .deleteGroup(id)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
      setDrawerMenuContext({ action: ACTIONS.CLOSE_ALL_DRAWER_MENU });
      navigate("/");
    }
  };

  const handleDeleteEmailItem = () => {
    handleCloseDeleteEmailItemDialog();
    if (currentReceptorEmail) {
      acelerometrosService
          .deleteReceptoresEmail({
            id: currentReceptorEmail.id,
          })
          .then((res) => {
            loadReceptoresEmail();
          })
          .catch((err) => {
            console.error(err);
          });
    }
  };

  const handleClosePublicar = () => {
    setOpenPublicar(false);
  };

  const handleClickPublicar = () => {
    setOpenPublicar(true);
  };

  const handlePublicar = ({ nombre, fecha }) => {
    handleClosePublicar()
    notify("Publicando Grupo de Acelerometros...")
    acelerometrosService.crearCapa({
      grupoId: acelerometroGroup.id,
      creado:fecha,
      nombre: nombre,
      itemId: ""
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          loadAcelerometroGroup();
          update(
              "Los datos fueron publicados correctamente 👌",
              toast.TYPE.SUCCESS
          );
        } else {
          console.log(res)
          update(
              `Ocurrio un error al publicar los datos 🤯 ${res.message}`,
              toast.TYPE.ERROR
          );
        }
      })
  };

  //menu-option-row
  const handleClickRowOption = (event) => {
    setRowEl(event.currentTarget);
    setOpenRowEl(event.currentTarget.getAttribute("aria-controls"));
  };
  const handleCloseRowOption = () => {
    setRowEl(null);
    setOpenRowEl(null);
  };
  const handleClickAddPiezom = () => {
    handleCloseRowOption();
    setOpenAddPiezomDialog(true);
  };
  const handleCloseAddPiezomDialog = () => {
    setOpenAddPiezomDialog(false);
    setCurrentAcelerometro(null);
  };

  const handleAddAcelerometro = (acelerometro) => {
    handleCloseAddPiezomDialog();
    setLoadingGroup(true);
    notify("Agregando nuevo Acelerometro...")
    const {
      identifier,
      latitude,
      longitude,
        cota,
      alertX,
      alertY,
      alertZ,
      alarmX,
      alarmY,
      alarmZ
    } = acelerometro;

    acelerometrosService
      .createAcelerometro(
          {
            grupoAcelerometroId: acelerometroGroup.id,
            etiqueta: identifier,
            ubicacion: {
              x: longitude,
              y: latitude,
            },
            cota: cota,
            umbralAcelerometro: {
              alertaX: alertX,
              alertaY: alertY,
              alertaZ: alertZ,
              alarmaX: alarmX,
              alarmaY: alarmY,
              alarmaZ: alarmZ
            }
          })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadAcelerometroGroup();
          update(
              "Los datos fueron procesados correctamente 👌",
              toast.TYPE.SUCCESS
          );
        } else {
          console.log(res);
          update(
              `Ocurrio un error al procesar los datos 🤯 ${res.message}`,
              toast.TYPE.ERROR
          );
        }
      });
    setCurrentAcelerometro(null);
  };

  const handleEditAcelerometro = (acelerometroEdited) => {
    handleCloseAddPiezomDialog();
    notify("Editando Acelerometro seleccionado...")
    setLoadingGroup(true);
    const {
      identifier,
      latitude,
      longitude,
      cota,
      alertX,
      alertY,
      alertZ,
      alarmX,
      alarmY,
      alarmZ
    } = acelerometroEdited;

    acelerometrosService
      .updateAcelerometro({
        id: currentAcelerometro.id,
        etiqueta: identifier,
        ubicacion: {
          x: longitude,
          y: latitude,
        },
        cota: cota,
        umbralAcelerometro: {
          alertaX: alertX,
          alertaY: alertY,
          alertaZ: alertZ,
          alarmaX: alarmX,
          alarmaY: alarmY,
          alarmaZ: alarmZ
        }
      })
      .then((res) => {
        loadAcelerometroGroup();
        update(
            "Los datos fueron procesados correctamente 👌",
            toast.TYPE.SUCCESS
        );
      })
      .catch((err) => {
        console.error(err);
        update(
            `Ocurrio un error al procesar los datos 🤯 ${err.message}`,
            toast.TYPE.ERROR
        );
      });
    setCurrentAcelerometro(null);
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - acelerometroGroup.acelerometros.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickDejarPublicarCapa = () => {
    setOpenDejarPublicar(true);
  };

  const handleCloseDejarPublicar = () => {
    setOpenDejarPublicar(false);
  };

  const handleDejarPublicarCapa = () => {
    handleCloseDejarPublicar();
    acelerometrosService.eliminarCapaByGrupoId(parseInt(acelerometroGroup.id)).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        loadAcelerometroGroup()
        toast.success("La capa se ah eliminado correctamente.", {
          autoClose: 6000,
        });
      } else {
        toast.error("Fallo al eliminar el Grupo de Acelerómetros" + res.message, {
          autoClose: 6000,
        });
      }
    });
  };

  return (
    <div className="area-container">
      {loadingGroup ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
          <>
            <div className="head-area">
              <div className="head-name">
                <img src={AcelerometroBlackIcon} alt="" width={18} height={18}/>
                <h2 className="name-area">
                  {acelerometroGroup
                      ? acelerometroGroup.name
                      : "Grupo de Acelerómetros inexistente"}
                </h2>
              </div>
              {acelerometroGroup ? (
                  <div className="actionsButtons">
                    <Button
                        onClick={loadAcelerometroGroup}
                        sx={{mr: "0px", color: "white", display: "block"}}
                    >
                      <img src={Reload} alt=""/>
                    </Button>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{
                          bgcolor: open ? "rgba(0, 0, 0, 0.04)" : "transparent",
                        }}
                    >
                      <img src={Information} alt="informacionIcon"/>
                    </IconButton>
                  </div>
              ) : null}
            </div>
            <div>
            <span>{publicado ?
                <><PublicadoIcon/> <span>Publicado</span></>
                :
                <><PublicadoIcon color="disabled"/> <span>No Publicado</span></>
            }</span>
            </div>
            {acelerometroGroup ? (
                <>
                  <div className="container-table">
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                      <Tabs value={value} onChange={handleChange}>
                        <Tab label="Acelerómetros" {...a11yProps(0)} />
                        <Tab label="Emails Notificaciones" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} className="table-captura">
                      <Button
                          variant="contained"
                          className="btn-add-acelerometro"
                          onClick={handleClickAddPiezom}
                      >
                        Adicionar Acelerómetro
                      </Button>
                      &nbsp;
                      {acelerometroGroup.acelerometros.length ? (
                          <TableContainer component={Paper}>
                            <Table
                                sx={{minWidth: 700}}
                                size="small"
                                aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                      align="center"
                                      colSpan={2}
                                  ></StyledTableCell>
                                  <StyledTableCell align="center" colSpan={3}>
                                    Última Medición
                                  </StyledTableCell>
                                  <StyledTableCell align="center" colSpan={6}>
                                    Umbrales
                                  </StyledTableCell>
                                  <StyledTableCell
                                      align="center"
                                      colSpan={2}
                                  ></StyledTableCell>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell align="left">
                                    Identificador
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Cota
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Estación
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Fecha Inicio
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Fecha Fin
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Alerta en X
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Alerta en Y
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Alerta en Z
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Alarma en X
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Alarma en Y
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Alarma en Z
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Archivado
                                  </StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                        ? acelerometroGroup.acelerometros.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : acelerometroGroup.acelerometros
                                ).map((row, index) => {
                                  var length = row.capturas.length;

                                  return (
                                      <StyledTableRow key={row.id}>
                                        <StyledTableCell align="left">
                                          {row.etiqueta || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {row.cota.toFixed(2) || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          { row.ultimaCaptura?.estacion || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {(row.ultimaCaptura?.fechaInicio) ?
                                              moment(row.ultimaCaptura?.fechaInicio).utc().format(
                                                  "DD-MM-YYYY HH:mm:ss"
                                              )
                                              : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {(row.ultimaCaptura?.fechaFin)
                                              ? moment(row.ultimaCaptura?.fechaFin).utc().format(
                                                  "DD-MM-YYYY HH:mm:ss"
                                              )
                                              : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                           {row.umbralAcelerometro.alertaX.toFixed(2) || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                           {row.umbralAcelerometro.alertaY.toFixed(2) || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                           {row.umbralAcelerometro.alertaZ.toFixed(2) || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                           {row.umbralAcelerometro.alarmaX.toFixed(2) || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                           {row.umbralAcelerometro.alarmaY.toFixed(2) || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                           {row.umbralAcelerometro.alarmaZ.toFixed(2) || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {row.archivado ? <ArchiveIcon/> : <ArchiveIcon color="disabled"/>}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                          <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={row.id}
                                              // aria-expanded={open ? 'true' : undefined}
                                              aria-haspopup="true"
                                              onClick={handleClickRowOption}
                                          >
                                            <MoreHorizIcon/>
                                          </IconButton>
                                          <Menu
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby": "long-button",
                                              }}
                                              anchorEl={rowEl}
                                              open={openRowEl === row.id.toString()}
                                              onClose={handleCloseRowOption}
                                              keepMounted
                                          >
                                            <MenuItem
                                                onClick={() => {
                                                  setCurrentAcelerometro(row);
                                                  handleClickImportarDatos();
                                                }}
                                            >
                                              Importar datos
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                  setCurrentAcelerometro(row);
                                                  handleClickAddPiezom();
                                                }}
                                            >
                                              Editar
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                  setCurrentAcelerometro(row);
                                                  handleClickArchivarItem(row);
                                                }}
                                            >
                                              {row.archivado ? "Activar" : "Archivar" }
                                            </MenuItem>
                                          </Menu>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                              <TableFooter>
                                <TableRow>
                                  <TablePagination
                                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: acelerometroGroup.acelerometros.length }]}
                                      colSpan={12}
                                      count={acelerometroGroup.acelerometros.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      SelectProps={{
                                        inputProps: {
                                          'aria-label': 'rows per page',
                                        },
                                        native: true,
                                      }}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                  />
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </TableContainer>
                      ) : (
                          <TableContainer component={Paper}>
                            <Table
                                sx={{minWidth: 700}}
                                size="small"
                                aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="center">
                                    No existen acelerómetros
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1} className="table-captura">
                      <Button
                          variant="contained"
                          className="btn-add-acelerometro"
                          onClick={handleClickAddEmail}
                      >
                        Adicionar Email
                      </Button>
                      &nbsp;
                      {receptoresEmail.length ? (
                          <TableContainer component={Paper}>
                            <Table
                                sx={{minWidth: 700}}
                                size="small"
                                aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="left">
                                    Identificador
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Correo Electrónico
                                  </StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {receptoresEmail.map((row) => {
                                  return (
                                      <StyledTableRow key={row.id}>
                                        <StyledTableCell align="left">
                                          {row.id || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {row.email || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                          <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={row.id}
                                              aria-haspopup="true"
                                              onClick={handleClickRowOption}
                                          >
                                            <MoreHorizIcon/>
                                          </IconButton>
                                          <Menu
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby": "long-button",
                                              }}
                                              anchorEl={rowEl}
                                              open={openRowEl === row.id.toString()}
                                              onClose={handleCloseRowOption}
                                              keepMounted
                                          >
                                            <MenuItem
                                                onClick={() => {
                                                  setCurrentReceptorEmail(row);
                                                  setUpdatingThresholds(false);
                                                  handleClickEditEmail();
                                                }}
                                            >
                                              Editar
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                  setCurrentReceptorEmail(row);
                                                  handleClickDeleteEmailItem(row);
                                                }}
                                            >
                                              Eliminar
                                            </MenuItem>
                                          </Menu>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                      ) : (
                          <TableContainer component={Paper}>
                            <Table
                                sx={{minWidth: 700}}
                                size="small"
                                aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="center">
                                    No existen receptores de email definidos.
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                      )}
                    </TabPanel>
                  </div>
                </>
            ) : null}
          </>
      )}

      <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              marginLeft: '-5px',
              '& .MuiMenu-list': {
                paddingTop: 0,
                paddingBottom: 0
              },
            },
          }}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {/*<MenuItem onClick={() => { handleClickPublicar(); handleClose() }} >Publicar Capa</MenuItem>*/}
        <MenuItem onClick={() => { handleClickEditAcelerometro(); handleClose() }} >Editar</MenuItem>
        <MenuItem onClick={() => { handleClickDeleteItem(); handleClose() }} >Eliminar</MenuItem>
        {!publicado && <MenuItem onClick={() => { handleClickPublicar(); handleClose() }}>Publicar Capa</MenuItem>}
        {publicado && <MenuItem onClick={() => { handleClickDejarPublicarCapa(); handleClose() }}>Dejar de publicar</MenuItem>}
      </Menu>

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem}
      />
      {openDeleteItemEmailDialog ? (
          <DeleteItemDialog
              open={openDeleteItemEmailDialog}
              handleClose={handleCloseDeleteEmailItemDialog}
              handleDeleteItem={handleDeleteEmailItem}
          />
      ) : null}
      {openEditAcelerometroDialog ? (
        <EditAcelerometroDialog
          open={openEditAcelerometroDialog}
          handleClose={handleCloseEditAcelerometroDialog}
          handleEditGrupoAcelerometro={handleEditGrupoAcelerometro}
          acelerometroGroup={acelerometroGroup}
        />
      ) : null}
      {openAddEmailDialog ? (
          <ReceptorEmailForm
              open={openAddEmailDialog}
              handleClose={handleCloseAddEmailDialog}
              handleReceptorEmail={handleAddReceptorEmail}
              edit={false}
              usuario={currentReceptorEmail}
          />
      ) : null}
      {openDejarPublicar ?
          <DeleteCapaDialog
              open={openDejarPublicar}
              handleClose={handleCloseDejarPublicar}
              handleDeleteItem={handleDejarPublicarCapa}/> : null
      }
      {openEditEmailDialog ? (
          <ReceptorEmailForm
              open={openEditEmailDialog}
              handleClose={handleCloseEditEmailDialog}
              handleReceptorEmail={handleEditReceptorEmail}
              edit={true}
              usuario={currentReceptorEmail}
          />
      ) : null}
      {openAddPiezomDialog ? (
        <AddAcelerometroDialog
          open={openAddPiezomDialog}
          handleClose={handleCloseAddPiezomDialog}
          handleSubmit={
            currentAcelerometro ? handleEditAcelerometro : handleAddAcelerometro
          }
          updatingThresholds={updatingThresholds}
          currentAcelerometro={currentAcelerometro}
        />
      ) : null}
      {openPublicar && (
        <PublicarDialog
          open={openPublicar}
          handleClose={handleClosePublicar}
          handleSubmit={handlePublicar}
        />
      )}
      {openImportarDatosDialog ? (
        <ImportDataAcelerometroDialog
          open={openImportarDatosDialog}
          handleClose={handleCloseImportarDatosDialog}
          handleSubmit={handleImportarDatos}
        />
      ) : null}
    </div>
  );
};
export default Acelerometros;
