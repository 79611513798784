import axios from "../config/axiosInstance";
import { curvasNivelBaseUrl } from "../config/config";
import errorHandle from "./tratarError";

export const curvasNivelService = {
  crearGrupo: ({ nombre, areaId }) => {
    return axios("api/curva-nivel/grupo", {
      baseURL: curvasNivelBaseUrl,
      method: "post",
      data: { nombre, areaId },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupos: async () => {
    return axios("api/curva-nivel/grupos", {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/api/curva-nivel/grupo/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrupo: ({ id, nombre, areaId }) => {
    return axios(`/api/curva-nivel/grupo/${id}`, {
      baseURL: curvasNivelBaseUrl,
      data: { id, nombre, areaId },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrupo: (id) => {
    return axios(`/api/curva-nivel/grupo/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarCurvasNivel: ({ grupoId, curvaId }) => {
    return axios(`/api/curva-nivel/grupo/${grupoId}/curva/${curvaId}`, {
      baseURL: curvasNivelBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCurvaNivel: async (id) => {
    return axios(`/api/curva-nivel/curva/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearCurvasNivel: ({ idGrupo, formData }) => {
    return axios(`/api/curva-nivel/grupo/${idGrupo}/upload-curva-nivel`, {
      baseURL: curvasNivelBaseUrl,
      method: "post",
      data: formData,
      headers: {
      "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        const err = errorHandle(error);
        return err;
      });
  },
  descargarCurvasNivel: ({ grupoId, curvaId }) => {
    return axios(
      `/api/curva-nivel/grupo/${grupoId}/descargar-curva/${curvaId}`,
      {
        baseURL: curvasNivelBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  publicarCapa: ({ curvaNivelId, nombre, url, fechaCreado }) => {
    return axios("api/curva-nivel/capa", {
      baseURL: curvasNivelBaseUrl,
      method: "post",
      data: {
        curvaNivelId,
        nombre,
        url,
        fechaCreado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapas: async () => {
    return axios("api/curva-nivel/capas", {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/curva-nivel/capa/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, creado, curvaNivelId}) => {
    return axios(`api/curva-nivel/capa/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "put",
      data: {
        curvaNivelId,
        id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/curva-nivel/capa/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
};
