import axios from "../config/axiosInstance";
import {revanchasBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const revanchaService = {
  //grupo
  crearGrupo: ({ nombre, areaId }) => {
    return axios("api/v1/grupos", {
      baseURL: revanchasBaseUrl,
      method: "post",
      data: { nombre, areaId },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupos: () => {
    return axios("api/v1/grupos", {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: (id) => {
    return axios(`api/v1/grupos/${id}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupoByRevanchaId: (id) => {
    return axios(`api/v1/grupos/revancha/${id}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarGrupo: ({ grupoId, nombre, areaId }) => {
    return axios(`api/v1/grupo/${grupoId}`, {
      baseURL: revanchasBaseUrl,
      method: "put",
      data: {
        id: parseInt(grupoId),
        nombre,
        areaId,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrupo: ({ id }) => {
    return axios(`api/v1/grupos/${id}`, {
      baseURL: revanchasBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  //Puntos de revancha
  /**
   * Retorna un punto por su id
   * @param {string} puntoRevanchaId
   */
  getPunto: ({ puntoRevanchaId }) => {
    return axios(`api/v1/puntos-revanchas/${puntoRevanchaId}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  /**
   * Retorna un punto de una revancha
   * @param {string} puntoRevanchaId
   * @param {string} revanchaId
   */
  getPuntoRevancha: ({ puntoRevanchaId, revanchaId }) => {
    return axios(
      `api/v1/puntos-revanchas/revancha/${revanchaId}/punto-revancha/${puntoRevanchaId}`,
      {
        baseURL: revanchasBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  /**
   * Retorna los puntos de una revancha
   * @param {string} revanchaId
   */
  getPuntosRevancha: ({ revanchaId }) => {
    return axios(`api/v1/puntos-revanchas/revancha/${revanchaId}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  crearPunto: ({ revanchaId, etiqueta }) => {
    return axios("api/v1/puntos-revanchas", {
      baseURL: revanchasBaseUrl,
      method: "post",
      data: {
        revanchaId,
        etiqueta,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  editarPunto: ({ revanchaId, puntoRevanchaId, etiqueta }) => {
    return axios(
      `api/v1/puntos-revanchas/revancha/${revanchaId}/punto-revancha/${puntoRevanchaId}`,
      {
        baseURL: revanchasBaseUrl,
        method: "put",
        data: {
          revanchaId,
          puntoRevanchaId,
          etiqueta,
        },
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  eliminarPunto: ({ revanchaId, puntoRevanchaId }) => {
    return axios(
      `api/v1/puntos-revanchas/revancha/${revanchaId}/punto-revancha/${puntoRevanchaId}`,
      {
        baseURL: revanchasBaseUrl,
        method: "delete",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  //Revanchas
  getRevancha: ({ revanchaId }) => {
    return axios(`api/v1/revanchas/${revanchaId}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getRevanchaGrupo: ({ revanchaId, grupoId }) => {
    return axios(`api/v1/revanchas/grupo/${grupoId}/revancha/${revanchaId}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getRevanchasGrupo: ({ grupoId }) => {
    return axios(`api/v1/revanchas/grupo/${grupoId}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  descargarPuntosRevancha: ({
    revanchaId,
    etiquetaPuntoRevancha,
    desde,
    hasta,
  }) => {
    return axios(
      `api/v1/revanchas/${revanchaId}/punto-revancha/${etiquetaPuntoRevancha}/get-data?desde=${desde}&hasta=${hasta}`,
      {
        baseURL: revanchasBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearRevancha: ({ grupoId, umbral, tipoRevanchaId }) => {
    return axios("api/v1/revanchas", {
      baseURL: revanchasBaseUrl,
      method: "post",
      data: {
        grupoId,
        umbral,
        tipoRevanchaId,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarRevancha: ({ revanchaId, grupoId, tipoRevanchaId, umbral }) => {
    return axios(`api/v1/revanchas/grupo/${grupoId}/revancha/${revanchaId}`, {
      baseURL: revanchasBaseUrl,
      method: "put",
      data: {
        id: revanchaId,
        grupoId,
        tipoRevanchaId,
        umbral,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarRevancha: ({ grupoId, revanchaId }) => {
    return axios(`api/v1/revanchas/grupo/${grupoId}/revancha/${revanchaId}`, {
      baseURL: revanchasBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  subirArchivoPuntosRevancha: ({ revanchaId, formData }) => {
    console.log(formData)
    return axios(`api/v1/revanchas/${revanchaId}/importar-dato-punto-revancha`, {
      baseURL: revanchasBaseUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/plain",
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  subirPlantillaPuntosRevancha: ({ revanchaId, formData }) => {
    //console.log(formData)
    return axios(`api/v1/revanchas/${revanchaId}/importar-plantilla-csv`, {
      baseURL: revanchasBaseUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getExcelConfiguration: () => {
    return axios(`api/v1/revanchas/get-excel-config`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearConfiguracionDeExcel: ({ initialRow, etiquetaCell, ubicacionNorteCell, ubicacionEsteCell,
                                cotaInferiorCell, cotaSuperiorCell, diferenciaCotaCell }) => {
    return axios(`api/v1/revanchas/excel-config`, {
      baseURL: revanchasBaseUrl,
      method: "post",
      data: {
        initialRow,
        etiquetaCell,
        ubicacionNorteCell,
        ubicacionEsteCell,
        cotaInferiorCell,
        cotaSuperiorCell,
        diferenciaCotaCell
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarConfiguracionDeExcel: ({ initialRow, etiquetaCell, ubicacionNorteCell, ubicacionEsteCell,
                                cotaInferiorCell, cotaSuperiorCell, diferenciaCotaCell }) => {
    return axios(`api/v1/revanchas/update-excel-config`, {
      baseURL: revanchasBaseUrl,
      method: "put",
      data: {
        initialRow,
        etiquetaCell,
        ubicacionNorteCell,
        ubicacionEsteCell,
        cotaInferiorCell,
        cotaSuperiorCell,
        diferenciaCotaCell
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  /**Capas */
  publicarCapa: ({ revanchaId, nombre, url, tipoRevanchaId, creado }) => {
    console.log('TIPO', tipoRevanchaId)
    return axios(`api/v1/capas`, {
      baseURL: revanchasBaseUrl,
      method: "post",
      data: {
        revanchaId,
        nombre,
        url,
        tipoRevanchaId,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getTipos: () => {
    return axios("api/v1/revanchas/tipos-revanchas", {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapas: async () => {
    return axios("api/v1/capas", {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/v1/capas/${id}`, {
      baseURL: revanchasBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, itemId, creado}) => {
    let capaId = parseInt(id);
    return axios(`api/v1/capas/${id}`, {
      baseURL: revanchasBaseUrl,
      method: "put",
      data: {
        id: capaId,
        nombre,
        itemId,
        creado,
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/v1/capas/${id}`, {
      baseURL: revanchasBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapaByRevnahcaId: async (revanchaId) => {
    return axios(`api/v1/capas/grupo/${revanchaId}`, {
      baseURL: revanchasBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
