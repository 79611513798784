import axios from "../config/axiosInstance";
import { areasBaseUrl, sectoresBaseUrl } from "../config/config";
import errorHandle from "./tratarError";

export const sectionService = {
  getAllAreas: async () => {
    return axios("api/areas/areas", {
      baseURL: areasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  createArea: (data) => {
    return axios("/api/areas/area", {
      baseURL: areasBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getArea: (id) => {
    return axios(`/api/areas/area/${id}`, {
      baseURL: areasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateArea: (data) => {
    return axios(`/api/areas/area/${data.id}`, {
      baseURL: areasBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  deleteArea: (id) => {
    return axios(`/api/areas/area/${id}`, {
      baseURL: areasBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getAllSectores: async () => {
    return axios("/api/sectores/sectores", {
      baseURL: sectoresBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getSector: (id) => {
    return axios(`/api/sectores/sector/${id}`, {
      baseURL: sectoresBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateSector: (data) => {
    return axios(`/api/sectores/sector/${data.id}`, {
      baseURL: sectoresBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  deleteSector: (id) => {
    return axios(`/api/sectores/sector/${id}`, {
      baseURL: sectoresBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  createSector: (data) => {
    return axios("/api/sectores/sector", {
      baseURL: sectoresBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
};
