// import axios from 'axios';
import axios from "../config/axiosInstance";
import { batimetriasUrl } from "../config/config";
import errorHandle from "./tratarError";

export const batimetriasService = {
  /* GRUPOS MISIONES BATIMETRIAS */
  crearGrupoBatimetria: ({ nombre }) => {
    return axios("api/GrupoMisionBatimetria", {
      baseURL: batimetriasUrl,
      method: "post",
      data: { nombre, areaId: 1 },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGruposBatimetrias: async () => {
    return axios("api/GrupoMisionBatimetria", {
      baseURL: batimetriasUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupoBatimetriaById: async (grupoId) => {
    return axios(`/api/GrupoMisionBatimetria/${grupoId}`, {
      baseURL: batimetriasUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  actualizarGrupo: ({ grupoId, nombre }) => {
    return axios(`/api/GrupoMisionBatimetria/${grupoId}`, {
      baseURL: batimetriasUrl,
      data: { grupoId, nombre, areaId: 1 },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrupo: (grupoId) => {
    return axios(`/api/GrupoMisionBatimetria/${grupoId}`, {
      baseURL: batimetriasUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  /* MISIONES BATIMETRIS */
  crearMisionBatimetria: ({ grupoId, fechaMedicion }) => {
    return axios(`api/MisionBatimetria/grupo/${grupoId}`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: { grupoId, fechaMedicion },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getMisionesBatimetriaByGrupoId: async (grupoId) => {
    return axios(`/api/MisionBatimetria/grupo/${grupoId}`, {
      baseURL: batimetriasUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarMisionBatimetria: (misionId) => {
    return axios(`/api/MisionBatimetria/${misionId}`, {
      baseURL: batimetriasUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  /* Datos Batimetrias */
  importarOrtofoto: ({ misionId, formData }) => {
    return axios(`api/Ortofoto/${misionId}/upload-ortofoto`, {
          baseURL: batimetriasUrl,
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
          },
        })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  importarIsobata: ({ misionId, formData }) => {
    return axios(`api/Isobata/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearMapaDistancia: ({grupoId, misionId, itemId, urlStorage,
                         distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7}) => {
    return axios(`api/MapaDistancia/grupo/${grupoId}/mision/${misionId}`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: {
        grupoId, misionId, itemId, urlStorage,
        distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  importarMapaDistancia: ({ misionId, formData }) => {
    return axios(`api/MapaDistancia/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearMapaFactorForma: ({grupoId, misionId, itemId, urlStorage,
                         area, largo, anchoPromedio, factorForma}) => {
    return axios(`api/MapaFactorForma/grupo/${grupoId}/mision/${misionId}`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: {
        grupoId, misionId, itemId, urlStorage,
        area, largo, anchoPromedio, factorForma
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  importarMapaFactorForma: ({ misionId, formData }) => {
    return axios(`api/MapaFactorForma/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearMapaSuperficieRelave: ({grupoId, misionId, itemId, urlStorage, superficieOcupacionRelave}) => {
    return axios(`api/MapaSuperficie/grupo/${grupoId}/mision/${misionId}`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: {
        grupoId, misionId, itemId, urlStorage, superficieOcupacionRelave
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  importarMapaSuperficieRelave: ({ misionId, formData }) => {
    return axios(`api/MapaSuperficie/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearDatosSuperficie: ({grupoId, misionId, itemIdAgua, urlStorageAgua, urlStorageCuerposAgua, itemIdCuerposAgua,
                           superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
                           cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
                           volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro}) => {
    return axios(`api/DatosSuperficie/grupo/${grupoId}/mision/${misionId}`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: {
        grupoId, misionId, urlStorageAgua, itemIdAgua, urlStorageCuerposAgua, itemIdCuerposAgua,
        superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
        cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
        volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  importarDatosSuperficie: ({ misionId, formFiles }) => {
    return axios(`api/DatosSuperficie/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formFiles,
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
