// import axios from 'axios';
import axios from "../config/axiosInstance";
import {mapasBaseUrl} from "../config/config";
import errorHandle from "./tratarError";
import { toast } from "react-toastify";

export const mapaService = {
  crearGrupo: ({ nombre }) => {
    return axios("api/mapa-elevacion/grupo/", {
      baseURL: mapasBaseUrl,
      method: "post",
      data: { nombre, areaId: 1 },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupos: async () => {
    return axios("api/mapa-elevacion/grupos", {
      baseURL: mapasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/api/mapa-elevacion/grupo/${id}`, {
      baseURL: mapasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrupo: ({ id, nombre }) => {
    return axios(`/api/mapa-elevacion/grupo/${id}`, {
      baseURL: mapasBaseUrl,
      data: { id, nombre },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrupo: (id) => {
    return axios(`/api/mapa-elevacion/grupo/${id}`, {
      baseURL: mapasBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getMapas: async () => {
    return axios("/api/mapa-elevacion/grupo", {
      baseURL: mapasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getMapa: (data) => {
    return axios(
      `/api/mapa-elevacion/grupo/${data.grupoId}/mapa/${data.mapaId}`,
      {
        baseURL: mapasBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getMapaById: (mapaId) => {
    return axios(
        `/api/mapa-elevacion/mapa/${mapaId}`,
        {
          baseURL: mapasBaseUrl,
          method: "get",
        }
    )
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateMapa: (data) => {
    return axios(`/api/mapa-elevacion/grupo/${data.mapaId}`, {
      baseURL: mapasBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarMapa: ({ grupoId, mapaId }) => {
    return axios(`/api/mapa-elevacion/grupo/${grupoId}/mapa/${mapaId}`, {
      baseURL: mapasBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearMapa: (data) => {
    return axios("/api/mapa-elevacion/grupo", {
      baseURL: mapasBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  descargarMapa: ({ grupoId, mapaId }) => {
    return axios(
      `/api/mapa-elevacion/grupo/${grupoId}/descargar-mapa/${mapaId}`,
      {
        baseURL: mapasBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  /**Capas */
  publicarCapa: ({ mapaId, nombre, url, fechaCreado }) => {
    return axios("api/mapa-elevacion/capa", {
      baseURL: mapasBaseUrl,
      method: "post",
      data: {
        mapaElevacionId: mapaId,
        nombre,
        url,
        fechaCreado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  uploadMapa: async ({ idGrupo, formData }) => {
    const toastId = toast.loading("Importando datos");
    return axios(`api/mapa-elevacion/grupo/${idGrupo}/upload-mapa-elevacion`, {
      baseURL: mapasBaseUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/plain",
      },
    })
      .then((result) => {
        toast.update(toastId, {
          render: "Los datos se importaron correctamente",
          type: "success",
          isLoading: false,
        });
        return result;
      })
      .catch((error) => {
        const err = errorHandle(error);
        toast.update(toastId, {
          render: err.message,
          type: "error",
          isLoading: false,
        });
        return err;
      });
  },
  getCapas: async () => {
    return axios("api/mapa-elevacion/capas", {
      baseURL: mapasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/mapa-elevacion/capa/${id}`, {
      baseURL: mapasBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, mapaElevacionId, fechaCreado}) => {
    return axios(`api/mapa-elevacion/capa/${id}`, {
      baseURL: mapasBaseUrl,
      method: "put",
      data: {
        id,
        nombre,
        url,
        mapaElevacionId,
        fechaCreado,
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/mapa-elevacion/capa/${id}`, {
      baseURL: mapasBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
