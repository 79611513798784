import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import DTMIcon from '../../../../assets/terrain.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import { dtmService } from '../../../../services/DTMService';
import './ModelosTerreno.css'
import CapaDTMMenuBtn from "../../../Dialogs/DTM/CapaDTMMenuBtn";
import EditarCapaDialog from "../../../Dialogs/DTM/EditarCapaDialog";
import {toast} from "react-toastify";
import {ACTIONS, useDrawerMenu} from "../../../../providers/DrawerMenuProvider";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#343434",
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eaeaea",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CapaModelosTerreno = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu()

    const [capaDTMs, setCapaDTMs] = useState();
    const [modeloTerreno, setModeloTerreno] = useState();
    const [loadingGroup, setLoadingGroup] = useState(false);

    const [openEditCapaDialog, setOpenEditCapaDialog] = useState(false);
    const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

    const toastId = React.useRef(null);
    const notify = (mensaje) =>
        (toastId.current = toast(`${mensaje}`, { autoClose: false }));
    const update = (mensaje, tipo) => {
        toast.update(toastId.current, {
            render: `${mensaje}`,
            type: tipo ? tipo : toast.TYPE.INFO,
            autoClose: 5000,
        });
    };

    useEffect(() => {
        let mounted = true;
        mounted && cargarCapasDTMs();
        return () => mounted = false;
    }, [id]);

    const cargarCapasDTMs = () => {
        setLoadingGroup(true);
        dtmService.getCapa(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setCapaDTMs(res.data);
                } else {
                    console.log(res)
                }
                setLoadingGroup(false);
            })
    };

    useEffect(() => {
        loadModeloTerreno()
    }, [capaDTMs]);

    const loadModeloTerreno = () => {
        if(capaDTMs) {
            dtmService.getDtmById(capaDTMs.modeloTerrenoDigitalId)
                .then(res => {
                    if (res.status >= 200 && res.status < 300) {
                        setModeloTerreno(res.data);
                    } else {
                        console.log(res)
                    }
                })
        }
    };

    const handleClickEditCapa = (capa) => {
        setOpenEditCapaDialog(true);
    };

    const handleClickDeleteItem = () => {
        setOpenDeleteItemDialog(true);
    };

    const handleCloseEditCapaDialog = () => {
        setOpenEditCapaDialog(false);
    };

    const handleCloseDeleteItemDialog = () => {
        setOpenDeleteItemDialog(false);
    };

    const handleEditCapaDTMs = (nombre, url) => {
        handleCloseEditCapaDialog();
        setLoadingGroup(true);
        notify("Editando Capa Modelo Terreno Digital...")
        dtmService.editarCapa({
            id: id,
            nombre,
            url,
            fechaCreado: capaDTMs.fechaCreado,
            modeloTerrenoDigitalId: capaDTMs.modeloTerrenoDigitalId
        })
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    cargarCapasDTMs();
                    update(
                        "La Capa se editó correctamente 👌",
                        toast.TYPE.SUCCESS
                    );
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al editar la Capa 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
                setLoadingGroup(false);
            })
    };

    const handleDeleteItem = () => {
        handleCloseDeleteItemDialog();
        notify("Eliminando Capa Modelo Terreno Digital...")
        dtmService.eliminarCapa(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    update(
                        "La Capa se elimió correctamente 👌",
                        toast.TYPE.SUCCESS
                    );
                    setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU})
                    navigate("/")
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al eliminar la Capa 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
                setLoadingGroup(false);
            })

    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    };

    return (
        <div className="area-container">
            {loadingGroup ?
                <div className='loading'><CircularProgress /></div> :
                <>
                    <div className="head-area">
                        <div className="head-name">
                            <img src={DTMIcon} alt="" width={18} height={18} />
                            <h2 className="name-area">{capaDTMs ? capaDTMs.nombre : 'Capa de DTMs inexistente'}</h2>
                        </div>

                        {capaDTMs
                            ? <CapaDTMMenuBtn
                                editarAction={handleClickEditCapa}
                                eliminarAction={handleClickDeleteItem}
                            />
                            : null}
                    </div>
                    <>
                        <p>{modeloTerreno && <b>Modelo Terreno Digital:</b>} {modeloTerreno ? modeloTerreno.key : '-'}</p>
                        <p>{capaDTMs && <b>Id de Publicación:</b>} {capaDTMs ? capaDTMs.url : ''}</p>
                        <p>{capaDTMs && <b>Fecha de
                            Creado:</b>} {capaDTMs ? moment(capaDTMs.fechaCreado).utc().format("DD/MM/YYYY hh:mm:ss") : '-'}</p>
                    </>
                </>
            }

            <DeleteItemDialog
                open={openDeleteItemDialog}
                handleClose={handleCloseDeleteItemDialog}
                handleDeleteItem={handleDeleteItem} />
            {openEditCapaDialog
                ? <EditarCapaDialog
                    open={openEditCapaDialog}
                    handleClose={handleCloseEditCapaDialog}
                    handleEditarCapa={handleEditCapaDTMs}
                    capa={capaDTMs}
                /> : null}
        </div>
    );
}
export default CapaModelosTerreno;


