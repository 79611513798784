import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import moment from 'moment';
import fileDownload from 'js-file-download'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import InsightsIcon from "@mui/icons-material/Insights";
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import AddDialog from '../../../Dialogs/EjesMuro/AddDialog'
import EditDialog from '../../../Dialogs/EjesMuro/EditDialog';
import { ejesmuroService } from '../../../../services/EjesMuroService';
import MenuBtn from '../../../Dialogs/EjesMuro/MenuBtn';
import GrupoMenuBtn from '../../../Dialogs/EjesMuro/GrupoMenuBtn';
import './EjesMuro.css'
import NotificacionMensaje from '../../../NotificacionMensaje';
import PublicarDialog from '../../../Dialogs/EjesMuro/PublicarDialog';
import {toast} from "react-toastify";
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const EjesMuro = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grupo, setGrupo] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);

  const [setpenAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  const [current, setCurrent] = useState(null);
  const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
  const [openPublicar, setOpenPublicar] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };

  useEffect(() => {
    cargarGrupo();
  }, [id]);

  const cargarGrupo = () => {
    setLoadingGroup(true);
    ejesmuroService.getGrupo(parseInt(id))
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setGrupo(res.data.grupo);
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
      .catch(err => {
        console.error(err);
        setLoadingGroup(false);
      })
  };

  const handleClickEditGrupo = () => {
    setOpenEditDialog(true);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEditGrupo = (nombre) => {
    handleCloseEditDialog(false)
    ejesmuroService.updateGrupo({ id, nombre })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargarGrupo();
        } else {
          console.log(res)
        }
      })
  }

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (current) {
      ejesmuroService.eliminarEjeMuro({
        ejeId: current.id,
        grupoEjeId: grupo.id
      })
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            cargarGrupo();
          } else {
            console.log(res)
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      ejesmuroService.deleteGrupo(id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
            navigate('/');
          } else { console.log(res) }
        })
        .catch(err => {
          console.error(err)
        })
    }
  };

  //menu-option-row
  const handleClickAdd = () => {
    setOpenAddDialog(true);
  }

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setCurrent(null);
  };

  const handleAddEje = ({ fecha, ficheros, nombreCapa }) => {
    handleCloseAddDialog(false)
    setImportandoDatos({ loading: true, status: "Importando" })
    notify("Importando nuevo Eje de Muro...")
    const formData = new FormData()
    formData.append("UploadFile", ficheros)
    formData.append("NombreCapa", nombreCapa)
    formData.append("FechaCreado", new Date(fecha).toISOString())
    console.log(formData)
    ejesmuroService.uploadEjeMuro({
      idGrupo: grupo.id,
      formData
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        console.log(res)
        setCurrent(null);
        cargarGrupo();
        update(
            "Los datos fueron importado correctamente 👌",
            toast.TYPE.SUCCESS
        );
        setImportandoDatos({ loading: false, status: "Hecho" })
      } else {
        console.log(res)
        update(
            `Ocurrio un error al importar los datos 🤯 ${res.message}`,
            toast.TYPE.ERROR
        );
        setImportandoDatos({ loading: false, status: "Fallido" })
      }
    });
  }

  const handleDescargar = (eje) => {
    ejesmuroService.descargarEjeMuro({
      grupoEjesId: grupo.id,
      ejeId: eje.id
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          fileDownload(res.data, `(${moment(eje.creado).format("DD-MM-YYYY")})eje.geojson`)
        } else { console.log(res) }
      })
  }

  const handleDejarPublicar = () => {
    alert("Esta acción no esta disponible")
  }

  const handleClosePublicar = () => {
    setOpenPublicar(false)
  }

  const handleClickPublicar = (eje) => {
    setCurrent(eje)
    setOpenPublicar(true)
  }

  const handlePublicar = ({ url, nombre, fecha }) => {
    handleClosePublicar()
    ejesmuroService.publicarCapa({ nombre, ejeId: current.id, url: url, creado: fecha })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          let ejes = grupo?.ejes.map((eje) => {
            if (eje.id === current.id) {
              return {
                ...eje,
                publicado: true
              }
            } else {
              return eje;
            }
          })
          setGrupo({ ...grupo, ejes })
          setCurrent()
        } else {
          console.log(res)
        }
      })
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setImportandoDatos({ ...importandoDatos, status: 'Importando' })
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupo.modelos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTabla = () => {
    if (rowsPerPage > 0) {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Identificador</StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Publicado</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? grupo.modelos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : grupo.modelos
            ).map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">{row.id}</StyledTableCell>
                <StyledTableCell align="center">{row.key}</StyledTableCell>
                <StyledTableCell align="center">{row.fechaCreado ? moment(row.fechaCreado).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.publicado ?
                    <PublicadoIcon fontSize="small" />
                    :
                    <PublicadoIcon fontSize="small" color="disabled" />
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  <MenuBtn
                    disabled={importandoDatos.importando}
                    // dejarPublicarAction={row.publicado && (() => handleDejarPublicar(row))}
                    publicarAction={!row.publicado && (() => handleClickPublicar(row))}
                    descargarAction={() => handleDescargar(row)}
                    eliminarAction={() => {
                      setCurrent(row);
                      handleClickDeleteItem();
                    }} />
                </StyledTableCell>
              </StyledTableRow>))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: grupo.modelos.length }]}
                  colSpan={12}
                  count={grupo.modelos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    } else {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No existen ejes de muro</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    }
  }

  return (
    <div className="area-container">
      {loadingGroup ?
        <div className='loading'><CircularProgress /></div> :
        <>
          <div className="head-area">
            <div className="head-name">
              <InsightsIcon/>
              <h2 className="name-area">{grupo ? grupo.nombre : 'Grupo de Ejes de muro inexistente'}</h2>
            </div>
            {grupo
              ?
                <div className="actionsButtons">
                  <Button
                      onClick={cargarGrupo}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoMenuBtn
                    editarAction={handleClickEditGrupo}
                    eliminarAction={handleClickDeleteItem}
                  />
                </div>
              : null}
          </div>
          {grupo ?
            <>
              <Button
                disabled={importandoDatos.importando}
                variant="contained"
                className="btn-add"
                onClick={handleClickAdd}
              >{importandoDatos.importando && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}Adicionar
              </Button>
              <div className="container-table">
                {renderTabla()}
              </div>
            </> : null}
        </>
      }

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      {openEditDialog
        ? <EditDialog
          open={openEditDialog}
          handleClose={handleCloseEditDialog}
          handleEditarGrupo={handleEditGrupo}
          grupo={grupo}
        /> : null}
      {setpenAddDialog ?
        <AddDialog
          open={setpenAddDialog}
          handleClose={handleCloseAddDialog}
          handleSubmit={handleAddEje}
        /> : null}
      {openPublicar && <PublicarDialog
        open={openPublicar}
        handleClose={handleClosePublicar}
        handleSubmit={handlePublicar}
      />}
      <NotificacionMensaje
        open={importandoDatos.status === 'Fallido'}
        onClose={handleCloseAlert}
        message="Ha fallado la subida de los archivos"
      />
    </div>
  );
}
export default EjesMuro;


