import axios from '../config/axiosInstance';
import {insarBaseUrl} from '../config/config';
import errorHandle from './tratarError';

export const insarService = {
    /**Capturas Insar endpoint*/
    getSeries: () => {
        return axios('/api/capturas', {
            baseURL: insarBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    getSerie: (id) => {
        return axios(`/api/capturas/${id}`, {
            baseURL: insarBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    crearSerie: ({ etiqueta, fechaCreado }) => {
        return axios('/api/capturas', {
            baseURL: insarBaseUrl,
            method: 'post',
            data: { etiqueta, fechaCreado }
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    editarSerie: ({ id, etiqueta, fechaCreado }) => {
        return axios(`/api/capturas/${id}`, {
            baseURL: insarBaseUrl,
            data: { id, etiqueta, fechaCreado },
            method: 'put',
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    importarSerieTiempo: ({ capturaId, fechaCaptura, uploadFile }) => {
        return axios.postForm(`${insarBaseUrl}/api/capturas/${capturaId}/upload-serie-tiempo`, {
            fechaCaptura,
            uploadFile
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    eliminarSerie: (id) => {
        return axios(`/api/capturas/${id}`, {
            baseURL: insarBaseUrl,
            method: 'delete'
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },

    /**Series de Tiempo endpoint*/
    getSeriesTiempo: ({ capturaInSARId }) => {
        return axios(`/api/capturas/${capturaInSARId}/series-tiempo`, {
            baseURL: insarBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    getSerieTiempo: ({ capturaInSARId, serieTiempoId }) => {
        return axios(`/api/capturas/${capturaInSARId}/series-tiempo/${serieTiempoId}`, {
            baseURL: insarBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    crearSerieTiempo: ({ fechaCreado, tipoSerieId, uri, capturaInSARId, escala }) => {
        return axios(`/api/capturas/${capturaInSARId}/series-tiempo`, {
            baseURL: insarBaseUrl,
            method: 'post',
            data: { fechaCreado: new Date(fechaCreado).toISOString(), tipoSerieId, uri, capturaInSARId, escala }
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    editarSerieTiempo: ({ uri, storageUri, fechaCaptura, capturaInSARId }) => {
        return axios(`/api/capturas/${capturaInSARId}/series-tiempo`, {
            baseURL: insarBaseUrl,
            method: 'put',
            data: { uri, storageUri, fechaCaptura: new Date(fechaCaptura).toISOString(), capturaInSARId }
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    editarEscalaColores: (capturaId, escala) => {
        return axios(`/api/capturas/${capturaId}/series-tiempo/update-escala-colores`, {
            baseURL: insarBaseUrl,
            data: { capturaId, escala },
            method: 'put',
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },

    /**Deformaciones Acumuladas endpoint*/
    getDeformacionesAcumuladas: ({ capturaInSARId }) => {
        return axios(`/api/DeformacionesAcumuladas/${capturaInSARId}`, {
            baseURL: insarBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    editarDeformacionesAcumuladas: ({ deformacionId, periodo, uri = null, storageUri = null, escala }) => {
        return axios(`/api/DeformacionesAcumuladas/${deformacionId}`, {
            baseURL: insarBaseUrl,
            method: 'put',
            data: { deformacionId, periodo, uri, storageUri, escala }
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    importarDeformacionAcumulada: ({ capturaId, formData }) => {
        return axios(`api/DeformacionesAcumuladas/${capturaId}/upload-deformacion-acumulada`, {
            baseURL: insarBaseUrl,
            method: "post",
            data: formData,
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    crearDeformacionesAcumuladas: ({ capturaId, periodo, uri, storageUri, escala }) => {
        return axios('/api/DeformacionesAcumuladas', {
            baseURL: insarBaseUrl,
            method: 'post',
            data: { periodo, capturaId, uri, storageUri, escala }
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
    eliminarDeformacionesAcumuladas: (defAcumId) => {
        return axios(`/api/DeformacionesAcumuladas/${defAcumId}`, {
            baseURL: insarBaseUrl,
            method: 'delete',
        }).then((result) => result)
            .catch((error) => errorHandle(error))
    },
};
