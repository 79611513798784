import axios from "../config/axiosInstance";
import {estacionesMetBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const estacionesMetService = {
    /* GRUPOS ESTACIONES */
    getGroups: async () => {
        return axios("/api/estacion-meteorologica/grupos", {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    createGroup: ({ nombre, areaId }) => {
        return axios("/api/estacion-meteorologica/grupo", {
            baseURL: estacionesMetBaseUrl,
            method: "post",
            data: { nombre, areaId },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getGroup: (grupoId) => {
        return axios(`/api/estacion-meteorologica/grupo/${grupoId}`, {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    updateGroup: (data) => {
        return axios(`/api/estacion-meteorologica/grupo/${data.grupoId}`, {
            baseURL: estacionesMetBaseUrl,
            data: data,
            method: "put",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    publicarGroup: ({ grupoId }) => {
        return axios(`/api/estacion-meteorologica/grupo/${grupoId}/publicar`, {
            baseURL: estacionesMetBaseUrl,
            data: { grupoId },
            method: "post",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    deleteGroup: (grupoId) => {
        return axios(`/api/estacion-meteorologica/grupo/${grupoId}`, {
            baseURL: estacionesMetBaseUrl,
            data: {grupoId},
            method: "delete",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    /* ESTACIONES */
    getEstacionesByGrupoId: ({grupoId}) => {
        return axios(`api/estacion-meteorologica/estaciones/grupo/{grupoId}`, {
            baseURL: estacionesMetBaseUrl
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    crearEstacionesMet: (data) => {
        return axios(`api/estacion-meteorologica/estacion`, {
            baseURL: estacionesMetBaseUrl,
            method: "post",
            data: data
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    editarEstacionesMet: (data) => {
        return axios(`api/estacion-meteorologica/estacion/grupo/${data.grupoId}/estacion/${data.estacionId}`, {
            baseURL: estacionesMetBaseUrl,
            method: "put",
            data: data
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    crearExcelConfig: (data) => {
        return axios(`api/estacion-meteorologica/estacion/${data.estacionId}/excel-config`, {
            baseURL: estacionesMetBaseUrl,
            method: "post",
            data: data
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    editarExcelConfig: (data) => {
        return axios(`api/estacion-meteorologica/estacion/${data.estacionId}/excel-config`, {
            baseURL: estacionesMetBaseUrl,
            method: "put",
            data: data
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    importarDatos: ({estacionId, formData }) => {
        return axios(`api/estacion-meteorologica/estacion/${estacionId}/importar-datos`, {
            baseURL: estacionesMetBaseUrl,
            method: "post",
            data: formData
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    deleteEstacion: (estacionId) => {
        return axios(`/api/estacion-meteorologica/estacion/${estacionId}`, {
            baseURL: estacionesMetBaseUrl,
            method: "delete",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getConfiguracionExcelByEstacion: async ({estacionId}) => {
        return axios(`/api/estacion-meteorologica/estacion/${estacionId}/excel-config`, {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    /* CAPAS */
    getCapas: async () => {
        return axios("/api/estacion-meteorologica/capas", {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getCapa: (id) => {
        return axios(`/api/estacion-meteorologica/capa/${id}`, {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => {
                return result
            })
            .catch((error) => {
                const err = errorHandle(error);
                return err;
            });
    },
    editarCapa: ({id, nombre, itemId, fechaCreado }) => {
        return axios(`/api/estacion-meteorologica/capa/${id}`, {
            baseURL: estacionesMetBaseUrl,
            method: "put",
            data: {
                id,
                nombre,
                itemId,
                fechaCreado
            },
        })
            .then((result) => result)
            .catch((error) => {
                const err = errorHandle(error);
                return err;
            });
    },
    eliminarCapa: ({ capaId }) => {
        return axios(`/api/estacion-meteorologica/capa/${capaId}`, {
            baseURL: estacionesMetBaseUrl,
            method: "delete",
        })
            .then((result) => result)
            .catch((error) => {
                const err = errorHandle(error);
                return err;
            });
    },
};