// import axios from 'axios';
import axios from "../config/axiosInstance";
import { ortofotoBaseUrl } from "../config/config";
import errorHandle from "./tratarError";
import { toast } from "react-toastify";

export const ortofotosService = {
  crearGrupoOrtofotos: ({ nombre }) => {
    return axios("api/v1/grupos", {
      baseURL: ortofotoBaseUrl,
      method: "post",
      data: { nombre },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGruposOrtofotos: async () => {
    return axios("api/v1/grupos", {
      baseURL: ortofotoBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/api/v1/grupos/${JSON.stringify(id)}`, {
      baseURL: ortofotoBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGroup: ({ id, nombre }) => {
    return axios(`/api/v1/grupos/${id}`, {
      baseURL: ortofotoBaseUrl,
      data: { id, nombre },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  deleteGroup: (id) => {
    return axios(`/api/v1/grupos/${id}`, {
      baseURL: ortofotoBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  importarData: ({ id, ficheros }) => {
    const toastId = toast.loading("Importando datos");
    return axios(`/api/v1/grupos/${id}/importar-data`, {
      baseURL: ortofotoBaseUrl,
      method: "post",
      data: ficheros,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    })
      .then((result) => {
        toast.update(toastId, {
          render: "Los datos se importaron correctamente",
          type: "success",
          isLoading: false,
        });
        return result;
      })
      .catch((error) => {
        const err = errorHandle(error);
        toast.update(toastId, {
          render: err.message,
          type: "error",
          isLoading: false,
        });
        return err;
      });
  },
  getOrtofotos: async () => {
    return axios("/api/v1/ortofotos", {
      baseURL: ortofotoBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getOrtofoto: (id) => {
    return axios(`/api/v1/ortofotos/${id}`, {
      baseURL: ortofotoBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateOrtofoto: (data) => {
    return axios(`/api/v1/ortofotos/${data.ortofotoId}`, {
      baseURL: ortofotoBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarOrtofoto: (data) => {
    return axios(
      `/api/v1/ortofotos/${data.ortofotoId}/grupo/${data.grupoOrtofotosId}`,
      {
        baseURL: ortofotoBaseUrl,
        method: "delete",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearOrtofoto: (data) => {
    return axios("/api/v1/ortofotos", {
      baseURL: ortofotoBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  descargarOrtofoto: (data) => {
    return axios(
      `/api/v1/ortofotos/grupo/${data.grupoOrtofotosId}/ortofoto/${data.ortofotoId}/download`,
      {
        baseURL: ortofotoBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  uploadOrtofoto: async ({ idGrupo, formData }) => {
    return axios(`api/v1/ortofotos/${idGrupo}/upload-ortofoto`, {
      baseURL: ortofotoBaseUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/plain",
      },
    })
      .then((result) => result)
      .catch((error) =>errorHandle(error));
  },
  /**Capas */
  publicarCapa: ({ ortofotoId, nombre, url, creado }) => {
    return axios("api/v1/capas", {
      baseURL: ortofotoBaseUrl,
      method: "post",
      data: {
        ortofotoId,
        nombre,
        url,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapas: async () => {
    return axios("api/v1/capas", {
      baseURL: ortofotoBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/v1/capas/${id}`, {
      baseURL: ortofotoBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, creado}) => {
    return axios(`api/v1capas/${id}`, {
      baseURL: ortofotoBaseUrl,
      method: "put",
      data: {
        id,
        nombre,
        url,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/v1/capas/${id}`, {
      baseURL: ortofotoBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
};
