import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {Grid4x4} from "@mui/icons-material";


const CreateExcelConfigDialog = ({ open, handleClose, handleSubmit, currentExcelConfig = null }) => {
    const [excelConfig, setExelConfig] = useState(
        {
            estacionId: currentExcelConfig?.estacionId || "",
            inicialDataRow: currentExcelConfig?.inicialDataRow || "",
            alturaNieveCell: currentExcelConfig?.alturaNieveCell || "",
            alturaNieveUm: currentExcelConfig?.alturaNieveUm || "",
            direccionVientoCell: currentExcelConfig?.direccionVientoCell || "",
            direccionVientoUm: currentExcelConfig?.direccionVientoUm || "",
            humedadRelativaCell: currentExcelConfig?.humedadRelativaCell || "",
            humedadRelativaUm: currentExcelConfig?.humedadRelativaUm || "",
            precipitacionesCell: currentExcelConfig?.precipitacionesCell || "",
            precipitacionesUm: currentExcelConfig?.precipitacionesUm || "",
            presionBarometricaCell: currentExcelConfig?.presionBarometricaCell || "",
            presionBarometricaUm: currentExcelConfig?.presionBarometricaUm || "",
            radiacionSolarCell: currentExcelConfig?.radiacionSolarCell || "",
            radiacionSolarUm: currentExcelConfig?.radiacionSolarUm || "",
            temperaturaAmbientalCell: currentExcelConfig?.temperaturaAmbientalCell || "",
            temperaturaAmbientalUm: currentExcelConfig?.temperaturaAmbientalUm || "",
            velocidadVientoCell: currentExcelConfig?.velocidadVientoCell || "",
            velocidadVientoUm: currentExcelConfig?.velocidadVientoUm || "",
            fechaMedicionCell: currentExcelConfig?.fechaMedicionCell || ""
        }
    )

    const resetValues = () => {
        setExelConfig({
            inicialDataRow: "",
            alturaNieveCell: "",
            alturaNieveUm: "",
            direccionVientoCell: "",
            direccionVientoUm: "",
            humedadRelativaCell: "",
            humedadRelativaUm: "",
            precipitacionesCell: "",
            precipitacionesUm: "",
            presionBarometricaCell: "",
            presionBarometricaUm: "",
            radiacionSolarCell: "",
            radiacionSolarUm: "",
            temperaturaAmbientalCell: "",
            temperaturaAmbientalUm: "",
            velocidadVientoCell: "",
            velocidadVientoUm: "",
            fechaMedicionCell: ""
        });
    };

    const handleChangeExcelConfig = (event) => {
        const { id, value } = event.target;
        if(id.toString() === "inicialDataRow" || id.toString() === "alturaNieveCell" || id.toString() === "direccionVientoCell"
            || id.toString() === "humedadRelativaCell" || id.toString() === "precipitacionesCell" || id.toString() === "presionBarometricaCell"
            || id.toString() === "radiacionSolarCell" || id.toString() === "temperaturaAmbientalCell" || id.toString() === "velocidadVientoCell"
            || id.toString() === "fechaMedicionCell")
        {
            setExelConfig({
                ...excelConfig,
                [id]: parseInt(value),
            });
        } else {
            setExelConfig({
                ...excelConfig,
                [id]: value,
            });
        }

    };

    const close = () => {
        resetValues();
        handleClose();
    };

    const crearConfiguration = () => {
        handleSubmit({
            estacionId: excelConfig.estacionId,
            inicialDataRow: excelConfig.inicialDataRow,
            alturaNieveCell: excelConfig.alturaNieveCell,
            alturaNieveUm: excelConfig.alturaNieveUm,
            direccionVientoCell: excelConfig.direccionVientoCell,
            direccionVientoUm: excelConfig.direccionVientoUm,
            humedadRelativaCell: excelConfig.humedadRelativaCell,
            humedadRelativaUm: excelConfig.humedadRelativaUm,
            precipitacionesCell: excelConfig.precipitacionesCell,
            precipitacionesUm: excelConfig.precipitacionesUm,
            presionBarometricaCell: excelConfig.presionBarometricaCell,
            presionBarometricaUm: excelConfig.presionBarometricaUm,
            radiacionSolarCell: excelConfig.radiacionSolarCell,
            radiacionSolarUm: excelConfig.radiacionSolarUm,
            temperaturaAmbientalCell: excelConfig.temperaturaAmbientalCell,
            temperaturaAmbientalUm: excelConfig.temperaturaAmbientalUm,
            velocidadVientoCell: excelConfig.velocidadVientoCell,
            velocidadVientoUm: excelConfig.velocidadVientoUm,
            fechaMedicionCell: excelConfig.fechaMedicionCell
        });
        close();
    };

    return (
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle>
                    {`${currentExcelConfig ? "Editar" : "Adicionar"} Configuración Excel`}
                </DialogTitle>
                <DialogContent style={{ paddingTop: '1rem' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="inicialDataRow"
                                        label="Fila Inicial"
                                        fullWidth
                                        value={excelConfig.inicialDataRow}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="alturaNieveCell"
                                        label="Celda Altura de la Nieve"
                                        fullWidth
                                        value={excelConfig.alturaNieveCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="direccionVientoCell"
                                        label="Celda Dirección del Viento"
                                        fullWidth
                                        value={excelConfig.direccionVientoCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="humedadRelativaCell"
                                        label="Celda Humedad Relativa"
                                        fullWidth
                                        value={excelConfig.humedadRelativaCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="precipitacionesCell"
                                        label="Celda Precipitaciones"
                                        fullWidth
                                        value={excelConfig.precipitacionesCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="presionBarometricaCell"
                                        label="Celda Presión Barométrica"
                                        fullWidth
                                        value={excelConfig.presionBarometricaCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="radiacionSolarCell"
                                        label="Celda Radiación Solar"
                                        fullWidth
                                        value={excelConfig.radiacionSolarCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="temperaturaAmbientalCell"
                                        label="Celda Temperatura Ambiental"
                                        fullWidth
                                        value={excelConfig.temperaturaAmbientalCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="velocidadVientoCell"
                                        label="Celda Velocidad del Viento"
                                        fullWidth
                                        value={excelConfig.velocidadVientoCell}
                                        onChange={handleChangeExcelConfig}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="fechaMedicionCell"
                                        label="Celda Fecha de Medición"
                                        fullWidth
                                        value={excelConfig.fechaMedicionCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="alturaNieveUm"
                                        label="Altura de la Nieve [UM]"
                                        fullWidth
                                        value={excelConfig.alturaNieveUm}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="direccionVientoUm"
                                        label="Dirección del Viento [UM]"
                                        fullWidth
                                        value={excelConfig.direccionVientoUm}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="humedadRelativaUm"
                                        label="Humedad Relativa [UM]"
                                        fullWidth
                                        value={excelConfig.humedadRelativaUm}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="precipitacionesUm"
                                        label="Precipitaciones [UM]"
                                        fullWidth
                                        value={excelConfig.presionBarometricaCell}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="presionBarometricaUm"
                                        label="Presión Barométrica [UM]"
                                        fullWidth
                                        value={excelConfig.presionBarometricaUm}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="radiacionSolarUm"
                                        label="Radiación Solar [UM]"
                                        fullWidth
                                        value={excelConfig.radiacionSolarUm}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="temperaturaAmbientalUm"
                                        label="Temperatura Ambiental [UM]"
                                        fullWidth
                                        value={excelConfig.temperaturaAmbientalUm}
                                        onChange={handleChangeExcelConfig}
                                    />

                                    <TextField
                                        margin="dense"
                                        id="velocidadVientoUm"
                                        label="Velocidad del Viento [UM]"
                                        fullWidth
                                        value={excelConfig.velocidadVientoUm}
                                        onChange={handleChangeExcelConfig}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        Cancelar
                    </Button>
                    <Button onClick={crearConfiguration}>
                        {`${currentExcelConfig ? "Aceptar" : "Adicionar"}`}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CreateExcelConfigDialog;