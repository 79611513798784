import axios from "../config/axiosInstance";
import { sismosBaseUrl } from "../config/config";
import errorHandle from "./tratarError";

export const sismosService = {
  getSismos: () => {
    return axios("sismos", {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getSismosRangoFecha: ({ desde, hasta }) => {
    return axios(`sismos/desde/${desde}/hasta/${hasta}`, {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  publicarSismo: (data) => {
    const { lugar, fecha, ubicacion, magnitud, profundidad } = data;
    return axios("sismos", {
      baseURL: sismosBaseUrl,
      method: "post",
      data: {
        lugar,
        fecha,
        ubicacion,
        magnitud,
        profundidad,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  publicarSismoFecha: (fecha) => {
    return axios(`sismos/${fecha}/publicar`, {
      baseURL: sismosBaseUrl,
      method: "post",
      data: {
        date: fecha,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  //Umbrales de magnitud
  getUmbralMagnitud: ({ sismoId }) => {
    return axios(`umbrales-magnitud/${sismoId}`, {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getUmbralesMagnitud: () => {
    return axios("umbrales-magnitud", {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getUltimoUmbralMagnitud: () => {
    return axios("umbrales-magnitud/ultimo", {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarUmbralMagnitud: ({ id, magnitud }) => {
    return axios(`umbrales-magnitud/${id}`, {
      baseURL: sismosBaseUrl,
      method: "put",
      data: {
        id,
        magnitud,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearUmbralMagnitud: ({ magnitud }) => {
    return axios("umbrales-magnitud", {
      baseURL: sismosBaseUrl,
      method: "post",
      data: {
        magnitud,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  //Umbrales de distancia
  getUmbralDistancia: ({ sismoId }) => {
    return axios(`umbrales-distancia/${sismoId}`, {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getUmbralesDistancia: () => {
    return axios("umbrales-distancia", {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getUltimoUmbralDistancia: () => {
    return axios("umbrales-distancia/ultimo", {
      baseURL: sismosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarUmbralDistancia: ({ id, distancia, origen }) => {
    return axios(`umbrales-distancia/${id}`, {
      baseURL: sismosBaseUrl,
      method: "put",
      data: {
        distancia,
        origen,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearUmbralDistancia: ({ distancia, origen, creado }) => {
    return axios("umbrales-distancia", {
      baseURL: sismosBaseUrl,
      method: "post",
      data: { distancia, origen, creado },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getReceptoresEmail: () => {
    return axios("receptores-email", {
      baseURL: sismosBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getReceptoresEmailById: ({id}) => {
    return axios(`receptores-email/${id}`, {
      baseURL: sismosBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearReceptoresEmail: ({ email }) => {
    return axios("receptores-email", {
      baseURL: sismosBaseUrl,
      method: "post",
      data: { email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateReceptoresEmail: ({ id, email }) => {
    return axios(`receptores-email/${id}`, {
      baseURL: sismosBaseUrl,
      method: "put",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  deleteReceptoresEmail: ({ id, email }) => {
    return axios(`receptores-email/${id}`, {
      baseURL: sismosBaseUrl,
      method: "delete",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
