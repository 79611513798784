import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import fileDownload from 'js-file-download'
import moment from 'moment';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import './PuntosDescarga.css';
import RevanchaBlack from '../../../../assets/slopeBlack.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import EditarGrupoDialog from '../../../Dialogs/PuntosDescarga/EditarGrupoDialog';
import GrupoMenuBtn from '../../../Dialogs/PuntosDescarga/GrupoMenuBtn';
import { puntosDescargaService } from '../../../../services/PuntosDescargaService';
import ElementoMenuBtn from '../../../Dialogs/PuntosDescarga/ElementoMenuBtn';
import AddElementoDialog from '../../../Dialogs/PuntosDescarga/AddElementoDialog';
import PublicarDialog from '../../../Dialogs/PuntosDescarga/PublicarDialog';
import ImportarDialog from '../../../Dialogs/PuntosDescarga/ImportarDialog';
import {toast} from "react-toastify";
import CreateExcelConfigDialog from "../../../Dialogs/PuntosDescarga/CreateExcelConfigDialog";
import EditExcelConfigDialog from "../../../Dialogs/PuntosDescarga/EditExcelConfigDialog";
import PublicarPuntosDescargaDialog from "../../../Dialogs/PuntosDescarga/PublicarPuntosDescargaDialog";
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";
import {piezometersService} from "../../../../services/PiezometersService";
import DeleteCapaDialog from "../../../Dialogs/DeleteCapaDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PuntosDescarga = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [grupo, setGrupo] = useState();
  const [currentElemento, setCurrentElemento] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [openEditarDialog, setOpenEditarDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openImportarDialog, setOpenImportarDialog] = useState(false);
  const [openCrearExcelConfig, setOpenCrearExcelConfig] = useState(false);
  const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
  const [openPublicar, setOpenPublicar] = useState(false);
  const [openEditExcelConfig, setOpenEditExcelConfig] = useState(false);
  const [excelConfig, setExcelConfig] = useState(null);
  const [etiquetaActual, setEtiquetaActual] = useState('')
  const [publicado, setPublicado] = useState(false)
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();
  const [openDejarDePublicar, setOpenDejarDePublicar] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };


  useEffect(() => {
    let mounted = true;
    mounted && cargar();
    return () => mounted = false;
  }, [id]);

  const cargar = () => {
    setCargando(true);
    puntosDescargaService.getGrupo(id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setGrupo(res.data.grupo);
          setPublicado(res.data.grupo.published);
        } else {
          console.error(res);
        }
        setCargando(false);
      });
      puntosDescargaService.getExcelConfiguration().then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setExcelConfig(res.data.excelConfig);
        } else {
          toast.error("Fallo al cargar los de la confguración de Excel" + res.message, {
            autoClose: 6000,
          });
        }
      });
  }

  const handleClickEditar = (punto) => {
    setCurrentElemento(punto);
    setOpenEditarDialog(true);
  };

  const handleCloseEditarDialog = () => {
    setOpenEditarDialog(false);
  };

  const handleClickDeleteItem = (punto) => {
    setCurrentElemento(punto);
    setOpenDeleteItemDialog(true);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEditarGrupo = (nombre) => {
    handleCloseEditarDialog(false)
    puntosDescargaService.actualizarGrupo({ grupoId: grupo.id, nombre })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargar();
        } else {
          console.log(res)
        }
      })
  }

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentElemento) {
      puntosDescargaService.eliminarElemento({
        puntoId: currentElemento.id,
        grupoId: grupo.id
      })
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setCurrentElemento(null)
            cargar();
          } else {
            console.log(res)
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      puntosDescargaService.eliminarGrupo(grupo.id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
            navigate('/');
          } else { console.log(res) }
        })
        .catch(err => {
          console.error(err)
        })
    }
  };

  //menu-option-row
  const handleClickAdd = (punto) => {
    if (punto) {
      setEtiquetaActual(punto.etiqueta)
      setCurrentElemento(punto)
    }
    else {
      setEtiquetaActual('')
      setCurrentElemento(null)
    }


    setOpenAddDialog(true);
  }

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setCurrentElemento(null);
  };
  const handleClickImportar = () => {
    setOpenImportarDialog(true);
  }

  const handleCloseImportarDialog = () => {
    setOpenImportarDialog(false);
  };

  const handleImportar = ({fecha, ficheros}) => {
    handleCloseImportarDialog(false)
    setImportandoDatos({ importando: true, status: "Importando" })
    //console.log('FECHERO', ficheros)
    const formData = new FormData();
    formData.append("UploadFile", ficheros);
    formData.append("FechaCreado", new Date(fecha).toISOString());
    notify("Importando Datos...")
    console.log('FORM', formData.get("UploadFile"))

    puntosDescargaService.subirPlantilla({
      grupoPuntoDescargaId: grupo.id,
      formData
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        setCurrentElemento(null);
        cargar();
        update(
            "Los datos se importaron corretamente 👌",
            toast.TYPE.SUCCESS
        );
        setImportandoDatos({ importando: false, status: "Hecho" })
      } else {
        console.log(res)
        update(
            `Ocurrio un error al importar los datos 🤯 ${res.message}`,
            toast.TYPE.ERROR
        );
        setImportandoDatos({ importando: false, status: "Fallido" })
      }
    });
  }

  const handleAdicionar = ({ etiqueta }) => {
    handleCloseAddDialog(false)
    setImportandoDatos({ importando: true, status: "Importando" })
    if (currentElemento) {
      console.log(currentElemento)
      puntosDescargaService.editarElemento({
        grupoId: grupo.id,
        puntoId: currentElemento.id,
        etiqueta
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          setCurrentElemento(null)
          cargar();
          setImportandoDatos({ importando: false, status: "Hecho" })
        } else {
          console.log(res)
          setImportandoDatos({ importando: false, status: "Fallido" })
        }
      });
    } else {
      puntosDescargaService.crearElemento({
        grupoId: grupo.id,
        etiqueta
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargar();
          setImportandoDatos({ importando: false, status: "Hecho" })
        } else {
          console.log(res)
          setImportandoDatos({ importando: false, status: "Fallido" })
        }
      });
    }
  }

  const handleClickDejardePublicar = () => {
    setOpenDejarDePublicar(true);
  };

  const handleCloseDejardePublicar = () => {
    setOpenDejarDePublicar(false);
  };

  const handleDejarPublicar = () => {
    handleCloseDejardePublicar();
    puntosDescargaService.eliminarCapaByGrupoId(grupo.id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            cargar();
          } else { console.log(res) }
        })
        .catch(err => {
          console.error(err)
        })
  }

  const handleClosePublicar = () => {
    setOpenPublicar(false)
  }

  const handleClickPublicar = (item) => {
    setCurrentElemento(item)
    setOpenPublicar(true)
  }

  const handlePublicar = ({ nombre, fecha }) => {
    handleClosePublicar();
    notify("Publicando nueva Capa de Piezómetro...")
    puntosDescargaService
        .publicarCapa({
          grupoId: parseInt(id),
          nombre,
          itemId: "",
          creado: fecha,
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            cargar()
            update(
                "La capa fue publicada correctamente 👌",
                toast.TYPE.SUCCESS
            );
          } else {
            update(
                "Ocurrio un error al publicar la capa. 🤯 ${res.message}",
                toast.TYPE.ERROR
            );
            console.log(res);
          }
        });
  };

  // const handlePublicar = ({ nombreCapa}) => {
  //   handleClosePublicar()
  //   puntosDescargaService.publicarPunto({ grupoPuntoDescargaId: grupo.id, nombreCapa })
  //     .then(res => {
  //       if (res.status >= 200 && res.status < 300) {
  //         setGrupo({ ...grupo, publicado: true })
  //         setCurrentElemento()
  //       } else {
  //         console.log(res)
  //       }
  //     })
  // }

  const handleCreateExcelConfig = () => {
    setOpenCrearExcelConfig(true);
  };

  const handleCloseCrearExcelConfig = () => {
    setOpenCrearExcelConfig(false);
  };

  const handleEditExcelConfig = () => {
    setOpenEditExcelConfig(true);
  };

  const handleCloseEditExcelConfig = () => {
    setOpenEditExcelConfig(false);
  };

  const handleConfiguracionExcel = ({initialRow, etiquetaCell, ubicacionNorteCell,
                                           ubicacionEsteCell, cotaInferiorCell,
                                           cotaSuperiorCell}) => {
    if(excelConfig){
      handleCloseEditExcelConfig()
      notify("Se está editando la configuración del archivo de datos");
      puntosDescargaService
          .editarConfiguracionDeExcel({
            initialRow,
            etiquetaCell,
            ubicacionNorteCell,
            ubicacionEsteCell,
            cotaInferiorCell,
            cotaSuperiorCell
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              update(
                  "La configuración para la carga de los excel se edito correctamente 👌",
                  toast.TYPE.SUCCESS
              );
            } else {
              update(
                  `Ha ocurrido un error editando la configuración 🤯 ${res.message}`,
                  toast.TYPE.ERROR
              );
            }
          });
    } else {
      handleCloseCrearExcelConfig()
      notify("Se está creando la configuración del archivo de datos");
      puntosDescargaService
          .crearConfiguracionDeExcel({
            initialRow,
            etiquetaCell,
            ubicacionNorteCell,
            ubicacionEsteCell,
            cotaInferiorCell,
            cotaSuperiorCell
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              update(
                  "La configuración para la carga de los excel se creo correctamente 👌",
                  toast.TYPE.SUCCESS
              );
            } else {
              update(
                  `Ha ocurrido un error creando la configuración 🤯 ${res.message}`,
                  toast.TYPE.ERROR
              );
            }
          });
    }
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupo.puntos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTabla = () => {
    if (rowsPerPage > 0) {
      return <TableContainer component={Paper}>
        <Table sx={{minWidth: 700}} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Etiqueta</StyledTableCell>
              <StyledTableCell align="center">Última Cota Inferior</StyledTableCell>
              <StyledTableCell align="center">Última Cota Superior</StyledTableCell>
              <StyledTableCell align="center">Última Diferencia Cotas</StyledTableCell>
              <StyledTableCell align="center">Fecha Última Medición</StyledTableCell>
              <StyledTableCell align="center">Cantidad Datos</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? grupo.puntos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : grupo.puntos
            ).map((punto, index) => (
                <StyledTableRow key={punto.id}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">{punto.etiqueta ? punto.etiqueta : "-"}</StyledTableCell>
                  <StyledTableCell
                      align="center">{punto.ultimaCotaSuperior ? punto.ultimaCotaSuperior : '-'}</StyledTableCell>
                  <StyledTableCell
                      align="center">{punto.ultimaCotaInferior ? punto.ultimaCotaInferior : '-'}</StyledTableCell>
                  <StyledTableCell
                      align="center">{punto.ultimaDiferenciaCotas ? punto.ultimaDiferenciaCotas : '-'}</StyledTableCell>
                  <StyledTableCell
                      align="center">{punto.fechaUltimaMedicion ? moment(punto.fechaUltimaMedicion).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                  <StyledTableCell align="center">{punto.cantidadDatos ? punto.cantidadDatos : '-'}</StyledTableCell>
                  <StyledTableCell align="right">
                    <ElementoMenuBtn
                        editarAction={() => handleClickAdd(punto)}
                        disabled={importandoDatos.importando}
                        eliminarAction={() => handleClickDeleteItem(punto)}/>
                  </StyledTableCell>
                </StyledTableRow>))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, {label: 'Todos', value: grupo.puntos.length}]}
                  colSpan={12}
                  count={grupo.puntos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>;
    } else {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No existen puntos de descargas</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    }
  }

  return (
    <div className="area-container">
      {cargando ?
        <div className='loading'><CircularProgress /></div> :
          <>
            <div className="head-area">
              <div className="head-name">
                <img src={RevanchaBlack} alt="" width={18} height={18}/>
                <h2 className="name-area">{grupo ? grupo.nombre : 'Grupo puntos de descargas inexistente'}</h2>
              </div>
              {grupo
                  ?
                  <div className="actionsButtons">
                    <Button
                        onClick={cargar}
                        sx={{mr: "0px", color: "white", display: "block"}}
                    >
                      <img src={Reload} alt=""/>
                    </Button>
                    <GrupoMenuBtn
                        importarAction={handleClickImportar}
                        editarAction={handleClickEditar}
                        crearConfigExcel={handleCreateExcelConfig}
                        editarConfigExcel={handleEditExcelConfig}
                        eliminarAction={() => handleClickDeleteItem(null)}
                        dejarPublicarAction={publicado && (() => handleClickDejardePublicar())}
                        publicarAction={!publicado && (() => handleClickPublicar())}
                        excelConfig={excelConfig}
                    />
                  </div>
                  : null}
            </div>
            <div>
            <span>{publicado ?
                <><PublicadoIcon/> <span>Publicado</span></>
                :
                <><PublicadoIcon color="disabled"/> <span>No Publicado</span></>
            }</span>
            </div>
            {grupo ?
                <>
                  <Button
                      disabled={importandoDatos.importando}
                      variant="contained"
                      className="btn-add"
                      onClick={() => handleClickAdd(null)}
                  >{importandoDatos.importando && (
                      <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                      />
                  )}Adicionar
                  </Button>
                  <div className="container-table">
                    {renderTabla()}
                  </div>
                </> : null}
          </>
      }

      <DeleteItemDialog
          open={openDeleteItemDialog}
          handleClose={handleCloseDeleteItemDialog}
          handleDeleteItem={handleDeleteItem}/>
      {openEditarDialog
          ? <EditarGrupoDialog
              open={openEditarDialog}
          handleClose={handleCloseEditarDialog}
          handleEditarGrupo={handleEditarGrupo}
          nombre={grupo.nombre}
        /> : null}
      {openAddDialog ?
        <AddElementoDialog
          open={openAddDialog}
          handleClose={handleCloseAddDialog}
          handleSubmit={handleAdicionar}
          nombre={etiquetaActual}
        /> : null}
      {openDejarDePublicar ?
          <DeleteCapaDialog
              open={openDejarDePublicar}
              handleClose={handleCloseDejardePublicar}
              handleDeleteItem={handleDejarPublicar}/> : null
      }
      {openImportarDialog ?
        <ImportarDialog
          open={openImportarDialog}
          handleClose={handleCloseImportarDialog}
          handleSubmit={handleImportar}
        /> : null}
      {openPublicar &&
          <PublicarDialog
          open={openPublicar}
          handleClose={handleClosePublicar}
          handleSubmit={handlePublicar}
      />}
      {openCrearExcelConfig && (
          <CreateExcelConfigDialog
              open={openCrearExcelConfig}
              handleClose={handleCloseCrearExcelConfig}
              handleSubmit={handleConfiguracionExcel}
          />
      )}
      {openEditExcelConfig && (
          <EditExcelConfigDialog
              open={openEditExcelConfig}
              handleClose={handleCloseEditExcelConfig}
              handleSubmit={handleConfiguracionExcel}
              excelConfig={excelConfig}
          />
      )}
    </div>
  );
}
export default PuntosDescarga;
