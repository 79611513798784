import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import HydrogeologyBlack from "../../../../assets/hydrogeologyBlack.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";

import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import "./EstacionesMeteorologicas.css";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { truncThree } from "../../../../utils/utils";
import moment from "moment";
import GrupoMenuBtn from "../../../Dialogs/EstacionesMeteorologicas/GrupoMenuBtn";
import PublicarDialog from "../../../Dialogs/EstacionesMeteorologicas/PublicarCapaDialog";
import { toast } from "react-toastify";
import EditGrupoEstacionesDialog from "../../../Dialogs/EstacionesMeteorologicas/EditGrupoEstacionesDialog";
import AddEstacionMeteorologica from "../../../Dialogs/EstacionesMeteorologicas/AddEstacionMeteorologica";
import ImportDataEstacionesDialog from "../../../Dialogs/EstacionesMeteorologicas/ImportDataEstacionesDialog";
import {estacionesMetService} from "../../../../services/EstacionesMeteorologicasService";
import CreateExcelConfigDialog from "../../../Dialogs/EstacionesMeteorologicas/CreateExcelConfigDialog";
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EstacionesMeteorologicas = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [estacionMetGroup, setEstacionMetGroup] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [loadingExcelConfig, setLoadingExcelConfig] = useState(false);
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  const [rowEl, setRowEl] = useState(null);
  const [openRowEl, setOpenRowEl] = useState(null);

  const [openAddEstacionMetDialog, setOpenAddEstacionMetDialog] = useState(false);
  const [openEditEstacionMetDialog, setOpenEditEstacionMetDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openImportarDatosDialog, setOpenImportarDatosDialog] = useState(false);

  const [currentEstacionMet, setCurrentEstacionMet] = useState(null);
  const [openPublicar, setOpenPublicar] = useState(false);

  const [openExcelConfigDialog, setOpenExcelConfigDialog] = useState(false);
  const [currentExcelConfig, setCurrentExcelConfig] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    let mounted = true;
    mounted && loadEstacionMetGroup();
    return () => (mounted = false);
  }, [id]);

  const loadEstacionMetGroup = () => {
    setLoadingGroup(true);
    estacionesMetService.getGroup(id).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setEstacionMetGroup(res.data.grupo);
      } else {
        console.error(res);
      }
      setLoadingGroup(false);
    });
  };

  const handleClickDeleteItem = (row) => {
    setCurrentEstacionMet(row);
    setOpenDeleteItemDialog(true);
  };

  const handleClickEditEstacionMetDialog = () => {
    setOpenEditEstacionMetDialog(true);
  };

  const handleCloseEditEstacionMetDialog = () => {
    setOpenEditEstacionMetDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };
  const handleCloseImportarDatosDialog = () => {
    setOpenImportarDatosDialog(false);
  };

  const handleClickImportarDatos = (estacion) => {
    setCurrentEstacionMet(estacion);
    setOpenImportarDatosDialog(true);
  };

  const handleImportarDatos = async (ficheros) => {
    setOpenImportarDatosDialog(false);
    const formData = new FormData();
    formData.append("File", ficheros);
    const response = await toast.promise(
      estacionesMetService.importarDatos({
        estacionId: currentEstacionMet.id,
        formData: formData,
      }),
      {
        pending: "Importando...",
        success: "Los datos se importaron 👌",
        error: "Ha ocurrido un error 🤯",
      }
    );
  };

  const handleEditEstMetGroup = (estacionGroupEdited) => {
    handleCloseEditEstacionMetDialog();
    setLoadingGroup(true);

    const {
      id,
      nombre,
      areaId,
      publicado
    } = estacionGroupEdited;

    estacionesMetService
      .updateGroup({
        grupoId: id,
        nombre,
        areaId
      })
      .then((res) => {
        loadEstacionMetGroup();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentEstacionMet) {
      estacionesMetService
        .deleteEstacion({
          estacionId: currentEstacionMet.id
        })
        .then((res) => {
          loadEstacionMetGroup();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      estacionesMetService
        .deleteGroup(id)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
      setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
      navigate("/");
    }
  };

  //menu-option-row
  const handleClickRowOption = (event) => {
    setRowEl(event.currentTarget);
    setOpenRowEl(event.currentTarget.getAttribute("aria-controls"));
  };
  const handleCloseRowOption = () => {
    setRowEl(null);
    setOpenRowEl(null);
  };
  const handleClickAddEstacionDialog = (estacion) => {
    setCurrentEstacionMet(estacion)
    handleCloseRowOption();
    setOpenAddEstacionMetDialog(true);
  };
  const handleCloseAddEstacionDialog = () => {
    setOpenAddEstacionMetDialog(false);
  };

  const handleAddEstacionMet = (estacion) => {
    handleCloseAddEstacionDialog();
    const {
      identifier,
      location_East,
      location_North,
    } = estacion;

    estacionesMetService
      .crearEstacionesMet({
        grupoId: parseInt(id),
        etiqueta: identifier,
        ubicacion: {
          x: location_East,
          y: location_North,
        }
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadEstacionMetGroup();
          setCurrentEstacionMet(null);
        } else {
          console.error(res);
        }
      });
  };

  const handleEditEstacionMet = (estacionEdited) => {
    handleCloseAddEstacionDialog();
    const {
      identifier,
      location_East,
      location_North,
    } = estacionEdited;

    estacionesMetService
      .editarEstacionesMet({
        grupoId: parseInt(id),
        estacionId :parseInt(currentEstacionMet.id),
        etiqueta: identifier,
        ubicacion: {
          x: location_East,
          y: location_North,
        },
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadEstacionMetGroup();
          setCurrentEstacionMet(null);
        } else {
          console.error(res);
        }
      });
  };

  const getResultadoRow = (numero) => {
    const resultado = truncThree(numero);
    if (resultado?.includes("99999")) return "-";
    if (resultado?.includes("NaN")) return "NaN";
    return resultado;
  };

  const handleClickPublicarCapa = () => {
    setOpenPublicar(true);
  };

  const handlePublicar = () => {
    handleClosePublicar();
    estacionesMetService
      .publicarGroup({ grupoId: id })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          toast.success("La capa se publicó correctamente");
        } else {
          toast.error("Ha ocurrido un error al publicar");
          console.log(res);
        }
      });
  };
  const handleClosePublicar = () => {
    setOpenPublicar(false);
  };

  const handleOpenExcelConfigDialog = (estacion) => {
    handleCloseRowOption();
    if(currentExcelConfig === null) {
      setLoadingExcelConfig(true);
      setCurrentEstacionMet(estacion);
      console.log("LOADING", loadingExcelConfig);
      estacionesMetService
          .getConfiguracionExcelByEstacion({estacionId: estacion.id})
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setCurrentExcelConfig(res.data.config);
              setOpenExcelConfigDialog(true);
              setLoadingExcelConfig(false);
            } else {
              console.error(res);
            }});
    } else {
      setOpenExcelConfigDialog(true);
    }
  }

  const handleCloseExcelConfigDialog = (estacion) => {
    setOpenExcelConfigDialog(false);
  }

  const handleConfiguracionExcel = (configuracionActual) => {
    console.log("CONFIG", configuracionActual)
    const {
        estacionId,
        inicialDataRow,
        alturaNieveCell,
        alturaNieveUm,
        direccionVientoCell,
        direccionVientoUm,
        humedadRelativaCell,
        humedadRelativaUm,
        precipitacionesCell,
        precipitacionesUm,
        presionBarometricaCell,
        presionBarometricaUm,
        radiacionSolarCell,
        radiacionSolarUm,
        temperaturaAmbientalCell,
        temperaturaAmbientalUm,
        velocidadVientoCell,
        velocidadVientoUm,
        fechaMedicionCell
    } = configuracionActual;

    if(currentExcelConfig && currentExcelConfig.estacionId == currentEstacionMet.id) {
      toast.info("Actualizando configuracion del Excel");
      estacionesMetService.editarExcelConfig({
        estacionId,
        inicialDataRow,
        alturaNieveCell,
        alturaNieveUm,
        direccionVientoCell,
        direccionVientoUm,
        humedadRelativaCell,
        humedadRelativaUm,
        precipitacionesCell,
        precipitacionesUm,
        presionBarometricaCell,
        presionBarometricaUm,
        radiacionSolarCell,
        radiacionSolarUm,
        temperaturaAmbientalCell,
        temperaturaAmbientalUm,
        velocidadVientoCell,
        velocidadVientoUm,
        fechaMedicionCell
      }).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          toast.success("La cconfiguración fue actualizada correctamente 👌");
          setCurrentExcelConfig(configuracionActual);
        } else {
          toast.error("Ha ocurrido al actualizar la configuración 🤯");
          console.log(res);
        }
      });
    } else {
      toast.info("Creando configuracion del Excel");
      estacionesMetService.crearExcelConfig({
        estacionId: currentEstacionMet.id,
        inicialDataRow,
        alturaNieveCell,
        alturaNieveUm,
        direccionVientoCell,
        direccionVientoUm,
        humedadRelativaCell,
        humedadRelativaUm,
        precipitacionesCell,
        precipitacionesUm,
        presionBarometricaCell,
        presionBarometricaUm,
        radiacionSolarCell,
        radiacionSolarUm,
        temperaturaAmbientalCell,
        temperaturaAmbientalUm,
        velocidadVientoCell,
        velocidadVientoUm,
        fechaMedicionCell
      }).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          toast.success("La cconfiguración fue creada correctamente 👌");
          setCurrentExcelConfig(configuracionActual);
        } else {
          toast.error("Ha ocurrido al crear la configuración 🤯");
          console.log(res);
        }
      });
    }
  }

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - estacionMetGroup.estacionesMeteorologicas.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="area-container">
      {loadingGroup ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <>
          {(loadingExcelConfig) &&
              <div className="loadingExtra">
                <CircularProgress /> Verificando Configuración Existente...
              </div>
          }

          <div className="head-area">
            <div className="head-name">
              <img src={HydrogeologyBlack} alt="" width={18} height={18} />
              <h2 className="name-area">
                {estacionMetGroup
                  ? estacionMetGroup.nombre
                  : "Grupo de Estaciones Meteorológicas inexistente"}
              </h2>
            </div>
            {estacionMetGroup && (
                <div className="actionsButtons">
                  <Button
                      onClick={loadEstacionMetGroup}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoMenuBtn
                    editarAction={handleClickEditEstacionMetDialog}
                    eliminarAction={handleClickDeleteItem}
                    publicarAction={handleClickPublicarCapa}
                  />
                </div>
            )}
          </div>
          {estacionMetGroup ? (
            <>
              <Button
                variant="contained"
                className="btn-add-piezometro"
                onClick={() => handleClickAddEstacionDialog(null)}
              >
                Adicionar
              </Button>
              <div className="container-table">
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 700 }}
                    size="small"
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Identificador
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Última Medición
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Altura Nieve
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Dirección Viento
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Humedad Relativa
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Precipitaciones
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Presión Barométrica
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Radiación Solar
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Temperatura Amb
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Velocidad Viento
                        </StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                              ? estacionMetGroup.estacionesMeteorologicas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              : estacionMetGroup.estacionesMeteorologicas
                      ).map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {row.etiqueta || "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.fechaMedicion
                                ? moment(row.ultimaMedicion.fechaMedicion).utc().format(
                                    "DD-MM-YYYY hh:mm:ss"
                                )
                                : "-"}
                            {row.fechaPrimeraMedicion || "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.alturaNieve
                                ? getResultadoRow(row.ultimaMedicion.alturaNieve) + row.ultimaMedicion.alturaNieveUm
                                : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.direccionViento
                              ? getResultadoRow(row.ultimaMedicion.direccionViento) + row.ultimaMedicion.direccionVientoUm
                              : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.humedadRelativa
                                ? getResultadoRow(row.ultimaMedicion.humedadRelativa) + row.ultimaMedicion.humedadRelativaUm
                                : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.precipitaciones
                                ? getResultadoRow(row.ultimaMedicion.precipitaciones) + row.ultimaMedicion.precipitacionesUm
                                : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.presionBarometrica
                                ? getResultadoRow(row.ultimaMedicion.presionBarometrica) + row.ultimaMedicion.presionBarometricaUm
                                : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.radiacionSolar
                                ? getResultadoRow(row.ultimaMedicion.radiacionSolar) + row.ultimaMedicion.radiacionSolarUm
                                : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.temperaturaAmbiental
                                ? getResultadoRow(row.ultimaMedicion.temperaturaAmbiental) + row.ultimaMedicion.temperaturaAmbientalUm
                                : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ultimaMedicion?.velocidadViento
                                ? getResultadoRow(row.ultimaMedicion.velocidadViento) + row.ultimaMedicion.velocidadVientoUm
                                : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={row.id}
                              aria-haspopup="true"
                              onClick={handleClickRowOption}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={rowEl}
                              open={openRowEl === row.id.toString()}
                              onClose={handleCloseRowOption}
                              keepMounted
                            >
                              <MenuItem onClick={handleCloseRowOption}> Descargar datos de medición </MenuItem>
                              <MenuItem onClick={() => handleClickImportarDatos(row) } > Importar Datos </MenuItem>
                              <MenuItem onClick={() => handleOpenExcelConfigDialog(row) } > Configurar Estructura del Excel </MenuItem>
                              <MenuItem onClick={() => handleClickAddEstacionDialog(row) } > Editar </MenuItem>
                              <MenuItem onClick={() => handleClickDeleteItem(row) } > Eliminar </MenuItem>
                            </Menu>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: estacionMetGroup.estacionesMeteorologicas.length }]}
                            colSpan={12}
                            count={estacionMetGroup.estacionesMeteorologicas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'rows per page',
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </>
          ) : null}
        </>
      )}

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem}
      />
      {openEditEstacionMetDialog ? (
        <EditGrupoEstacionesDialog
          open={openEditEstacionMetDialog}
          handleClose={handleCloseEditEstacionMetDialog}
          handleEditEstMet={handleEditEstMetGroup}
          estacionMetGroup={estacionMetGroup}
        />
      ) : null}
      {openAddEstacionMetDialog ? (
        <AddEstacionMeteorologica
          open={openAddEstacionMetDialog}
          handleClose={handleCloseAddEstacionDialog}
          handleSubmit={currentEstacionMet ? handleEditEstacionMet : handleAddEstacionMet}
          currentEstacionMet={currentEstacionMet}
        />
      ) : null}
      {openImportarDatosDialog ? (
        <ImportDataEstacionesDialog
          open={openImportarDatosDialog}
          handleClose={handleCloseImportarDatosDialog}
          handleSubmit={handleImportarDatos}
        />
      ) : null}
      {openPublicar && (
        <PublicarDialog
          open={openPublicar}
          handleClose={handleClosePublicar}
          handleSubmit={handlePublicar}
        />
      )}
      {openExcelConfigDialog && (
          <CreateExcelConfigDialog
              open={openExcelConfigDialog}
              handleClose={handleCloseExcelConfigDialog}
              handleSubmit={handleConfiguracionExcel}
              currentExcelConfig={currentExcelConfig}
          />
      )}
    </div>
  );
};
export default EstacionesMeteorologicas;
