// import axios from 'axios';
import axios from "../config/axiosInstance";
import { dtmBaseUrl } from "../config/config";
import errorHandle from "./tratarError";
import { toast } from "react-toastify";

export const dtmService = {
  crearGrupo: ({ nombre }) => {
    return axios("api/modelo-terreno-digital/grupo/", {
      baseURL: dtmBaseUrl,
      method: "post",
      data: { nombre, areaId: 1 },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupos: async () => {
    return axios("api/modelo-terreno-digital/grupos", {
      baseURL: dtmBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/api/modelo-terreno-digital/grupo/${id}`, {
      baseURL: dtmBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrupo: ({ id, nombre }) => {
    return axios(`/api/modelo-terreno-digital/grupo/${id}`, {
      baseURL: dtmBaseUrl,
      data: { id, nombre },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrupo: (id) => {
    return axios(`/api/modelo-terreno-digital/grupo/${id}`, {
      baseURL: dtmBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getDTMs: async () => {
    return axios("/api/modelo-terreno-digital/grupo", {
      baseURL: dtmBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getDTM: (id) => {
    return axios(`/api/modelo-terreno-digital/grupo/${id}`, {
      baseURL: dtmBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateDTM: (data) => {
    return axios(`/api/modelo-terreno-digital/grupo/${data.dtmId}`, {
      baseURL: dtmBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getDtmById: async (id) => {
    return axios(`/api/modelo-terreno-digital/modelo/${id}`, {
      baseURL: dtmBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarDTM: (data) => {
    return axios(
      `/api/modelo-terreno-digital/grupo/${data.grupoId}/modelo/${data.dtmId}`,
      {
        baseURL: dtmBaseUrl,
        method: "delete",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearDTM: (data) => {
    return axios("/api/modelo-terreno-digital/grupo", {
      baseURL: dtmBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  descargarDTM: ({ grupoId, modeloId }) => {
    return axios(
      `/api/modelo-terreno-digital/grupo/${grupoId}/descargar-modelo/${modeloId}`,
      {
        baseURL: dtmBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  /**Capas */
  publicarCapa: ({ modeloTerrenoDigitalId, nombre, url, creado }) => {
    return axios("api/modelo-terreno-digital/capa", {
      baseURL: dtmBaseUrl,
      method: "post",
      data: {
        modeloTerrenoDigitalId,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  uploadDTM: async ({ idGrupo, formData }) => {
    const toastId = toast.loading("Importando datos");
    return axios(
      `api/modelo-terreno-digital/grupo/${idGrupo}/upload-modelo-terreno-digital`,
      {
        baseURL: dtmBaseUrl,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "text/plain",
        },
      }
    )
      .then((result) => {
        toast.update(toastId, {
          render: "Los datos se importaron correctamente",
          type: "success",
          isLoading: false,
        });
        return result;
      })
      .catch((error) => {
        const err = errorHandle(error);
        toast.update(toastId, {
          render: err.message,
          type: "error",
          isLoading: false,
        });
        return err;
      });
  },
  getCapas: async () => {
    return axios("api/modelo-terreno-digital/capas", {
      baseURL: dtmBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/modelo-terreno-digital/capa/${id}`, {
      baseURL: dtmBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, fechaCreado, modeloTerrenoDigitalId}) => {
    return axios(`api/modelo-terreno-digital/capa/${id}`, {
      baseURL: dtmBaseUrl,
      method: "put",
      data: {
        id,
        nombre,
        url,
        modeloTerrenoDigitalId,
        fechaCreado
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/modelo-terreno-digital/capa/${id}`, {
      baseURL: dtmBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
};
