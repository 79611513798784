import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RevanchaBlack from "../../../../assets/slopeBlack.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { revanchaService } from "../../../../services/RevanchaService";
import EditarRevanchaDialog from "../../../Dialogs/Revancha/EditarRevanchaDialog";
import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import "./Revancha.css";
import Datos from "./Datos";
import Alarmas from "./Alarmas";
import { Button } from "@mui/material";
import AdicionarRevanchaDialog from "../../../Dialogs/Revancha/AdicionarRevanchaDialog";
import GrupoRevanchaMenuBtn from "../../../Dialogs/Revancha/GrupoRevanchaMenuBtn";
import CreateExcelConfigDialog from "../../../Dialogs/Revancha/CreateExcelConfigDialog";
import { toast } from "react-toastify";
import ImportarArchivoDialog from "../../../Dialogs/Revancha/ImportarArchivoDialog";
import EditExcelConfigDialog from "../../../Dialogs/Revancha/EditExcelConfigDialog";
import PublicarRevanchaDialog from "../../../Dialogs/Revancha/PublicarRevanchaDialog";
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import PublicadoIcon from "@mui/icons-material/FlagOutlined";
import PublicarDialog from "../../../Dialogs/Revancha/PublicarDialog";
import DeleteCapaDialog from "../../../Dialogs/DeleteCapaDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Revancha = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [revancha, setRevancha] = useState();
  const [loadingRevancha, setLoadingRevancha] = useState(false);
  const [openEditarRevanchaDialog, setOpenEditRevanchaDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openAdicionarRevanchaDialog, setOpenAdicionarRevanchaDialog] =
    useState(false);
  const [openImportarDialog, setOpenImportarDialog] = useState(false);
  const [openPublicarCapa, setOpenPublicarCapa] = useState(false);
  const [openCrearExcelConfig, setOpenCrearExcelConfig] = useState(false);
  const [openEditExcelConfig, setOpenEditExcelConfig] = useState(false);
  const [tiposRevanchas, setTiposRevanchas] = useState([]);
  const [excelConfig, setExcelConfig] = useState(null);
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();
  const [publicado, setPublicado] = useState(false);
  const [openDejarPublicarCapa, setOpenDejarPublicarCapa] =  useState(false)

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
    (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };

  useEffect(() => {
    let mounted = true;
    mounted && loadRevancha();
    return () => (mounted = false);
  }, [id]);

  const loadRevancha = () => {
    setLoadingRevancha(true);
    revanchaService.getTipos().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setTiposRevanchas(res.data.tipoRevanchas);
      } else {
        toast.error("Fallo al cargar los tipos de revanchas" + res.message, {
          autoClose: 6000,
        });
      }
    });
    revanchaService.getGrupo(id).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        let grupoR = res.data.grupo;
        if (grupoR?.revanchas?.length > 0) {
          loadRevanchaById(grupoR.revanchas[0]).then((resR) => {
            grupoR = {
              ...grupoR,
              revanchas: resR.data.revancha,
            };
            setRevancha(grupoR);
            setPublicado(resR.data.revancha.published);
          });
        } else {
          handleClickAdicionar();
          setRevancha({ ...grupoR, revanchas: null });
        }
      } else {
        toast.error("Fallo al cargar el Grupo Revanchas" + res.message, {
          autoClose: 6000,
        });
      }
      setLoadingRevancha(false);
    });
    revanchaService.getExcelConfiguration().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setExcelConfig(res.data.excelConfig);
      } else {
        toast.error("Fallo al cargar los de la confguración de Excel" + res.message, {
          autoClose: 6000,
        });
      }
    });
  };

  const loadRevanchaById = (item) => {
    return revanchaService
        .getRevancha({ revanchaId: item.id })
        .then((resR) => {
          if (resR.status >= 200 && resR.status < 300) {
            return resR;
          } else {
            notify("Fallo al cargar los Puntos de Revancha" + resR.message, {
              autoClose: 6000,
            });
          }
        });
  }

  const handleClickEditRevancha = () => {
    setOpenEditRevanchaDialog(true);
  };

  const handleCloseEditarRevancha = () => {
    setOpenEditRevanchaDialog(false);
  };

  const handleEditarRevancha = ({ nombre }) => {
    notify("Editando...");
    handleCloseEditarRevancha();
    revanchaService
      .editarGrupo({
        grupoId: id,
        nombre,
        areaId: 1,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          update("Los datos se editaron correctamente 👌", toast.TYPE.SUCCESS);
          loadRevancha();
        } else {
          update(
            `Ha ocurrido un error al editar los datos 🤯 ${res.message}`,
            toast.TYPE.ERROR
          );
        }
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEliminarGrupo = () => {
    handleCloseDeleteItemDialog();
    revanchaService.eliminarGrupo({ id }).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
        navigate("/");
      } else {
        toast.error("Fallo al eliminar el Grupo de Revanchas" + res.message, {
          autoClose: 6000,
        });
      }
    });
  };

  const handleClickImportar = () => {
    setOpenImportarDialog(true);
  };
  const handleCloseImportar = () => {
    setOpenImportarDialog(false);
  };

  const handleImportar = ({ fecha, ficheros }) => {
    handleCloseImportar();
    notify("Importando...");
    const formData = new FormData();
    formData.append("UploadFile", ficheros);
    formData.append("FechaCreado", new Date(fecha).toISOString());
    revanchaService
      .subirPlantillaPuntosRevancha({
        revanchaId: revancha?.revanchas?.id,
        formData,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          update("Los datos se importaron 👌", toast.TYPE.SUCCESS);
          loadRevancha();
        } else {
          update(
            `Ha ocurrido un error importando 🤯 ${res.message}`,
            toast.TYPE.ERROR
          );
        }
      });
  };
  const handleAddRevancha = ({ umbral, tipoRevanchaId }) => {
    handleCloseAdicionar();
    revanchaService
      .crearRevancha({ grupoId:  parseInt(id),  umbral, tipoRevanchaId })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadRevancha();
        } else {
          toast.error("Error al adicionar revancha");
        }
      });
  };

  const handleClickAdicionar = () => {
    setOpenAdicionarRevanchaDialog(true);
  };

  const handleCloseAdicionar = () => {
    setOpenAdicionarRevanchaDialog(false);
  };

  const handleClickPublicarCapa = () => {
    setOpenPublicarCapa(true);
  };

  const handleClosePublicarCapa = () => {
    setOpenPublicarCapa(false);
  };

  const handleCreateExcelConfig = () => {
    setOpenCrearExcelConfig(true);
  };

  const handleEditExcelConfig = () => {
    setOpenEditExcelConfig(true);
  };

  const handleClickDejarPublicarCapa = () => {
    setOpenDejarPublicarCapa(true);
  };

  const handleCloseDejarPublicarCapa = () => {
    setOpenDejarPublicarCapa(false);
  };

  const handleDejarPublicarCapa = () => {
    handleCloseDejarPublicarCapa()
    let revanchaId = parseInt(revancha.revanchas.id);
    revanchaService.eliminarCapaByRevnahcaId(revanchaId).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        loadRevancha()
      } else {
        toast.error("Fallo al eliminar el Grupo de Revanchas" + res.message, {
          autoClose: 6000,
        });
      }
    });
  };

  const handleCloseCrearExcelConfig = () => {
    setOpenCrearExcelConfig(false);
  };

  const handleCloseEditarExcelConfig = () => {
    setOpenEditExcelConfig(false);
  };

  // const handlePublicarCapa = ({nombreCapa}) => {
  //   handleClosePublicarCapa()
  //   let revanchaId = revancha.revanchas.id;
  //   update("Se están publicando la revancha con sus datos correspondientes", toast.TYPE.INFO);
  //   console.log("NOMBRE", nombreCapa);
  //   revanchaService
  //     .publicarRevanchas({revanchaId, nombreCapa})
  //     .then((res) => {
  //       if (res.status >= 200 && res.status < 300) {
  //         update("La revancha y sus datos fueron publicados correctamente 👌", toast.TYPE.SUCCESS);
  //         setRevancha({ ...revancha, publicado: true });
  //       } else {
  //         update(
  //             `Ha ocurrido un error a publicar los datos 🤯 ${res.message}`,
  //             toast.TYPE.ERROR
  //         );
  //       }
  //     });
  // };

  const handlePublicarCapa = ({ nombre, fecha }) => {
    handleClosePublicarCapa()
    let revanchaId = revancha.revanchas.id;
    let tipoRevanchaId = revancha.revanchas.tipoRevancha.id;
    notify("Publicando nueva Capa de Piezómetro...")
    revanchaService
        .publicarCapa({
          revanchaId: parseInt(revanchaId),
          nombre,
          url: "",
          creado: fecha,
          tipoRevanchaId: parseInt(tipoRevanchaId)
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            loadRevancha()
            update(
                "La capa fue publicada correctamente 👌",
                toast.TYPE.SUCCESS
            );
          } else {
            update(
                "Ocurrio un error al publicar la capa. 🤯 ${res.message}",
                toast.TYPE.ERROR
            );
            console.log(res);
          }
        });
  };

  const handleConfiguracionExcel = ({initialRow, etiquetaCell, ubicacionNorteCell,
                                           ubicacionEsteCell, cotaInferiorCell,
                                           cotaSuperiorCell, diferenciaCotaCell}) => {
    if (excelConfig) {
      handleCloseEditarExcelConfig()
      notify("Se está editando la configuración del archivo de datos");
      revanchaService
          .editarConfiguracionDeExcel({
            initialRow,
            etiquetaCell,
            ubicacionNorteCell,
            ubicacionEsteCell,
            cotaInferiorCell,
            cotaSuperiorCell,
            diferenciaCotaCell
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              update(
                  "La configuración para la carga de los excel se edito correctamente 👌",
                  toast.TYPE.SUCCESS
              );
            } else {
              update(
                  `Ha ocurrido un error editando la configuración 🤯 ${res.message}`,
                  toast.TYPE.ERROR
              );
            }
          });
    } else {
      handleCloseCrearExcelConfig()
      notify("Se está creando la configuración del archivo de datos");
      revanchaService
          .crearConfiguracionDeExcel({
            initialRow,
            etiquetaCell,
            ubicacionNorteCell,
            ubicacionEsteCell,
            cotaInferiorCell,
            cotaSuperiorCell,
            diferenciaCotaCell
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              update(
                  "La configuración para la carga de los excel se creo correctamente 👌",
                  toast.TYPE.SUCCESS
              );
            } else {
              update(
                  `Ha ocurrido un error creando la configuración 🤯 ${res.message}`,
                  toast.TYPE.ERROR
              );
            }
          });
    }
  };

  return (
    <div className="area-container">
      {loadingRevancha ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
          <>
            <div className="head-area">
              <div>
                {revancha ? (
                    <>
                      <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: 34,
                          }}
                      >
                        <img src={RevanchaBlack} alt="" width={18} height={18}/>
                        <h2 className="name-area">{revancha?.nombre}</h2>
                      </div>
                      <h4 style={{margin: 0}}>
                        {revancha?.revanchas
                            ? revancha?.revanchas?.tipoRevancha?.name
                            : "Este grupo no tiene asociada ninguna Revancha"}
                      </h4>
                    </>
                ) : (
                    <h2 className="name-area">Grupo Revanchas inexistente</h2>
                )}
              </div>
              <div>
                {revancha?.revanchas && (
                    <div className="actionsButtons">
                      <Button
                          onClick={loadRevancha}
                          sx={{mr: "0px", color: "white", display: "block"}}
                      >
                        <img src={Reload} alt=""/>
                      </Button>
                      <GrupoRevanchaMenuBtn
                          dejarPublicarAction={publicado && (() => handleClickDejarPublicarCapa())}
                          publicarAction={!publicado && (() => handleClickPublicarCapa())}
                          crearConfigExcel={handleCreateExcelConfig}
                          editConfigExcel={handleEditExcelConfig}
                          importarAction={handleClickImportar}
                          editarAction={handleClickEditRevancha}
                          eliminarAction={handleClickDeleteItem}
                          excelConfig={excelConfig}
                      />
                    </div>
                )}
              </div>
            </div>
            {!revancha?.revanchas && (
                <div>
                  <Button
                      disabled={false}
                      variant="contained"
                      className="btn-add"
                      onClick={handleClickAdicionar}
                  >
                    {false && (
                        <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                        />
                    )}
                    Adicionar
                  </Button>
                </div>
            )}
            <div>
            <span>{publicado ?
                <><PublicadoIcon/> <span>Publicado</span></>
                :
                <><PublicadoIcon color="disabled"/> <span>No Publicado</span></>
            }</span>
            </div>
            <Box sx={{width: "100%"}}>
              <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                  <Tab label="Datos" {...a11yProps(0)} />
                  <Tab label="Alarmas" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} className="table-captura">
                <Datos revancha={revancha?.revanchas} loadPuntos={loadRevancha}/>
              </TabPanel>
              <TabPanel value={value} index={1} className="table-captura">
                <Alarmas loadRevancha={loadRevancha}/>
              </TabPanel>
            </Box>
          </>
      )}
      {openDeleteItemDialog && (
          <DeleteItemDialog
              open={openDeleteItemDialog}
              handleClose={handleCloseDeleteItemDialog}
              handleDeleteItem={handleEliminarGrupo}
          />
      )}
      {openDejarPublicarCapa ?
          <DeleteCapaDialog
              open={openDejarPublicarCapa}
              handleClose={handleCloseDejarPublicarCapa}
              handleDeleteItem={handleDejarPublicarCapa}/> : null
      }
      {openEditarRevanchaDialog && (
        <EditarRevanchaDialog
          open={openEditarRevanchaDialog}
          handleClose={handleCloseEditarRevancha}
          handleSubmit={handleEditarRevancha}
          revancha={revancha}
        />
      )}
      {openAdicionarRevanchaDialog && (
        <AdicionarRevanchaDialog
          open={openAdicionarRevanchaDialog}
          handleClose={handleCloseAdicionar}
          handleSubmit={handleAddRevancha}
          tiposRevanchas={tiposRevanchas}
        />
      )}
      {openPublicarCapa && (
          <PublicarDialog
              open={openPublicarCapa}
              handleClose={handleClosePublicarCapa}
              handleSubmit={handlePublicarCapa}
          />
      )}
      {openImportarDialog && (
        <ImportarArchivoDialog
          open={openImportarDialog}
          handleClose={handleCloseImportar}
          handleSubmit={handleImportar}
          tiposRevanchas={tiposRevanchas}
        />
      )}
      {openCrearExcelConfig && (
          <CreateExcelConfigDialog
              open={openCrearExcelConfig}
              handleClose={handleCloseCrearExcelConfig}
              handleSubmit={handleConfiguracionExcel}
          />
      )}
      {openEditExcelConfig && (
          <EditExcelConfigDialog
              open={openEditExcelConfig}
              handleClose={handleCloseEditarExcelConfig}
              handleSubmit={handleConfiguracionExcel}
              excelConfig={excelConfig}
          />
      )}
    </div>
  );
};
export default Revancha;
