import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

export const MostrarDatosMapaFactorFormaDialog = ({mapaFactorFormaActual}) => {
    const [mapaFactorForma, setMapaFactorForma] = useState((mapaFactorFormaActual) ? mapaFactorFormaActual : null);

    return (
        <Stack spacing={3}>
            <div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Espígot 2->A [m]: </b>
                        <u>{(mapaFactorForma.area) ? mapaFactorForma.area : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Espígot 3->B [m]: </b>
                        <u>{(mapaFactorForma.largo) ? mapaFactorForma.largo : "-" }</u>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Espígot 2->A [m]: </b>
                        <u>{(mapaFactorForma.anchoPromedio) ? mapaFactorForma.anchoPromedio : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Espígot 3->B [m]: </b>
                        <u>{(mapaFactorForma.factorForma) ? mapaFactorForma.factorForma : "-" }</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>ArcGIS ItemId Factor Forma: </b>
                        <u>{(mapaFactorForma.itemId) ? mapaFactorForma.itemId : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Blob Storage Url Factor Forma: </b>
                        <u>{(mapaFactorForma.urlStorage) ? mapaFactorForma.urlStorage : "-" }</u>
                    </Grid>
                </Grid>
            </div>
        </Stack>
    )
}
