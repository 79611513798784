import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Plus from "../../../assets/plus.png";
import Button from "@mui/material/Button";

import GroupMenu from "../GroupMenu/GroupMenu";
import SectionMenu from "../SectionMenu/SectionMenu";
import SysRefMenu from "../SysRefMenu/SysRefMenu";
import AddLayerDialog from "../../Dialogs/AddLayerDialog";
import AddSectionDialog from "../../Dialogs/AddSectionDialog";
import AddSysRefDialog from "../../Dialogs/AddSysRefDialog";
import "./NavDrawerMenu.css";
import {
  LAYER_MENU,
  GROUP_MENU,
  SECTION_MENU,
  REFRERENCE_SYSTEM_MENU, USERS_MENU,
} from "../../../common/constants";

import {
  useSystemReferences,
  ACTIONS as SYS_REF_ACTIONS,
} from "../../../providers/SystemReferencesProvider";
import { sectionService } from "../../../services/SectionsService";
import { piezometersService } from "../../../services/PiezometersService";
import { insarService } from "../../../services/InsarService";
import { ortofotosService } from "../../../services/OrtofotosService";
import { dtmService } from "../../../services/DTMService";
import { mapaService } from "../../../services/MapaService";
import { curvasNivelService } from "../../../services/CurvasNivelService";
import { acelerometrosService } from "../../../services/AcelerometrosService";
import { revanchaService } from "../../../services/RevanchaService";

import { LAYER_TYPES } from "../../../common/constants";
import LayersMenu from "../LayersMenu/LayersMenu";
import { auxiliaresService } from "../../../services/AuxiliaresService";
import { ejesmuroService } from "../../../services/EjesMuroService";
import { grietasService } from "../../../services/GrietasService";
import { puntosDescargaService } from "../../../services/PuntosDescargaService";
import {batimetriasService} from "../../../services/BatimetriasService";
import {estacionesMetService} from "../../../services/EstacionesMeteorologicasService";
import {planesCargaService} from "../../../services/PlanesCargaService";
import {toast} from "react-toastify";
import UsersMenu from "../UsersMenu/UsersMenu";

const MenuDrawer = ({ open, toggleDrawer, currentMenu }) => {
  const [sysRefContext, setSysRefContext] = useSystemReferences();

  const [refreshMenu, setRefreshMenu] = useState(false);
  const [refreshLayers, setRefreshLayers] = useState({});

  const [openAddLayerDialog, setOpenAddLayerDialog] = useState(false);
  const [openAddAreaDialog, setOpenAddAreaDialog] = useState(false);
  //const [openAddSysRefDialog, setOpenAddSysRefDialog] = useState(false);

  //layer
  const handleCloseLayerDialog = () => {
    setOpenAddLayerDialog(false);
  };

  const handleAddGrupo = (layer) => {
    switch (layer.layerType) {
      case LAYER_TYPES.PIEZOMETROS:
        piezometersService
          .createGroup({
            nombre: layer.name,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.PIEZOMETROS]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.ACELEROMETROS:
        acelerometrosService
          .createGroup({
            name: layer.name,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.ACELEROMETROS]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.INSAR:
        insarService
          .crearSerie({
            etiqueta: layer.name,
            fechaCreado: new Date(),
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.INSAR]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.ORTOFOTOS:
        ortofotosService
          .crearGrupoOrtofotos({
            nombre: layer.name,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.ORTOFOTOS]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.DTM:
        dtmService
          .crearGrupo({
            nombre: layer.name,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.DTM]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.ELEVACIONES:
        mapaService
          .crearGrupo({
            nombre: layer.name,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.ELEVACIONES]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.CURVAS_NIVEL:
        curvasNivelService
          .crearGrupo({
            nombre: layer.name,
            areaId: 1,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.CURVAS_NIVEL]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.REVANCHA:
        revanchaService
          .crearGrupo({
            nombre: layer.name,
            areaId: 1,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.REVANCHA]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.PUNTOS_DESCARGA:
        puntosDescargaService
          .crearGrupo({
            nombre: layer.name,
            areaId: 1,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.PUNTOS_DESCARGA]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.AUXILIARES:
        auxiliaresService
          .crearGrupo({
            nombre: layer.name,
            areaId: 1,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.AUXILIARES]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.ESTACIONES_METEOROLOGICAS:
        estacionesMetService
          .createGroup({
            nombre: layer.name,
            areaId: 1,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.ESTACIONES_METEOROLOGICAS]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.EJES_MURO:
        ejesmuroService
            .crearGrupoEjesMuro({
              nombre: layer.name,
              areaId: 1,
            })
            .then((res) => {
              if (res.status >= 200 && res.status < 300) {
                setRefreshLayers({
                  [LAYER_TYPES.EJES_MURO]: true,
                });
              } else {
                console.error(res);
              }
            });
        break;
      case LAYER_TYPES.GRIETAS:
        grietasService
          .crearGrupo({
            nombre: layer.name,
            areaId: 1,
          })
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              setRefreshLayers({
                [LAYER_TYPES.GRIETAS]: true,
              });
            } else {
              console.error(res);
            }
          });
        break;
      case LAYER_TYPES.BATIMETRIAS:
        batimetriasService
            .crearGrupoBatimetria({
              nombre: layer.name,
              areaId: 1,
            })
            .then((res) => {
              if (res.status >= 200 && res.status < 300) {
                setRefreshLayers({
                  [LAYER_TYPES.BATIMETRIAS]: true,
                });
              } else {
                console.error(res);
              }
            });
        break;
      case LAYER_TYPES.PLANES_ESTERIL:
        console.log("LAYER", layer)
        planesCargaService.crearCapaPlanCargaEsteril({
          noCapa: layer.noCapa,
          ejeMuroId: layer.ejeMuroId,
          sector: layer.sector,
          estadoPlanCargaId: layer.estadoPlanCargaId,
          elevacionRequerida: parseFloat(layer.elevacionRequerida),
          espesorObjetivo: parseFloat(layer.espesorObjetivo),
          toleranciaCumplimiento: parseFloat(layer.toleranciaCumplimiento),
          fechaRecepcion: layer.fechaRecepcion,
          fechaCreacion: layer.fechaCreacion,
          puntoControlInicial: layer.puntoControlInicial,
          puntoControlFinal: layer.puntoControlFinal }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            setRefreshLayers({
              [LAYER_TYPES.PLANES_ESTERIL]: true,
            });
          } else {
            console.log(res)
          }
        });
        break;
      default:
        break;
    }
  };

  const handleCloseAreaDialog = () => {
    setOpenAddAreaDialog(false);
  };

  const handleAddSection = (section) => {
    const { nombre, descripcion, itemType, area, ubicacion } = section;
    if (itemType === "area") {
      const newArea = {
        nombre: "Area. " + nombre,
        descripcion,
        ubicacion,
      };
      sectionService.createArea(newArea).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setRefreshMenu(true);
        } else {
          console.error("createSector", res);
        }
      });
    } else {
      const newSector = {
        nombre: "Sector. " + nombre,
        areaId: area,
        descripcion,
        ubicacion,
      };
      sectionService.createSector(newSector).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setRefreshMenu(true);
        } else {
          console.error("createSector", res);
        }
      });
    }
  };
  //systemreference
  // const handleCloseSysRefDialog = () => {
  //   setOpenAddSysRefDialog(false);
  // };
  //
  // const handleAddSysRef = (sysRef) => {
  //   setSysRefContext({
  //     action: SYS_REF_ACTIONS.ADD_SYS_REF,
  //     payload: { sysRef },
  //   });
  // };

  const handleClickButtonAdd = () => {
    switch (currentMenu) {
      case GROUP_MENU:
        setOpenAddLayerDialog(true);
        break;
      case SECTION_MENU:
        setOpenAddAreaDialog(true);
        break;
      // case REFRERENCE_SYSTEM_MENU:
      //   setOpenAddSysRefDialog(true);
      //   break;
      default:
        break;
    }
  };

  return (
    <Drawer
      open={open}
      // onClose={() => toggleDrawer(!open)}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
        position: "unset",
      }}
      PaperProps={{
        sx: { maxWidth: 290 },
      }}
    >
      <div className="lateral-menu">
        <div className="menu-name">
          <div className="title-drawer-menu">
            <p>{currentMenu}</p>
            {(currentMenu === LAYER_MENU) ? <>&nbsp;</> :
              <div className="menu-button-header">
                <Button
                    className="btn-plus-drawer"
                    onClick={handleClickButtonAdd}
                    sx={{ my: 2, color: "white", display: "block" }}
                >
                   <img src={Plus} alt="plus_icon" />
                </Button>
              </div>
            }
          </div>
        </div>
        <div className="tree-menu">
          {currentMenu === LAYER_MENU && (
            <LayersMenu
              refreshLayers={refreshLayers}
              setRefreshLayers={setRefreshLayers}
            />
          )}
          {currentMenu === GROUP_MENU && (
            <GroupMenu
              refreshLayers={refreshLayers}
              setRefreshLayers={setRefreshLayers}
            />
          )}
          {currentMenu === SECTION_MENU && (
            <SectionMenu
              refreshMenu={refreshMenu}
              setRefreshMenu={setRefreshMenu}
            />
          )}
          {/*{currentMenu === REFRERENCE_SYSTEM_MENU && <SysRefMenu />}*/}
          {currentMenu === USERS_MENU && <UsersMenu />}
        </div>
      </div>
      <AddLayerDialog
        open={openAddLayerDialog}
        handleClose={handleCloseLayerDialog}
        handleAddLayer={handleAddGrupo}
      />
      <AddSectionDialog
        open={openAddAreaDialog}
        handleClose={handleCloseAreaDialog}
        handleAddSection={handleAddSection}
      />
      {/*<AddSysRefDialog*/}
      {/*  open={openAddSysRefDialog}*/}
      {/*  handleClose={handleCloseSysRefDialog}*/}
      {/*  handleAddSysRef={handleAddSysRef}*/}
      {/*/>*/}
    </Drawer>
  );
};
export default MenuDrawer;
