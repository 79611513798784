import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import moment from 'moment';
import fileDownload from 'js-file-download'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import DTMIcon from '../../../../assets/terrain.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import AddDTMDialog from '../../../Dialogs/DTM/AddDTMDialog'
import EditarDTMDialog from '../../../Dialogs/DTM/EditarDTMDialog';
import { dtmService } from '../../../../services/DTMService';
import DTMMenuBtn from '../../../Dialogs/DTM/DTMMenuBtn';
import GrupoDTMMenuBtn from '../../../Dialogs/DTM/GrupoDTMMenuBtn';
import NotificacionMensaje from '../../../NotificacionMensaje';
import './ModelosTerreno.css'
import PublicarCapaDialog from '../../../Dialogs/PublicarCapaTopografiaDialog';
import CategoriasMenuBtn from '../../../Dialogs/CategoriasMenuBtn';
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ModelosTerreno = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grupoDTMs, setGrupoDTMs] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [drawerMenuContext, setDrawerMenuCotext] = useDrawerMenu();

  const [openAddDTMDialog, setOpenAddDTMDialog] = useState(false);
  const [openEditDTMDialog, setOpenEditDTMDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

  const [currentDTM, setCurrentDTM] = useState(null);
  const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
  const [openPublicarCapa, setOpenPublicarCapa] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    let mounted = true;
    mounted && cargarGrupoDTMs();
    return () => mounted = false;
  }, [id]);

  const cargarGrupoDTMs = () => {
    setLoadingGroup(true);
    dtmService.getGrupo(id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setGrupoDTMs(res.data.grupo);
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
  };

  const handleClickEditGDTM = () => {
    setOpenEditDTMDialog(true);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseEditDTMDialog = () => {
    setOpenEditDTMDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEditGrupoDTMs = (nombre) => {
    handleCloseEditDTMDialog();
    setLoadingGroup(true);
    dtmService.updateGrupo({ id, nombre })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargarGrupoDTMs();
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
  };

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentDTM) {
      dtmService.eliminarDTM({
        dtmId: currentDTM.id,
        grupoId: id
      })
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setCurrentDTM(null);
            cargarGrupoDTMs();
          } else {
            console.log(res)
          }
          setLoadingGroup(false);
        })
    } else {
      dtmService.eliminarGrupo(id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setDrawerMenuCotext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
            navigate('/');
          } else {
            console.log(res)
          }
          setLoadingGroup(false);
        })
    }
  };

  //menu-option-row
  const handleClickAddDTM = () => {
    setOpenAddDTMDialog(true);
  };
  const handleCloseAddDTMDialog = () => {
    setOpenAddDTMDialog(false);
    setCurrentDTM(null);
  };

  const handleAddDTM = ({ fecha, ficheros, nombreCapa }) => {
    handleCloseAddDTMDialog();
    setImportandoDatos({ importando: true, status: "Importando" })
    const formData = new FormData()
    formData.append("UploadFile", ficheros)
    formData.append("NombreCapa", nombreCapa)
    formData.append("FechaCreado", new Date(fecha).toISOString())
    dtmService.uploadDTM({
      idGrupo: id,
      formData
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        setCurrentDTM(null);
        cargarGrupoDTMs();
        setImportandoDatos({ importando: false, status: "Hecho" })
      } else {
        console.log(res)
        setImportandoDatos({ importando: false, status: "Fallido" })
      }
    });
  };

  const handleDescargarDTM = (row) => {
    dtmService.descargarDTM({
      grupoId: grupoDTMs.id,
      modeloId: row.id
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          fileDownload(res.data, `(${moment(row.fechaCreado).format("DD-MM-YYYY")})dtm.tif`)
        } else { console.log(res) }
      })
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setImportandoDatos({ ...importandoDatos, status: 'Importando' })
  };

  const handleClickPublicarCapa = (ort) => {
    setCurrentDTM(ort)
    setOpenPublicarCapa(true)
  }

  const handleDejarPublicarCapa = () => {
    alert("Esta acción no esta disponible")
  }

  const handleClosePublicarCapa = () => {
    setOpenPublicarCapa(false)
  }

  const handlePublicarCapa = ({ url, nombre, fecha }) => {
    handleClosePublicarCapa()
    dtmService.publicarCapa({ modeloTerrenoDigitalId: currentDTM.id, nombre, url, fechaCreado: fecha })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          let modelos = grupoDTMs?.modelos.map((ort) => {
            if (ort.id === currentDTM.id) {
              return {
                ...ort,
                publicado: true
              }
            } else {
              return ort;
            }
          })
          setGrupoDTMs({ ...grupoDTMs, modelos })
          setCurrentDTM()
        } else {
          console.log(res)
        }
      })
  }

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupoDTMs.modelos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const renderTabla = () => {
    if (rowsPerPage > 0) {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Publicado</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? grupoDTMs.modelos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : grupoDTMs.modelos
            ).map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">{row.fechaCreado ? moment(row.fechaCreado).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.publicado ?
                    <PublicadoIcon fontSize="small" />
                    :
                    <PublicadoIcon fontSize="small" color="disabled" />
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  <CategoriasMenuBtn
                    dejarPublicarAction={row.publicado && (() => handleDejarPublicarCapa(row))}
                    publicarAction={!row.publicado && (() => handleClickPublicarCapa(row))}
                    disabled={importandoDatos.importando}
                    descargarAction={() => handleDescargarDTM(row)}
                    eliminarAction={() => {
                      setCurrentDTM(row);
                      handleClickDeleteItem();
                    }} />
                </StyledTableCell>
              </StyledTableRow>))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: grupoDTMs.modelos.length }]}
                  colSpan={12}
                  count={grupoDTMs.modelos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    } else {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No existen dtms</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    }
  }

  return (
    <div className="area-container">
      {loadingGroup ?
        <div className='loading'><CircularProgress /></div> :
        <>
          <div className="head-area">
            <div className="head-name">
              <img src={DTMIcon} alt="" width={18} height={18} />
              <h2 className="name-area">{grupoDTMs ? grupoDTMs.nombre : 'Grupo de DTMs inexistente'}</h2>
            </div>
            {grupoDTMs
              ?
                <div className="actionsButtons">
                  <Button
                      onClick={cargarGrupoDTMs}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoDTMMenuBtn
                    disabled={importandoDatos.importando}
                    editarAction={handleClickEditGDTM}
                    eliminarAction={handleClickDeleteItem}
                  />
                </div>
              : null}
          </div>
          {grupoDTMs ?
            <>
              <Button
                disabled={importandoDatos.importando}
                variant="contained"
                className="btn-add-ortofoto"
                onClick={handleClickAddDTM}
              >{importandoDatos.importando && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}Adicionar
              </Button>
              <div className="container-table">
                {renderTabla()}
              </div>
            </> : null}
        </>
      }

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      {openEditDTMDialog
        ? <EditarDTMDialog
          open={openEditDTMDialog}
          handleClose={handleCloseEditDTMDialog}
          handleEditarGrupo={handleEditGrupoDTMs}
          nombre={grupoDTMs.nombre}
        /> : null}
      {openAddDTMDialog ?
        <AddDTMDialog
          open={openAddDTMDialog}
          handleClose={handleCloseAddDTMDialog}
          handleSubmit={handleAddDTM}
        /> : null}
      <NotificacionMensaje
        open={importandoDatos.status === 'Fallido'}
        onClose={handleCloseAlert}
        message="Ha fallado la subida de los archivos"
      />
      {openPublicarCapa && <PublicarCapaDialog
        open={openPublicarCapa}
        handleClose={handleClosePublicarCapa}
        handleSubmit={handlePublicarCapa}
      />}
    </div>
  );
}
export default ModelosTerreno;


