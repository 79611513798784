// import axios from 'axios';
import axios from "../config/axiosInstance";
import {grietasBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const grietasService = {
  crearGrupo: ({ nombre }) => {
    return axios("api/grietas/grupo", {
      baseURL: grietasBaseUrl,
      method: "post",
      data: { nombre },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupos: () => {
    return axios("api/grietas/grupos", {
      baseURL: grietasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: (id) => {
    return axios(`/api/grietas/grupo/${JSON.stringify(id)}`, {
      baseURL: grietasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrupo: ({ id, nombre }) => {
    return axios(`/api/grietas/grupo/${id}`, {
      baseURL: grietasBaseUrl,
      data: { id, nombre },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  deleteGrupo: (id) => {
    return axios(`/api/grietas/grupo/${id}`, {
      baseURL: grietasBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrietas: async () => {
    return axios("/api/grietas/grupos", {
      baseURL: grietasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrieta: (id) => {
    return axios(`/api/grietas/${id}`, {
      baseURL: grietasBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrieta: (data) => {
    return axios(`/api/grietas/${data.ejesmuroId}`, {
      baseURL: grietasBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrieta: (data) => {
    return axios(`/api/grietas/grupo/${data.grupoId}/grieta/${data.id}`, {
      baseURL: grietasBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearGrieta: (data) => {
    return axios("/api/grietas", {
      baseURL: grietasBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  descargar: (data) => {
    return axios(
      `/api/grietas/grupo/${data.grupoId}/descargar-grieta/${data.id}`,
      {
        baseURL: grietasBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  /**Capas */
  publicarCapa: ({ id, nombre, url, creado }) => {
    return axios("api/grietas/capa", {
      baseURL: grietasBaseUrl,
      method: "post",
      data: {
        levantamientoGrietasId: id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  upload: ({ idGrupo, formData }) => {
    return axios(`api/grietas/grupo/${idGrupo}/upload-grieta`, {
      baseURL: grietasBaseUrl,
      method: "post",
      data: formData,
    })
      .then((result) => result)
      .catch((error) => {
        const err = errorHandle(error);
        return err;
      });
  },
  getCapas: async () => {
    return axios("api/grietas/capas", {
      baseURL: grietasBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/grietas/capa/${id}`, {
      baseURL: grietasBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, creado, levantamientoGrietasId}) => {
    return axios(`api/grietas/capa/${id}`, {
      baseURL: grietasBaseUrl,
      method: "put",
      data: {
        levantamientoGrietasId,
        id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/grietas/capa/${id}`, {
      baseURL: grietasBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapaByLevantamientoId: async (levantamientoId) => {
    return axios(`api/grietas/capa-by-levantamientoId/${levantamientoId}`, {
      baseURL: grietasBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
