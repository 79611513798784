// import axios from 'axios';
import axios from "../config/axiosInstance";
import {planesCargaBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const planesCargaService = {
    /* Plan de Carga Esteril */
    crearCapaPlanCargaEsteril: ({ noCapa, ejeMuroId, sector, estadoPlanCargaId,  elevacionRequerida, espesorObjetivo,
                                    toleranciaCumplimiento, fechaRecepcion, fechaCreacion, puntoControlInicial, puntoControlFinal }) => {
        return axios("api/v1/planes-carga-esteril", {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: {
                noCapa, ejeMuroId, sector, estadoPlanCargaId, elevacionRequerida, espesorObjetivo,
                toleranciaCumplimiento, fechaRecepcion, fechaCreacion, puntoControlInicial, puntoControlFinal
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    actualizarCapaPlanCargaEsteril: ({ capaId, noCapa, ejeMuroId, sector, estadoPlanCargaId,  elevacionRequerida, espesorObjetivo,
                                    toleranciaCumplimiento, fechaRecepcion, fechaCreacion, puntoControlInicial, puntoControlFinal }) => {
        return axios(`api/v1/planes-carga-esteril/${capaId}`, {
            baseURL: planesCargaBaseUrl,
            method: "put",
            data: {
                capaId, noCapa, ejeMuroId, sector, estadoPlanCargaId, elevacionRequerida, espesorObjetivo,
                toleranciaCumplimiento, fechaRecepcion, fechaCreacion, puntoControlInicial, puntoControlFinal
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getPlanesEsteril: async () => {
        return axios("api/v1/planes-carga-esteril", {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getPlanEsterilById: async (id) => {
        return axios(`api/v1/planes-carga-esteril/${id}`, {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getFilterPlanEsterilById: async ({id, desde, hasta}) => {
        return axios(`api/v1/planes-carga-esteril/${id}/get-protocolos-filter-datas?desde=${desde}&hasta=${hasta}`, {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    eliminarCapaPlanCargaEsteril: (capaId) => {
        return axios(`api/v1/planes-carga-esteril/${capaId}`, {
            baseURL: planesCargaBaseUrl,
            method: "delete",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    crearConfiguracionDeExcelRecepcionEsteril: ({ capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
                                    observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
                                    promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
                                    difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
                                    actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell }) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-recepcion/agregar-excel-recepcion-configuration`, {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: {
                capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
                observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
                promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
                difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
                actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    editarConfiguracionDeExcelRecepcionEsteril: ({ configId, capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
                                               observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
                                               promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
                                               difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
                                               actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell }) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-recepcion/${configId}/editar-excel-recepcion-configuration`, {
            baseURL: planesCargaBaseUrl,
            method: "put",
            data: {
                configId, capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
                observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
                promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
                difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
                actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getConfiguracionDeExcelRecepcionEsteril: async (capaId) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-recepcion/${capaId}/ver-excel-recepcion-configuration`, {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    importarProtocoloRecepcionEsteril: async ({capaId, formData}) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-recepcion/${capaId}/importar-protocolo-recepcion-capa`, {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    crearConfiguracionDeExcelAvanceEsteril: ({ capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow, observacionesCell,
                                                  puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
                                                  evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell,
                                                  anchoCapaCell, ajustesCell, observacionesPtoCell}) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-avance/agregar-excel-avance-configuration`, {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: {
                capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow, observacionesCell,
                puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
                evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell,
                anchoCapaCell, ajustesCell, observacionesPtoCell
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
   editarConfiguracionDeExcelAvanceEsteril: ({ configId, capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow, observacionesCell,
                                                 puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
                                                 evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell,
                                                 anchoCapaCell, ajustesCell, observacionesPtoCell}) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-avance/${configId}/editar-excel-avance-configuration`, {
            baseURL: planesCargaBaseUrl,
            method: "put",
            data: {
                configId, capaId, noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow, observacionesCell,
                puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
                evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell,
                anchoCapaCell, ajustesCell, observacionesPtoCell
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getConfiguracionDeExcelAvanceEsteril: async (capaId) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-avance/${capaId}/ver-excel-avance-configuration`, {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    importarProtocoloAvanceEsteril: async ({capaId, formData}) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-avance/${capaId}/importar-protocolo-avance-capa`, {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    /*Estados de las Capas */
    cambiarEstadosPlandeCarga: async ({capaId, estadoPlanCargaId}) => {
        return axios(`api/v1/planes-carga/${capaId}/cambiar-estado`, {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: { capaId, estadoPlanCargaId }
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getEstadosPlandeCarga: async () => {
        return axios("api/v1/estado-capa-plancarga", {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    /* Puntos de Levantamiento */
    getPuntosLevantamientoRecepcion: async ({capaId, fechaEmision}) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-recepcion/${capaId}/${fechaEmision}/puntos-levantamiento-recepcion`, {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    importarPuntosLevantamientoRecepcion: async ({capaId, formData}) => {
        console.log(formData)
        return axios(`api/v1/planes-carga-esteril/protocolos-recepcion/${capaId}/importar-puntos-levantamiento-recepcion`, {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getPuntosLevantamientoAvance: async ({capaId, fechaEmision}) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-avance/${capaId}/${fechaEmision}/puntos-levantamiento-avance`, {
            baseURL: planesCargaBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    importarPuntosLevantamientoAvance: async ({capaId, formData}) => {
        return axios(`api/v1/planes-carga-esteril/protocolos-avance/${capaId}/importar-puntos-levantamiento-avance`, {
            baseURL: planesCargaBaseUrl,
            method: "post",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            },
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    }
};