import axios from "../config/axiosInstance";
import {geotecniaBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const piezometersService = {
  getGroups: async () => {
    return axios("/api/v1/grupos", {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  createGroup: ({ nombre }) => {
    return axios("/api/v1/grupos", {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data: { nombre },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGroup: (id) => {
    return axios(`/api/v1/grupos/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGroup: (data) => {
    return axios(`/api/v1/grupos/${data.id}`, {
      baseURL: geotecniaBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  deleteGroup: (id) => {
    return axios(`/api/v1/grupos/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  importarData: ({ id, formData }) => {
    return axios(`/api/v1/grupos/${id}/importar-data`, {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  importarDataExcel: ({ grupoId, formData }) => {
    console.log("DATOS", {grupoId, formData});
    return axios(`/api/v1/grupos/${grupoId}/importar-data-excel`, {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data: formData,
    })
        .then((result) => result )
        .catch((error) => errorHandle(error));
  },
  getPiezometers: async () => {
    return axios("/api/v1/piezometros", {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getPiezometer: (id) => {
    return axios(`/api/v1/piezometros/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updatePiezometer: (data) => {
    console.log('DATA', data);
    return axios(`/api/v1/piezometros/${data.id}`, {
      baseURL: geotecniaBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  archivarPiezometro: (data) => {
    return axios(`/api/v1/piezometros/archivar/${data.piezometroId}`, {
      baseURL: geotecniaBaseUrl,
      data,
      method: "put",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  deletePiezometer: (data) => {
    return axios(
      `/api/v1/piezometros/${data.piezometroId}?grupoPiezometro=${data.grupoPiezometroId}`,
      {
        baseURL: geotecniaBaseUrl,
        method: "delete",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearPiezometro: (data) => {
    return axios(`api/v1/grupos/${data.grupoId}/agregar-piezometro`, {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  publicarGrupoPiezometro: ({grupoId}) => {
    return axios(`api/v1/grupos/${grupoId}/publicar`, {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data: { grupoId: parseInt(grupoId) },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  publicarCapa: ({ grupoId, nombre, itemId, creado }) => {
    return axios("api/v1/capas", {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data: {
        grupoPiezometroId: grupoId,
        nombre,
        itemId,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => {
        const err = errorHandle(error);
        return err;
      });
  },
  getCapas: async () => {
    return axios("api/v1/capas", {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getCapa: (id) => {
    return axios(`api/v1/capas/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => {
        const err = errorHandle(error);
        return err;
      });
  },
  editarCapa: ({id, nombre, itemId, creado }) => {
    let capaId = parseInt(id);
    return axios(`api/v1/capas/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "put",
      data: {
        id: capaId,
        nombre,
        itemId,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => {
        const err = errorHandle(error);
        return err;
      });
  },
  eliminarCapa: ({ capaId }) => {
    return axios(`api/v1/capas/${capaId}`, {
      baseURL: geotecniaBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => {
        const err = errorHandle(error);
        return err;
      });
  },
  eliminarCapaByGrupoId: (grupoId) => {
    return axios(`api/v1/capas/grupo/${grupoId}`, {
      baseURL: geotecniaBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => {
          const err = errorHandle(error);
          return err;
        });
  },
  createTipoPiezometro: ({ id, nombre }) => {
    return axios("/api/v1/tipos-piezometro", {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data: { id, name: nombre },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getTiposPiezometro: () => {
    return axios(`/api/v1/tipos-piezometro`, {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  deleteTipoPiezometro: ({id}) => {
    return axios(`/api/v1/tipos-piezometro/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },

  getReceptoresEmail: () => {
    return axios("/api/v1/receptores-email", {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getReceptoresEmailById: ({id}) => {
    return axios(`/api/v1/receptores-email/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearReceptoresEmail: ({ grupoId, email }) => {
    return axios("/api/v1/receptores-email", {
      baseURL: geotecniaBaseUrl,
      method: "post",
      data: { grupoId, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateReceptoresEmail: ({ id, email }) => {
    return axios(`/api/v1/receptores-email/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "put",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  deleteReceptoresEmail: ({ id, email }) => {
    return axios(`/api/v1/receptores-email/${id}`, {
      baseURL: geotecniaBaseUrl,
      method: "delete",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
