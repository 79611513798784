import React, { useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import '../AddElementoDialog.css'
import Grid from "@mui/material/Grid";

const FILE_TYPES = ".tif";
const FILE_TEXT = "Seleccione un archivo (*.tif)";

const CrearDatosSuperficieDialog = ({ open, handleClose, handleSubmit, }) => {
    const [fichero1, setFichero1] = useState();
    const [fichero2, setFichero2] = useState();
    const [superficieEspejoAgua, setSuperficieEspejoAgua] = useState()
    const [superficieCuerpoAgua, setSuperficieCuerpoAgua] = useState()
    const [perimetro, setPerimetro] = useState()
    const [superficiePeloAgua, setSuperficiePeloAgua] = useState()
    const [zonaBajaProfundidad08, setZonaBajaProfundidad08] = useState()
    const [zonaBajaProfundidad291, setZonaBajaProfundidad291] = useState()
    const [cotaPeloAgua, setCotaPeloAgua] = useState()
    const [cotaProfundidadMaxima, setCotaProfundidadMaxima] = useState()
    const [profundidadMaxima, setProfundidadMaxima] = useState()
    const [volumenPeloAgua, setVolumenPeloAgua] = useState()
    const [volumenPeloAgua1Metro, setVolumenPeloAgua1Metro] = useState()
    const [volumenPeloAgua2Metro, setVolumenPeloAgua2Metro] = useState()

    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);

    const handleSuperficieEspejoAgua = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setSuperficieEspejoAgua(newData);
    }

    const handleSuperficieCuerpoAgua = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setSuperficieCuerpoAgua(newData);
    }

    const handleSuperficiePeloAgua = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setSuperficiePeloAgua(newData);
    }

    const handlePerimetro = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setPerimetro(newData);
    }

    const handleZonaBajaProfundidad08 = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setZonaBajaProfundidad08(newData);
    }

    const handleZonaBajaProfundidad291 = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setZonaBajaProfundidad291(newData);
    }

    const handleCotaPeloAgua = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setCotaPeloAgua(newData);
    }

    const handleCotaProfundidadMaxima = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setCotaProfundidadMaxima(newData);
    }

    const handleProfundidadMaxima = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setProfundidadMaxima(newData);
    }

    const handleVolumenPeloAgua = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setVolumenPeloAgua(newData);
    }

    const handleVolumenPeloAgua1Metro = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setVolumenPeloAgua1Metro(newData);
    }

    const handleVolumenPeloAgua2Metro = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setVolumenPeloAgua2Metro(newData);
    }

    const close = () => {
        handleClose();
    }

    const handleClickImportar1 = (event) => {
        hiddenFileInput1.current.click();
    };

    const handleClickImportar2 = (event) => {
        hiddenFileInput2.current.click();
    };

    const cargarFichero1 = (event) => {
        setFichero1(event.target.files[0]);
    }

    const cargarFichero2 = (event) => {
        setFichero2(event.target.files[0]);
    }

    const crearMapaDistancia = () => {
        handleSubmit({ superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro,
            cotaPeloAgua, cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08,
        profundidadMaxima, volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro, fichero1, fichero2})
    }

    return (
        <div>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Crear Datos Superficie</DialogTitle>
                <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
                    <Stack spacing={3}>
                        <div>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="superficieEspejoAgua"
                                        label="Superficie Espejo de Agua"
                                        fullWidth
                                        type="number"
                                        value={superficieEspejoAgua}
                                        onChange={handleSuperficieEspejoAgua}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="superficiePeloAgua"
                                        label="Superficie Pelo de Agua"
                                        fullWidth
                                        type="number"
                                        value={superficiePeloAgua}
                                        onChange={handleSuperficiePeloAgua}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="superficieCuerpoAgua"
                                        label="Superficie Cuerpo de Agua"
                                        fullWidth
                                        type="number"
                                        value={superficieCuerpoAgua}
                                        onChange={handleSuperficieCuerpoAgua}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="perimetro"
                                        label="Perimetro"
                                        fullWidth
                                        type="number"
                                        value={perimetro}
                                        onChange={handlePerimetro}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="zonaBajaProfundidad08"
                                        label="Zona Baja Profundidad 08m"
                                        fullWidth
                                        type="number"
                                        value={zonaBajaProfundidad08}
                                        onChange={handleZonaBajaProfundidad08}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="zonaBajaProfundidad291"
                                        label="Zona Baja Profundidad 291m"
                                        fullWidth
                                        type="number"
                                        value={zonaBajaProfundidad291}
                                        onChange={handleZonaBajaProfundidad291}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="cotaPeloAgua"
                                        label="Cota Pelo de Agua"
                                        fullWidth
                                        type="number"
                                        value={cotaPeloAgua}
                                        onChange={handleCotaPeloAgua}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="cotaProfundidadMaxima"
                                        label="Cota de Profundidad Maxima"
                                        fullWidth
                                        type="number"
                                        value={cotaProfundidadMaxima}
                                        onChange={handleCotaProfundidadMaxima}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="profundidadMaxima"
                                        label="Profundidad Maxima"
                                        fullWidth
                                        type="number"
                                        value={profundidadMaxima}
                                        onChange={handleProfundidadMaxima}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="volumenPeloAgua"
                                        label="Volumen del Pelo de Agua"
                                        fullWidth
                                        type="number"
                                        value={volumenPeloAgua}
                                        onChange={handleVolumenPeloAgua}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="volumenPeloAgua1Metro"
                                        label="Volumen del Pelo de Agua 1M"
                                        fullWidth
                                        type="number"
                                        value={volumenPeloAgua1Metro}
                                        onChange={handleVolumenPeloAgua1Metro}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="volumenPeloAgua2Metro"
                                        label="Volumen del Pelo de Agua 2M"
                                        fullWidth
                                        type="number"
                                        value={volumenPeloAgua2Metro}
                                        onChange={handleVolumenPeloAgua2Metro}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={5}>
                                    <InputLabel id="select-datos-label">Archivo Pelo de Agua</InputLabel>
                                    <div className="select-datos">
                                        <input
                                            ref={hiddenFileInput1}
                                            type='file'
                                            style={{ display: 'none' }}
                                            // accept={FILE_TYPES}
                                            onChange={cargarFichero1}
                                        />
                                        <Button
                                            size="sm"
                                            className="rounded mr-2"
                                            color={"primary"}
                                            variant="contained"
                                            onClick={handleClickImportar1}
                                            style={{ marginRight: '8px' }}
                                        >
                                            Selecionar
                                        </Button>
                                        <span>{fichero1 ? fichero1.name : FILE_TEXT}</span>
                                    </div>
                                </Grid>

                                <Grid xs={5}>
                                    <InputLabel id="select-datos-label">Archivo Cuerpo de Agua</InputLabel>
                                    <div className="select-datos">
                                        <input
                                            ref={hiddenFileInput2}
                                            type='file'
                                            style={{ display: 'none' }}
                                            // accept={FILE_TYPES}
                                            onChange={cargarFichero2}
                                        />
                                        <Button
                                            size="sm"
                                            className="rounded mr-2"
                                            color={"primary"}
                                            variant="contained"
                                            onClick={handleClickImportar2}
                                            style={{ marginRight: '8px' }}
                                        >
                                            Selecionar
                                        </Button>
                                        <span>{fichero2 ? fichero2.name : FILE_TEXT}</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        close();
                    }}>
                        Cancelar
                    </Button>
                    <Button disabled={!fichero1 || !fichero2}
                            onClick={crearMapaDistancia}>
                        Subir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default CrearDatosSuperficieDialog;