import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PublicadoIcon from "@mui/icons-material/FlagOutlined";
import moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import "./Area.css";
import Information from "../../../../assets/informationBlack.png";
import { useParams } from "react-router-dom";

import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import EditAreaDialog from "../../../Dialogs/EditAreaDialog";
import { useNavigate } from "react-router-dom";
import { sectionService } from "../../../../services/SectionsService";
import Reload from "../../../../assets/reloadIconBlack.png";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Area = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [area, setArea] = useState();
  const [loadingArea, setLoadingArea] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openEditAreaDialog, setOpenEditAreaDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

  useEffect(() => {
    loadArea();
  }, [id]);

  const loadArea = () => {
    setLoadingArea(true);
    sectionService.getArea(id).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setArea(res.data);
      } else {
        console.error(res);
      }
      setLoadingArea(false);
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //area eliminar
  const handleCloseDleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleClickDeleteItem = () => {
    handleClose();
    setOpenDeleteItemDialog(true);
  };

  const handleDeleteItem = () => {
    handleCloseDleteItemDialog();
    sectionService.deleteArea(id).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        navigate("/");
      } else {
        console.error(res);
      }
    });
  };
  //edit
  const handleCloseEditAreaDialog = () => {
    setOpenEditAreaDialog(false);
  };

  const handleClickEditArea = () => {
    handleClose();
    setOpenEditAreaDialog(true);
  };

  const handleEditArea = (areaEdited) => {
    handleCloseEditAreaDialog();
    setLoadingArea(true);
    sectionService.updateArea(areaEdited).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        loadArea();
      } else {
        console.error(res);
      }
    });
  };

  const renderTabla = () => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">ID.</StyledTableCell>
            <StyledTableCell align="center">Nombre</StyledTableCell>
            <StyledTableCell align="center">Ubicación X</StyledTableCell>
            <StyledTableCell align="center">Ubicación Y</StyledTableCell>
            <StyledTableCell align="center">Descripción</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow key={id}>
            <StyledTableCell align="center">{id}</StyledTableCell>
            <StyledTableCell align="center">
              {area?.nombre ? area.nombre : "-"}
            </StyledTableCell>
            <StyledTableCell align="center">
              {area?.ubicacion?.x ? area.ubicacion.x : "-"}
            </StyledTableCell>
            <StyledTableCell align="center">
              {area?.ubicacion?.y ? area.ubicacion.y : "-"}
            </StyledTableCell>
            <StyledTableCell align="center">
              {area?.descripcion ? area.descripcion : "-"}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div className="area-container">
      {loadingArea ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="head-area">
            <h2 className="name-area">
              {area ? area.nombre : "Área inexistente"}
            </h2>
            {area ? (
                <div className="actionsButtons">
                  <Button
                      onClick={loadArea}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <Button
                    onClick={handleClick}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    <img src={Information} alt="informacion" />
                  </Button>
                </div>
            ) : null}
          </div>
          {area ? (
            <>
              <div className="container-table">{renderTabla()}</div>
              <Menu
                id="section-menu"
                // MenuListProps={{
                //   'aria-labelledby': 'long-button',
                // }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                keepMounted
              >
                <MenuItem onClick={handleClickEditArea}>Editar</MenuItem>
                <MenuItem onClick={handleClickDeleteItem}>Eliminar</MenuItem>
              </Menu>
            </>
          ) : null}
        </>
      )}

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDleteItemDialog}
        handleDeleteItem={handleDeleteItem}
      />
      {openEditAreaDialog ? (
        <EditAreaDialog
          open={openEditAreaDialog}
          handleClose={handleCloseEditAreaDialog}
          handleEditArea={handleEditArea}
          area={area}
        />
      ) : null}
    </div>
  );
};

export default Area;
