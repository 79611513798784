// import axios from 'axios';
import axios from "../config/axiosInstance";
import {auxiliaresBaseUrl} from "../config/config";
import errorHandle from "./tratarError";
import { toast } from "react-toastify";

export const auxiliaresService = {
  crearGrupo: ({ nombre }) => {
    return axios("api/auxiliares/grupo", {
      baseURL: auxiliaresBaseUrl,
      method: "post",
      data: { nombre, areaId: 1 },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupos: async () => {
    return axios("api/auxiliares/grupos", {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/api/auxiliares/grupo/${id}`, {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  actualizarGrupo: ({ id, nombre }) => {
    return axios(`/api/auxiliares/grupo/${id}`, {
      baseURL: auxiliaresBaseUrl,
      data: { id, nombre, areaId: 1 },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrupo: (id) => {
    return axios(`/api/auxiliares/grupo/${id}`, {
      baseURL: auxiliaresBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getAuxiliares: async () => {
    return axios("/api/auxiliares/grupo", {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getAuxiliar: (id) => {
    return axios(`/api/auxiliares/grupo/${id}/auxiliares`, {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getAuxiliarById: (id) => {
    return axios(`/api/auxiliares/auxiliar/${id}`, {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateAuxiliar: (data) => {
    return axios(`/api/auxiliares/grupo/${data.auxiliarId}`, {
      baseURL: auxiliaresBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarAuxiliar: ({ grupoId, auxiliarId }) => {
    return axios(`/api/auxiliares/grupo/${grupoId}/auxiliar/${auxiliarId}`, {
      baseURL: auxiliaresBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  descargarAuxiliar: ({ grupoId, auxiliarId }) => {
    return axios(
      `api/auxiliares/grupo/${grupoId}/descargar-auxiliar/${auxiliarId}`,
      {
        baseURL: auxiliaresBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  publicarCapaOrtofoto: ({ auxiliarId, nombre, url, creado }) => {
    return axios("api/auxiliares/capa", {
      baseURL: auxiliaresBaseUrl,
      method: "post",
      data: {
        auxiliarId,
        nombre,
        url,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  uploadAuxiliares: async ({ idGrupo, formData }) => {
    const toastId = toast.loading("Importando datos");
    return axios(`api/auxiliares/grupo/${idGrupo}/upload-auxiliar`, {
      baseURL: auxiliaresBaseUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/plain",
      },
    })
      .then((result) => {
        toast.update(toastId, {
          render: "Los datos se importaron correctamente",
          type: "success",
          isLoading: false,
        });
        return result;
      })
      .catch((error) => {
        const err = errorHandle(error);
        toast.update(toastId, {
          render: err.message,
          type: "error",
          isLoading: false,
        });
        return err;
      });
  },
  getCapas: async () => {
    return axios("api/auxiliares/capas", {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/auxiliares/capa/${id}`, {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, creado, auxiliarId}) => {
    return axios(`api/auxiliares/capa/${id}`, {
      baseURL: auxiliaresBaseUrl,
      method: "put",
      data: {
        auxiliarId,
        id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/auxiliares/capa/${id}`, {
      baseURL: auxiliaresBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
